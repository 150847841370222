import {TOOLS_LOGS_EXPORT, TOOLS_LOGS_FILTERS_CHANGE, TOOLS_LOGS_RESET_COUNTER} from "../../../../constants";

export const changeFilters = (filters) => {
  return {
    type: TOOLS_LOGS_FILTERS_CHANGE,
    filters
  }
};

export const downloadLogs = () => {
  return {
    type: TOOLS_LOGS_EXPORT
  }
};

export const resetCounter = () => {
  return {
    type: TOOLS_LOGS_RESET_COUNTER
  }
};