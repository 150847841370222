import PropTypes from "prop-types";
import React from "react";
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {Button} from "reactstrap";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {
  exportConfig,
  exportStreamhubProfile,
  importConfig,
  importConfigModal,
  resetConfig,
  resetConfigModal
} from "./general.settings.actions";
import ImportConfigModal from "./import-config-modal";
import ResetConfigModal from "./reset-config-modal";

const propTypes = {
  callExportConfig: PropTypes.func.isRequired,
  callImportConfigModal: PropTypes.func.isRequired,
  callImportConfig: PropTypes.func.isRequired,
  callResetConfigModal: PropTypes.func.isRequired,
  callResetConfig: PropTypes.func.isRequired,
  callExportStreamhubProfile: PropTypes.func.isRequired,
  importConfigModalOpened: PropTypes.bool.isRequired,
  resetConfigModalOpened: PropTypes.bool.isRequired
};

const ConfigSection = (props) => {
  const {
    importConfigModalOpened,
    resetConfigModalOpened
  } = props;
  return (
    <section className="form-section">
      <div className="header">
        <div className="title secondary">
          <Translate id="genericLabel.CONFIG_IMPORT_EXPORT.text"/>
          <div className="subtitle">
            <Translate id="genericLabel.CONFIG_IMPORT_EXPORT_DESCRIPTION.text"/>
          </div>
        </div>
      </div>

      <div className="config-actions">
        <Button className="square"
                onClick={() => props.callExportConfig()}>
          <AWIcon name="file_export"/>
          <span className="text"><Translate id="genericLabel.EXPORT_CONFIG.text"/></span>
        </Button>
        <Button className="square"
                onClick={() => props.callImportConfigModal(true)}>
          <AWIcon name="file_import"/>
          <span className="text"><Translate id="genericLabel.IMPORT_CONFIG.text"/></span>
        </Button>
        <Button className="square"
                onClick={() => props.callResetConfigModal(true)}>
          <AWIcon name="restore"/>
          <span className="text long"><Translate id="genericLabel.RESTORE_FACTORY_SETTINGS.text"/></span>
        </Button>
        <Button className="square"
                onClick={() => props.callExportStreamhubProfile()}>
          <AWIcon name="connect"/>
          <span className="text"><Translate id="genericLabel.EXPORT_PROFILE.text"/></span>
        </Button>
      </div>
      { importConfigModalOpened &&
      <ImportConfigModal onCancel={() => props.callImportConfigModal(false)}
                         onUpdate={props.callImportConfig}/>
      }
      { resetConfigModalOpened &&
      <ResetConfigModal onCancel={() => props.callResetConfigModal(false)}
                        onConfirm={props.callResetConfig}/>
      }
    </section>
  )
};

ConfigSection.propTypes = propTypes;

const mapStateToProps = (state) => {
  const { importConfigModalOpened, resetConfigModalOpened } = state.settings.general;
  return {
    importConfigModalOpened,
    resetConfigModalOpened
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callExportConfig: () => dispatch(exportConfig()),
    callImportConfigModal: (open) => dispatch(importConfigModal(open)),
    callImportConfig: (file) => dispatch(importConfig(file)),
    callResetConfigModal: (open) => dispatch(resetConfigModal(open)),
    callResetConfig: () => dispatch(resetConfig()),
    callExportStreamhubProfile: () => dispatch(exportStreamhubProfile())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigSection);