import PropTypes from 'prop-types';
import React, { Fragment, useMemo } from 'react';
import { Button } from 'reactstrap';
import { connect } from "react-redux";
import AWPopup from "@aviwest/ui-kit/dist/js/components/popup";
import { AWControlGridItemSelect } from '@aviwest/ui-kit/dist/js/components/control-grid';
import AWBadgeLabel from "@aviwest/ui-kit/dist/js/components/badge-label";

import {
  encoderPropTypes,
  inputIpProfilePropTypes,
  inputPropTypes,
  multiViewPropTypes
} from "../../../utils/models-prop-types";
import { formatEncoderId, formatInputId, inputDisplayName, inputIcon, inputMatchingIPProfile } from "../../../utils/global-utils";
import { INPUT_ID_MULTIVIEW, MULTIVIEW, PLAYBACK_STATUS_OFF } from "../../../constants";
import AWIcon from '@aviwest/ui-kit/dist/js/components/icon';
import StatusBadge from '../status-badge';
import { withLocalize } from 'react-localize-redux';

const propTypes = {
  inputId: PropTypes.string,
  encoderId: PropTypes.string,
  includeEncoders: PropTypes.bool,
  includeInputs: PropTypes.bool,
  includeMultiView: PropTypes.bool,
  passthrough: PropTypes.bool,
  multiView: multiViewPropTypes,
  disabled: PropTypes.bool,
  inputIPProfiles: PropTypes.arrayOf(inputIpProfilePropTypes).isRequired,
  inputs: PropTypes.arrayOf(inputPropTypes).isRequired,
  encoders: PropTypes.arrayOf(encoderPropTypes),
  onChange: PropTypes.func.isRequired,
  onHoverInput: PropTypes.func,
  onHoverEncoder: PropTypes.func,
  translate: PropTypes.func.isRequired
};

const defaultProps = {
  includeEncoders: false,
  includeInputs: true,
  includeMultiView: false
};

const InputSelector = (props) => {
  const {
    encoders,
    encoderId,
    includeEncoders,
    includeInputs,
    includeMultiView,
    passthrough,
    inputs,
    inputId,
    inputIPProfiles,
    multiView,
    disabled,
    lockstate,
    onHoverInput,
    onHoverEncoder,
    translate
  } = props;


  const displayedName = useMemo(() => {
    if (!inputId && !encoderId) {
      return null;
    }

    const encoder = encoders ? encoders.find(encoder => encoder.id === encoderId) : null;
    const input = inputs.find(input => input.id === inputId);

    let name = "";
    let iconName = "";
    if (input) {
      const matchingProfile = inputMatchingIPProfile(inputIPProfiles, input)
      name = inputDisplayName(input, matchingProfile);
      iconName = input.playbackStatus !== PLAYBACK_STATUS_OFF ? "file_input" : inputIcon(input.type, input.channelType);
    } else if (multiView && inputId === INPUT_ID_MULTIVIEW) {
      name = multiView.name;
      iconName = "multiview";
    } else if (encoder) {
      name = encoder.displayName;
      iconName = "encoder_2";
    } else {
      name = translate("genericLabel.AVAILABLE.text");
    }

    return (
      <Fragment>
          {iconName && <AWIcon name={iconName} />}
          <div className="text-clip" title={name}>{name}</div>
          {!disabled && <AWIcon name="caret_down" />}
          {disabled && lockstate &&
            <AWBadgeLabel fill color="white" className="lock">
              <AWIcon name="lock" />
            </AWBadgeLabel>
          }
      </Fragment>
    )
  }, [inputs, encoders, multiView, inputId, encoderId, inputIPProfiles, disabled, translate, lockstate]);

  if (!inputId && !encoderId) {
    return null;
  }

  const handleSelection = (inputId, encoderId, close) => {
    onHoverEncoder && onHoverEncoder(-1);
    onHoverInput && onHoverInput(-1);
    props.onChange(inputId, encoderId);
    close();
  };

  return (
    <AWControlGridItemSelect>
      <AWPopup scrollable direction="bottom" portal className="aw-input-selector-popup" renderTrigger={({ ref, onClick, className }) => (
        <Button innerRef={ref} className={className + " aw-input-selector"} onClick={onClick} disabled={disabled}>
          {displayedName}
        </Button>
      )}>
        {({ close }) => (
          <ul className="list">
            {includeInputs &&
              <Fragment>
                {inputs.map((currentInput) => {
                  const matchingProfile = inputMatchingIPProfile(inputIPProfiles, currentInput)
                  const inputName = inputDisplayName(currentInput, matchingProfile);
                  const iconName = currentInput.playbackStatus !== PLAYBACK_STATUS_OFF ? "file_input"
                    : inputIcon(currentInput.type, currentInput.channelType);
                  return (
                    <li key={currentInput.id}>
                      <button
                        className={`input-selection ${currentInput.id === inputId ? 'active' : ''}`}
                        // Do not enable NDI inputs for IP Outputs or Passthrough encoders
                        disabled={(passthrough || includeEncoders) && currentInput.channelType === 'NDI'}
                        onClick={() => handleSelection(currentInput.id, null, close)}
                        onMouseEnter={() => onHoverInput && onHoverInput(currentInput.id)}
                        onMouseLeave={() => onHoverInput && onHoverInput(-1)}>
                        <span className="id">{formatInputId(currentInput.id)}</span>
                        <span className="name text-clip">
                          {iconName && <AWIcon name={iconName} />}
                          <span title={inputName}>{inputName}</span>
                        </span>
                        <span className="status"><StatusBadge status={currentInput.status} /></span>
                      </button>
                    </li>
                  );
                })}
              </Fragment>
            }
            {includeMultiView && multiView != null &&
              <li>
                <button
                  className={`input-selection ${inputId === INPUT_ID_MULTIVIEW ? 'active' : ''}`}
                  onClick={() => handleSelection(INPUT_ID_MULTIVIEW, null, close)}
                  disabled={passthrough}
                  onMouseEnter={() => onHoverInput && onHoverInput(MULTIVIEW)}
                  onMouseLeave={() => onHoverInput && onHoverInput(-1)}>
                  <span className="id">MULTI</span>
                  <span className="name text-clip">
                    <AWIcon name="multiview" />
                    <span title={multiView.name}>{multiView.name}</span>
                  </span>
                  <span className="status"><StatusBadge status={multiView.status} /></span>
                </button>
              </li>
            }
            {includeEncoders &&
              <Fragment>
                {encoders != null && encoders.map(currentEncoder => {
                  if (currentEncoder.profileId !== '-1') {
                    return (
                      <li key={currentEncoder.id}>
                        <button
                          className={`input-selection ${currentEncoder.id === encoderId ? 'active' : ''}`}
                          onClick={() => handleSelection(null, currentEncoder.id, close)}
                          onMouseEnter={() => onHoverEncoder && onHoverEncoder(currentEncoder.id)}
                          onMouseLeave={() => onHoverEncoder && onHoverEncoder(-1)}>
                          <span className="id">{formatEncoderId(currentEncoder.id)}</span>
                          <span className="name text-clip">
                            <AWIcon name="encoder_2" />
                            <span title={currentEncoder.displayName}>{currentEncoder.displayName}</span>
                          </span>
                          <span className="status"><StatusBadge status={currentEncoder.status} /></span>
                        </button>
                      </li>
                    );
                  }
                  else return null
                })}

              </Fragment>
            }
          </ul>
        )}
      </AWPopup>
    </AWControlGridItemSelect>
  );
};

InputSelector.propTypes = propTypes;
InputSelector.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
  const {
    encoders,
    inputIPProfiles,
    inputs,
    multiView
  } = state.streamhub;

  return {
    encoders,
    inputIPProfiles,
    inputs,
    multiView
  };
};

export default connect(mapStateToProps)(withLocalize(InputSelector));