import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

import {
  DASHBOARD_LAYOUT_GRID,
  DASHBOARD_LAYOUT_INLINE
} from '../../../constants';
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import { Fragment } from 'react';

const propTypes = {
    onChange: PropTypes.func.isRequired,
    outline: PropTypes.bool,
    selected: PropTypes.oneOf([DASHBOARD_LAYOUT_GRID, DASHBOARD_LAYOUT_INLINE]).isRequired
};

const defaultProps = {
    outline: false
}

const LayoutSelector = (props) => {
  const { onChange, selected, outline } = props;
  return (
      <Fragment>
        <Button active={ selected === DASHBOARD_LAYOUT_GRID}
                className='basic icon' 
                size="sm"
                color='primary'
                outline={outline}
                onClick={() => onChange(DASHBOARD_LAYOUT_GRID)}>
          <AWIcon name="layout_grid"/>
        </Button>
        <Button active={ selected === DASHBOARD_LAYOUT_INLINE}
                className='basic icon' 
                size="sm"
                color='primary'
                outline={outline}
                onClick={() => onChange(DASHBOARD_LAYOUT_INLINE)}>
          <AWIcon name="layout_inline"/>
        </Button>
      </Fragment>
  )
};

LayoutSelector.propTypes = propTypes;
LayoutSelector.defaultProps = defaultProps;

export default LayoutSelector;