import PropTypes from "prop-types";
import React, {Component} from 'react';
import {Translate} from "react-localize-redux";
import {Alert} from "reactstrap";
import {connect} from "react-redux";
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";

import EncoderProfileForm from "../../../settings/encoders/form";
import {encoderEditSettings} from "../../dashboard.actions";
import {updateEncoderProfile} from "../../../settings/encoders/encoders.settings.actions";
import {encoderPropTypes} from "../../../../../utils/models-prop-types";
import {STATUS_LIVE} from "../../../../../constants";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";

const propTypes = {
  encoderRunningProfile: PropTypes.bool.isRequired,
  callCloseModal: PropTypes.func.isRequired,
  callUpdateProfile: PropTypes.func.isRequired,
  editingEncoder: encoderPropTypes,
  multipleEncodersUsingProfile: PropTypes.bool.isRequired
};

class EncoderSettingsModal extends Component {
 
  render(){
    const props = this.props;
    const { editingEncoder, multipleEncodersUsingProfile, encoderRunningProfile } = props;
    const opened = editingEncoder != null;
    const encoderName = editingEncoder ? editingEncoder.name : '';

    const alert = (multipleEncodersUsingProfile && !encoderRunningProfile) ?
      (<Alert color="warning">
        <Translate id="genericLabel.MULTIPLE_ENCODERS_USING_PROFILE.text"/>
      </Alert>)
      : encoderRunningProfile ? 
        (<Alert color="info">
          <Translate id="genericLabel.ENCODER_RUNNING_PROFILE.text"/>
        </Alert>)
        : null;

    return (
      <AWModal onClose={() => props.callCloseModal()}
             open={ opened }
             title={
              <Breadcrumb>
                <BreadcrumbItem active>
                  <Translate id="genericLabel.SETTINGS.text"/>
                </BreadcrumbItem>
                <BreadcrumbItem>{ encoderName }</BreadcrumbItem>
              </Breadcrumb>}>
        { opened &&
          <fieldset disabled={encoderRunningProfile}>
            <EncoderProfileForm
                                editingProfileId={editingEncoder.profileId}
                                onSubmit={ props.callUpdateProfile }
                                alert={alert}/>
          </fieldset>
        }
      </AWModal>
    );
  }
}
EncoderSettingsModal.propTypes = propTypes;

const mapStateToProps = (state) => {
  const editingEncoder = state.streamhub.encoders ? state.streamhub.encoders.find(encoder => encoder.id === state.dashboard.editingEncoder) : null;
  let multipleEncodersUsingProfile = false;
  let encoderRunningProfile = false;
  if(editingEncoder){
    const encodersUsingProfile = state.streamhub.encoders.filter(encoder => encoder.profileId === editingEncoder.profileId);
    multipleEncodersUsingProfile = encodersUsingProfile.length > 1;
    encoderRunningProfile = encodersUsingProfile.filter(encoder => encoder.status === STATUS_LIVE).length > 0;
  }
  return {
    editingEncoder,
    encoderProfiles: state.streamhub.encoderProfiles,
    encoderRunningProfile,
    multipleEncodersUsingProfile
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callCloseModal: () => dispatch(encoderEditSettings(null)),
    callUpdateProfile: (data) => {
      dispatch(updateEncoderProfile(data.id, data));
      dispatch(encoderEditSettings(null));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(EncoderSettingsModal);