import PropTypes from "prop-types";
import React from "react";
import { Translate } from "react-localize-redux";

import { REF_INPUT_STATUS_DISABLED, REF_INPUT_STATUS_OFF, REF_INPUT_STATUS_ON } from "../../../../../constants";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import AWBadgeLabel from "@aviwest/ui-kit/dist/js/components/badge-label";
import { formatOutputId } from "../../../../../utils/global-utils";
import { OUTPUT_TYPE_HARDWARE } from '../../../../../constants';

const propTypes = {
  refInputStatus: PropTypes.oneOf([REF_INPUT_STATUS_DISABLED, REF_INPUT_STATUS_OFF, REF_INPUT_STATUS_ON]).isRequired,
  outputId: PropTypes.string.isRequired,
  finalName: PropTypes.string.isRequired,
};

const RefBadge = (props) => {
  if (props.refInputStatus === REF_INPUT_STATUS_DISABLED) {
    return null;
  }
  return (
    <AWBadgeLabel left={formatOutputId(props.outputId, OUTPUT_TYPE_HARDWARE)} right={
      props.refInputStatus === REF_INPUT_STATUS_ON ? <span className="ref-badge" ><AWIcon name="sdi" /> <Translate id="genericLabel.REF.text" /></span> : null
    }>
      {props.finalName}
    </AWBadgeLabel>
  )
};

RefBadge.propTypes = propTypes;

export default RefBadge;