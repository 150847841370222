import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Translate} from "react-localize-redux";
import DayJs from 'dayjs';
import { Button } from "reactstrap";

import {
  SERVER_LOG_LEVEL_DANGER,
  SERVER_LOG_LEVEL_INFO,
  SERVER_LOG_LEVEL_WARNING
} from "../../../../constants";
import {changeFilters, downloadLogs, resetCounter} from "./logs.tools.actions";
import LogFilters, {filtersPropTypes, hasAtLeastOneFilter} from "./filters";
import {getVisibleLogs} from "./logs.tools.selectors";
import Empty from "../../../common/empty";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

const propTypes = {
  callChangeFilters: PropTypes.func.isRequired,
  callDownloadLogs: PropTypes.func.isRequired,
  callResetCounter: PropTypes.func.isRequired,
  filters: filtersPropTypes.isRequired,
  logs: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf([SERVER_LOG_LEVEL_INFO, SERVER_LOG_LEVEL_WARNING, SERVER_LOG_LEVEL_DANGER]).isRequired,
    label: PropTypes.string.isRequired,
    timestamp: PropTypes.instanceOf(Date).isRequired
  }))
};

class LogsTools extends Component {

  componentDidMount(){
    this.props.callResetCounter();
  }

  render() {
    const { callChangeFilters, callDownloadLogs, filters, logs } = this.props;
    const atLeastOneFilter = hasAtLeastOneFilter(filters);
    return (
        <div className="tools-section">
          <div className="header">
            <div className="title">
              <Translate id="genericLabel.LOGS.text"/>
            </div>
            <div className="actions">
              <LogFilters onChange={ callChangeFilters }
                          filters={ filters }/>
              <Button className="basic"
                      onClick={ callDownloadLogs }>
                <AWIcon name="download"/>
              </Button>
            </div>
          </div>
          <div className="content pretty-scroll">
            { logs.length > 0 &&
            <ul className="aw-logs">
              { logs.map((log, index) => {
                return <li key={index}>
                  <span className="timestamp">{DayJs(log.timestamp).format('YYYY-MM-DD HH:mm')}</span>
                  <span className={`label ${log.type}`}>{log.label}</span>
                </li>;
              })}
            </ul>
            }
            { logs.length === 0 && atLeastOneFilter &&
              <Empty icon="filter"
                     text={<Translate id="genericLabel.LOGS_FILTER_MATCH_EMPTY.text"/>}/>
            }
          </div>
        </div>
    );
  }
}

LogsTools.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    logs: getVisibleLogs(state),
    filters: state.tools.logs.filters
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callChangeFilters: (filters) => dispatch(changeFilters(filters)),
    callDownloadLogs: () => dispatch(downloadLogs()),
    callResetCounter: () => dispatch(resetCounter())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LogsTools);