import PropTypes from "prop-types";
import React, {Component} from 'react';
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";
import {connect} from "react-redux";
import {Translate} from "react-localize-redux";

import {outputIPEditSettings} from "../../dashboard.actions";
import {
  updateOutputIPProfile
} from "../../../settings/outputs/outputs.settings.actions";
import OutputIPProfileForm from "../../../settings/outputs/form";
import {outputPropTypes} from "../../../../../utils/models-prop-types";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";

const propTypes = {
  callCloseModal: PropTypes.func.isRequired,
  callUpdateProfile: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  outputId: PropTypes.string,
  output: outputPropTypes
};

class OutputIPSettingsModal extends Component {

  constructor(props){
    super(props);

    this.handleDetachProfile = this.handleDetachProfile.bind(this);
    this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
  }

  handleProfileSubmit(data){
    const { id, ...otherProps } = data;
    this.props.callUpdateProfile(id, otherProps);
    this.props.callCloseModal();
  }

  handleDetachProfile(){
    this.props.callDetachProfile(this.props.output);
    this.props.callCloseModal();
  }

  render(){
    const { opened, output } = this.props;
    return (
      <AWModal onClose={() => this.props.callCloseModal()}
             open={ opened }
             title={
              <Breadcrumb>
                <BreadcrumbItem active>
                  <Translate id="genericLabel.SETTINGS.text"/>
                </BreadcrumbItem>
                {output && <BreadcrumbItem><Translate id="genericLabel.IP_OUTPUT.text"/> { output.id }</BreadcrumbItem>}
              </Breadcrumb>
             }>
        { opened &&
          <OutputIPProfileForm key={ output.ipProfileId }
                               editingProfileId={output.ipProfileId}
                               onSubmit={this.handleProfileSubmit}/>
        }
      </AWModal>
    );
  }
}

OutputIPSettingsModal.propTypes = propTypes;

const mapStateToProps = (state) => {
  const output = state.streamhub.ipOutputs.find(output => output.id === state.dashboard.editingIPOutput);
  return {
    opened: output != null,
    output
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callCloseModal: () => dispatch(outputIPEditSettings(null)),
    callUpdateProfile: (id, data) => dispatch(updateOutputIPProfile(id, data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OutputIPSettingsModal);