import PropTypes from "prop-types";
import React, {Component} from 'react';

import {connect} from "react-redux";
import {outputNDIEditSettings} from "../../dashboard.actions";
import GeneralForm from "./general-form";
import {Translate} from "react-localize-redux";
import {updateNDIOutputSettings} from "../../../settings/settings.actions";
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";

const propTypes = {
  callCloseModal: PropTypes.func.isRequired,
  callUpdateNDIOutputSettings: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  outputId: PropTypes.string,
};

class OutputNDISettingsModal extends Component {

  constructor(props){
    super(props);

    this.handleCustomPattern = this.handleCustomPattern.bind(this);
    this.handleGeneralFormSubmit = this.handleGeneralFormSubmit.bind(this);

    this.state = {
      customPatternFormVisible: false
    }
  }

  handleCustomPattern(display){
    this.setState({
      customPatternFormVisible: display
    });
  }

  handleGeneralFormSubmit(data){
    this.props.callUpdateNDIOutputSettings(this.props.outputId, data);
    this.props.callCloseModal();
  }

  render(){
    return (
    <AWModal onClose={() => this.props.callCloseModal()}
             open={this.props.opened}
             title={
             <Breadcrumb>
                <BreadcrumbItem active>
                  <Translate id="genericLabel.SETTINGS.text"/>
                </BreadcrumbItem>
                <BreadcrumbItem>{ this.props.outputName }</BreadcrumbItem>
              </Breadcrumb>}>
          { this.props.opened &&
          <div className="settings">
            <GeneralForm outputId={ this.props.outputId }
                         onSubmit={ this.handleGeneralFormSubmit }/>
          </div>
          }
        </AWModal>
    );
  }

}

OutputNDISettingsModal.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    opened: state.dashboard.editingNDIOutput !== null,
    outputId: state.dashboard.editingNDIOutput,
    outputName: state.dashboard.editingNDIOutput ? state.config.NDIOutput[state.dashboard.editingNDIOutput].name : null
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    callCloseModal: () => dispatch(outputNDIEditSettings(null)),
    callUpdateNDIOutputSettings: (outputId, data) => dispatch(updateNDIOutputSettings(outputId, data)),

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OutputNDISettingsModal);