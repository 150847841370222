import { createSelector } from 'reselect'

import {SERVER_LOG_LEVEL_DANGER, SERVER_LOG_LEVEL_INFO, SERVER_LOG_LEVEL_WARNING} from "../../../../constants";

const getLogs = (state) => state.tools.logs.list;
const getFilters = (state) => state.tools.logs.filters;

export const getVisibleLogs = createSelector(
  [ getLogs, getFilters ],
  (logs, filters) => {
    if(!logs) return [];
    return logs.filter(log => {
      if(!filters.info && log.type === SERVER_LOG_LEVEL_INFO){
        return false;
      }
      if(!filters.warning && log.type === SERVER_LOG_LEVEL_WARNING){
        return false;
      }
      if(!filters.error && log.type === SERVER_LOG_LEVEL_DANGER){
        return false;
      }
      return true;
    });
  }
);