import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {Button} from "reactstrap";
import {Translate} from "react-localize-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import {getEncoderPresets} from "../../../../misc/capabilities.selectors";
import {encoderProfilePropTypes, encoderPropTypes} from "../../../../utils/models-prop-types";
import Empty from "../../../common/empty";
import {isEmptyString} from "../../../../utils/string-utils";
import { STATUS_LIVE, STATUS_OFF } from '../../../../constants';
import StatusBadge from '../../../common/status-badge';

const matchingSearch = (profile, search) => {
  if(search.length === 0){
    return true
  }
  return profile.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
};

const propTypes = {
  search: PropTypes.string.isRequired,
  encoderProfiles: PropTypes.arrayOf(encoderProfilePropTypes),
  encoders: PropTypes.arrayOf(encoderPropTypes),
  onProfileEdit: PropTypes.func,
  onProfileAttach: PropTypes.func,
  canSelect: PropTypes.bool.isRequired,
};

class EncoderProfileList extends Component {

  render() {
    const { encoderProfiles, encoders, onProfileEdit, onProfileDelete, onProfileAttach, search, canSelect, encoderPresets } = this.props;
    const hasActions = onProfileEdit !== null || onProfileDelete !== null;
    const matchingProfiles = encoderProfiles ? encoderProfiles.filter(profile => matchingSearch(profile, search)) : [];
    if(matchingProfiles.length === 0){
      if(!isEmptyString(search)){
        return (
          <Empty icon="search"
                 text={<Translate id="genericLabel.ENCODER_PROFILES_SEARCH_MATCH_EMPTY.text"/>}
                 subText={`"${search}"`}/>
        );
      }
      else {
        return (
          <Empty icon="encoder_2"
                 text={<Translate id="genericLabel.ENCODER_PROFILES_EMPTY.text"/>}/>
        );
      }
    }
    return (
      <div className="profiles-settings">
        { matchingProfiles
          .map(profile => {
            const encoderRunningProfile = encoders.find(encoder => encoder.profileId === profile.id && encoder.status === STATUS_LIVE);
            const encoderWithoutProfile = encoders.find(encoder => encoder.profileId === '-1');
            const matchingEncoder = encoders.find(encoder => encoder.profileId === profile.id);
            return <div className="profile" key={ profile.name }>
              { encoderWithoutProfile && canSelect &&
              <div className="selection">
                <Button className="basic icon"
                        onClick={() => onProfileAttach(encoderWithoutProfile, profile)}>
                  <AWIcon name="circle"/>
                </Button>
              </div>
              }
              <div className="name text-clip">
                <span title={profile.name} className="text-clip">
                  { profile.name }
                </span>
              </div>
              <div className="protocol enc">
                <AWIcon name="encoder_2" className="mr-1" /> { <Translate id="genericLabel.ENCODER.text"/> }
              </div>
              <div className="status"><StatusBadge status={matchingEncoder ? matchingEncoder.status : STATUS_OFF} /></div>
              <div className="encoder-preset">
                { encoderPresets[profile.encoderPresetIndex] && encoderPresets[profile.encoderPresetIndex].name}
              </div>
              <div className="format">
                { `${profile.encoderVideoType}, ${profile.encoderAudioType}` }
              </div>
              <div className="actions">
                { hasActions &&
                <Fragment>
                  { !encoderRunningProfile &&
                  <Button className="basic icon"
                          onClick={() => onProfileEdit(profile)}>
                    <AWIcon name="edit"/>
                  </Button>
                  }
                </Fragment>
                }
              </div>
            </div>;
          }) }
      </div>
    );
  }
}

EncoderProfileList.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  return {
    encoderPresets: getEncoderPresets(state)
  }
};

export default connect(mapStateToProps)(EncoderProfileList);