import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {connect} from "react-redux";
import {Translate, withLocalize} from "react-localize-redux";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";

import OptionsForm from "./options-form";
import {inputEditSettings} from "../../dashboard.actions";
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";

const propTypes = {
  callCloseModal: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  inputId: PropTypes.string,
  inputName: PropTypes.string
};

class InputSettingsModal extends Component {

  render() {
    const { opened } = this.props;
    return (
    <AWModal onClose={this.props.callCloseModal}
             open={opened}
             title={
              <Breadcrumb>
                <BreadcrumbItem active>
                  <Translate id="genericLabel.SETTINGS.text"/>
                </BreadcrumbItem>
                <BreadcrumbItem>{ this.props.inputName }</BreadcrumbItem>
              </Breadcrumb>}>
          <OptionsForm inputId={this.props.inputId} />
        </AWModal>
    );
  }
}

InputSettingsModal.propTypes = propTypes;

const mapStateToProps = (state) => {
  const input = state.streamhub.inputs.find(input => input.id === state.dashboard.editingInput);
  return {
    opened: state.dashboard.editingInput !== null,
    inputId: state.dashboard.editingInput,
    inputName: input ? input.name : null
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callCloseModal: () => dispatch(inputEditSettings(null))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(InputSettingsModal));