import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import {Button, Col, FormGroup, Input, Row} from 'reactstrap';

import {updateCustomPattern} from "../settings.actions";
import {SETTINGS_MULTIVIEW_CUSTOM_PATTERN_PREFIX, SETTINGS_ENCODER_CUSTOM_PATTERN_PREFIX, SETTINGS_OUTPUT_CUSTOM_PATTERN_PREFIX, SETTINGS_NDI_OUTPUT_CUSTOM_PATTERN_PREFIX} from '../../../../constants'

const propTypes = {
  customPatternImg: PropTypes.string,
  callCustomPatternUpdate: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
  timestamp: PropTypes.number
};

const initialState = {
  fileread: null,
  filename: null
};

class CustomPatternForm extends Component {

  constructor(props){
    super(props);

    this.submitForm = this.submitForm.bind(this);
    this.handleFileSelected = this.handleFileSelected.bind(this);

    this.state = initialState;
  }

  static getDerivedStateFromProps(props, state){
    if(props.customPatternImg === state.fileread){
      return initialState;
    }
    return null;
  }

  handleFileSelected(e) {
    if(e.target.files || e.target.files.length > 0){
      const file = e.target.files[0];
      if(file){
        const fileName = file.name;
        if(this.reader){
          this.reader.abort();
        }
        this.reader = new FileReader();
        this.reader.readAsDataURL(file);
        this.reader.onload = () => {
          this.setState({
            fileread: this.reader.result,
            filename: fileName
          });
          this.reader = null;
        };
      }
      else {
        this.setState(initialState);
      }
    }
  }

  submitForm() {
    this.props.callCustomPatternUpdate(this.state.fileread);
  }

  render() {
    const {
      fileread
    } = this.state;
    const { customPatternImg } = this.props;
    return (
        <div className="output-custom-pattern-form">
          <Row form>
            <Col xs={8}>
              <FormGroup>
                <Input type="file"
                       accept=".jpg"
                       onChange={this.handleFileSelected}/>
                <div className="indicator">
                  <Translate id="genericLabel.CUSTOM_PATTERN.text"/>
                </div>
              </FormGroup>
            </Col>
            <Col className="right"
                 xs={4}>
              <Button disabled={fileread === null}
                      color="primary"
                      type="button"
                      onClick={() => this.submitForm()}>
                <Translate id="genericLabel.UPLOAD.text" />
              </Button>
            </Col>
          </Row>
          <div className="preview">
            { fileread !== null &&
            <img alt="custom_default_pattern" src={fileread}/>
            }
            { fileread === null && customPatternImg &&
            <img alt="custom_default_pattern" src={customPatternImg} onError={(e)=>{e.target.onerror = null; e.target.style.display='none'}}/>
            }
          </div>
        </div>
    );
  }
}

CustomPatternForm.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  let img = null;
  switch (ownProps.prefix) {
    case SETTINGS_MULTIVIEW_CUSTOM_PATTERN_PREFIX:
      img = `/thumbnails/${SETTINGS_MULTIVIEW_CUSTOM_PATTERN_PREFIX}/${ownProps.id}/${ownProps.timestamp}`;
      break;
    case SETTINGS_ENCODER_CUSTOM_PATTERN_PREFIX:
      img = `/thumbnails/${SETTINGS_ENCODER_CUSTOM_PATTERN_PREFIX}/${ownProps.id}/${ownProps.timestamp}`;
      break;
    case SETTINGS_OUTPUT_CUSTOM_PATTERN_PREFIX:
      img = `/thumbnails/${SETTINGS_OUTPUT_CUSTOM_PATTERN_PREFIX}/${ownProps.id}/${ownProps.timestamp}`;
      break;
    case SETTINGS_NDI_OUTPUT_CUSTOM_PATTERN_PREFIX:
      img = `/thumbnails/${SETTINGS_NDI_OUTPUT_CUSTOM_PATTERN_PREFIX}/${ownProps.id}/${ownProps.timestamp}`;
      break;
    default:
      break;
  }
  return {
    customPatternImg: img
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    callCustomPatternUpdate: (data) => dispatch(updateCustomPattern(data, ownProps.prefix, ownProps.id))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomPatternForm);