import {Formik} from "formik";
import PropTypes from "prop-types";
import React, {Fragment, useState} from "react";
import {Button, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {Translate, withLocalize} from "react-localize-redux";
import AWSwitch from "@aviwest/ui-kit/dist/js/components/switch";

import {
  inputRtmpPullURI,
  inputRtmpPushLocalURIs,
  inputRtmpPushPublicURI,
} from "../../../../misc/streamhub.adapters";
import {isEmptyString, isStreamNameValid} from "../../../../utils/string-utils";
import {DEJITTER_BUFFER_MAX, DEJITTER_BUFFER_MIN, DEJITTER_BUFFER_DEFAULT, RTMP} from "../../../../constants";
import PasswordRevealInput from "../../../common/password-reveal-input";
import AWConfirm from "@aviwest/ui-kit/dist/js/components/confirm";
import HelpLayout from "../../../common/help-layout";

const propTypes = {
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    serverMode: PropTypes.bool.isRequired,
    rtmpsMode: PropTypes.bool,
    streamName: PropTypes.string,
    uri: PropTypes.string,
    streamKey: PropTypes.string,
    login: PropTypes.string,
    password: PropTypes.string
  }),
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  forbiddenUris: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  localIps: PropTypes.arrayOf(PropTypes.string),
  publicIp: PropTypes.string,
  alert: PropTypes.node,
  protocol: PropTypes.node
};

const RTMPForm = (props) => {
  const { config, forbiddenNames, forbiddenUris, localIps, publicIp, translate, alert, protocol } = props;
  const [passwordProtected, setPasswordProtected] = useState(config && config.password ? true : false)

  const uriList = (values) => {
    let uris = null;
    if(values.serverMode){
      // Push
      uris = inputRtmpPushLocalURIs(localIps, values);
      uris.push(inputRtmpPushPublicURI(publicIp, values));
    }
    else {
      // Pull
      uris = [inputRtmpPullURI(values)];
    }
    return uris;
  };

  const handleFormSubmit = (values) => {
    console.log('values', values)
    props.onSubmit(values);
  };

  const handleValidation = (values) => {
    const errors = {};

    // Name
    if(isEmptyString(values.name)){
      errors.name = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.name.length >= 32){
      errors.name = 'genericLabel.TOO_LONG.text';
    }
    else if(forbiddenNames.indexOf(values.name) !== -1){
      errors.name = 'genericLabel.DUPLICATED_VALUES.text';
    }

    if(!values.serverMode){
      if(isEmptyString(values.uri)){
        errors.uri = 'genericLabel.REQUIRED_FIELD.text';
      }
      if(isEmptyString(values.streamKey)){
        errors.streamKey = 'genericLabel.REQUIRED_FIELD.text';
      }

      if (isEmptyString(values.login) && !isEmptyString(values.password)) {
        errors.login = 'genericLabel.REQUIRED_FIELD.text';
      }

      if (!isEmptyString(values.login) && isEmptyString(values.password)) {
        errors.password = 'genericLabel.REQUIRED_FIELD.text';
      } else {
        delete errors.password;
      }

      // Password with length > 32 characters are not encrypted (Bugs #15905)
      if((!config || (config && config.password !== values.password)) && values.password.length > 32) {
        errors.password = 'genericLabel.TOO_LONG.text';
      }
    }
    else {
      if(isEmptyString(values.streamName)){
        errors.streamName = 'genericLabel.REQUIRED_FIELD.text';
      }
      else if (!isStreamNameValid(values.streamName)) {
        errors.streamName = 'genericLabel.INVALID_FORMAT.text';
      }
    }

    // DeJitterBuffer
    if(isEmptyString(values.dejitterBuffer)){
      errors.dejitterBuffer = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(parseInt(values.dejitterBuffer) < DEJITTER_BUFFER_MIN || parseInt(values.dejitterBuffer) > DEJITTER_BUFFER_MAX){
      errors.dejitterBuffer = 'genericLabel.INVALID_FORMAT.text';
    }

    const uris = uriList(values);
    if(uris !== null && uris.find(uri => forbiddenUris.indexOf(uri) !== -1) !== undefined){
      errors.uri = 'genericLabel.DUPLICATED_VALUES.text';
      errors.streamKey = 'genericLabel.DUPLICATED_VALUES.text';
      errors.streamName = 'genericLabel.DUPLICATED_VALUES.text';
    }

    return errors;
  };

  return (
    <Formik initialValues={{
              id: config ? config.id : undefined,
              type: RTMP,
              name: config ? config.name : '',
              serverMode: config ? config.serverMode : true,
              rtmpsMode: config ? config.rtmpsMode : false,
              streamName: config && config.streamName ? config.streamName : '',
              uri: config && config.uri ? config.uri : '',
              streamKey: config && config.streamKey ? config.streamKey : '',
              login: config && config.login ? config.login : '',
              password: config && config.password ? config.password : '',
              dejitterBuffer: config ? config.dejitterBuffer : DEJITTER_BUFFER_DEFAULT
            }}
            validate={ handleValidation }
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={ handleFormSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
          /* and other goodies */
        }) => (
        <Form onSubmit={ handleSubmit }>

          <HelpLayout
          filename={`c_sh_rtmp_input_profile.html`}
          form={<fieldset disabled={config && config.enable === true}>
            {alert}
            {protocol}

            <FormGroup>
              <Label for="name">
                <Translate id="genericLabel.NAME.text"/>
              </Label>
              <Input type="text"
                    name="name"
                    id="name"
                    invalid={errors.name !== undefined}
                    placeholder={ translate('genericLabel.NAME.text') }
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.name} />
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label for="serverMode">
                <Translate id="genericLabel.SERVER_MODE.text"/>
              </Label>
              <div className="push-pull"
                  id="serverMode" >
                <div className="push">
                  <Translate id="genericLabel.PUSH.text"></Translate>
                </div>
                <AWSwitch checked={!values.serverMode}
                        onChange={(checked) => setFieldValue('serverMode', !values.serverMode)}/>
                <div className="pull">
                  <Translate id="genericLabel.PULL.text"></Translate>
                </div>
              </div>
            </FormGroup>

            { values.serverMode === true &&
            <Fragment>
              <FormGroup>
                <Label for="streamName">
                  <Translate id="genericLabel.STREAM_KEY.text"/>
                </Label>
                <Input type="text"
                      name="streamName"
                      id="streamName"
                      invalid={errors.streamName !== undefined}
                      placeholder={ translate('genericLabel.STREAM_KEY.text') }
                      value={values.streamName}
                      onBlur={handleBlur}
                      onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.streamName} />
                </FormFeedback>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox"
                        name="rtmpsMode"
                        onChange={handleChange}
                        checked={values.rtmpsMode}/>{' '}
                  <Translate id="genericLabel.RTMPS_MODE.text"/>
                </Label>
              </FormGroup>
            </Fragment>
            }
            { values.serverMode === false &&
            <Fragment>
              <FormGroup>
                <Label for="uri">
                  <Translate id="genericLabel.STREAM_URL.text"/>
                </Label>
                <Input type="text"
                      name="uri"
                      id="uri"
                      invalid={errors.uri !== undefined}
                      placeholder={ translate('genericLabel.STREAM_URL.text') }
                      value={values.uri}
                      onBlur={handleBlur}
                      onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.uri} />
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="streamKey">
                  <Translate id="genericLabel.STREAM_KEY.text"/>
                </Label>
                <Input type="text"
                      name="streamKey"
                      id="streamKey"
                      invalid={errors.streamKey !== undefined}
                      placeholder={ translate('genericLabel.STREAM_KEY.text') }
                      value={values.streamKey}
                      onBlur={handleBlur}
                      onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.streamKey} />
                </FormFeedback>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox"
                        name="passwordProtected"
                        onChange={() => {
                          setPasswordProtected(!passwordProtected);
                          setFieldValue('login', '');
                          setFieldValue('password', '');
                        }}
                        checked={passwordProtected}/>{' '}
                  <Translate id="genericLabel.PASSWORD_PROTECTED.text"/>
                </Label>
              </FormGroup>
              { passwordProtected &&
              <>
              <FormGroup>
                <Label for="login">
                  <Translate id="genericLabel.LOGIN.text"/>
                </Label>
                <Input type="text"
                      name="login"
                      id="login"
                      invalid={errors.login !== undefined}
                      placeholder={ translate('genericLabel.LOGIN.text') }
                      value={values.login}
                      onBlur={handleBlur}
                      onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.login} />
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="password">
                  <Translate id="genericLabel.PASSWORD.text"/>
                </Label>
                <PasswordRevealInput name="password"
                                    id="password"
                                    setFieldValue={setFieldValue}
                                    invalid={errors.password !== undefined}
                                    placeholder={ translate('genericLabel.PASSWORD.text') }
                                    value={values.password}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={errors.password}/>
              </FormGroup>
              </>
              }
            </Fragment>
            }

            <FormGroup>
              <Label for="dejitterBuffer">
                <Translate id="genericLabel.DEJITTER_BUFFER.text"/>
              </Label>
              <Input type="number"
                    name="dejitterBuffer"
                    id="dejitterBuffer"
                    invalid={errors.dejitterBuffer !== undefined}
                    min={DEJITTER_BUFFER_MIN}
                    max={DEJITTER_BUFFER_MAX}
                    placeholder={ translate('genericLabel.DEJITTER_BUFFER.text') }
                    value={values.dejitterBuffer}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.dejitterBuffer} />
              </FormFeedback>
              <div className="indicator">
                <Translate id={`genericLabel.DEJITTER_BUFFER_HELP.text`}/>
              </div>
            </FormGroup>

            <div className="uri-preview">
              { uriList(values).map(uri => (<div key={uri} className="uri">{uri}</div>)) }
            </div>
            </fieldset>}

            buttons={<FormGroup className="buttons">
              { props.onDelete && !props.backToDashboard &&
              <AWConfirm
                onConfirm={props.onDelete}
                confirmationText={ props.translate("genericLabel.SECOND_CLICK_CONFIRM.text") }
                render={(ref, onClick) => (
                  <Button innerRef={ref}
                    onClick={onClick}
                    disabled={config && config.enable === true}
                    className="mr-auto"
                    outline
                    color="primary">
                    <Translate id="genericLabel.DELETE.text"/>
                  </Button>
                )} />
              }
              { props.onCancel && !props.backToDashboard &&
              <Button onClick={props.onCancel}
                outline
                color="primary">
                <Translate id="genericLabel.CANCEL.text"/>
              </Button>
              }
              <Button color="primary"
                      disabled={!dirty}
                      type="submit">
                <Translate id="genericLabel.SAVE.text"/>
              </Button>
            </FormGroup>
          } />
        </Form>
      )}
    </Formik>
  );
};

RTMPForm.propTypes = propTypes;

export default withLocalize(RTMPForm);