import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Button} from "reactstrap";
import {Translate, withLocalize} from "react-localize-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {
  changeEncodersLayout,
  displayFiles,
  encoderAttachProfile,
  encoderDetachProfile, encoderEditSettings,
  encodersProfilesSettings,
  encoderStart,
  encoderStop,
  encoderSwitchInput,
  highlightInput,
  highlightEncoder
} from "../dashboard.actions";
import {encoderProfilePropTypes, encoderPropTypes} from "../../../../utils/models-prop-types";
import Encoder from "./encoder";
import EncoderSettingsModal from "./encoder-settings-modal";
import {DASHBOARD_LAYOUT_GRID, DASHBOARD_LAYOUT_INLINE} from "../../../../constants";
import LayoutSelector from "../../../common/layout-selector";
import Empty from "../../../common/empty";
import AWControlGrid from "@aviwest/ui-kit/dist/js/components/control-grid";
import { canUsePlayback } from "../../../../misc/license.selectors";
import NewEncoderButton from "./new-encoder-button";
import { openHelpModal } from "../../../../misc/help.actions";
import {USER_ROLE_ADMIN} from "../../../../constants";
const propTypes = {
  hasAdminLevel: PropTypes.bool,
  callChangeEncodersLayout: PropTypes.func.isRequired,
  callEncoderStart: PropTypes.func.isRequired,
  callEncoderStop: PropTypes.func.isRequired,
  callEncoderAttachProfile: PropTypes.func.isRequired,
  callEncoderDetachProfile: PropTypes.func.isRequired,
  callEncoderSwitchInput: PropTypes.func.isRequired,
  callEncoderSettings: PropTypes.func.isRequired,
  openHelpModal: PropTypes.func.isRequired,
  encoderProfiles: PropTypes.arrayOf(encoderProfilePropTypes),
  encoders: PropTypes.arrayOf(encoderPropTypes),
  encodersVisible: PropTypes.bool,
  filesVisible: PropTypes.bool,
  layout: PropTypes.oneOf([DASHBOARD_LAYOUT_GRID, DASHBOARD_LAYOUT_INLINE]),
  mobileOutputsVisible: PropTypes.bool,
  ready: PropTypes.bool.isRequired,
  callDisplayFiles: PropTypes.func.isRequired,
  canUsePlayback: PropTypes.bool.isRequired,
};

const EncoderSection = (props) => {

  const handleProfileChange = (encoder, profileId) => {
    if(profileId != null){
      props.callEncoderAttachProfile(encoder, profileId);
    }
    else {
      props.callEncoderDetachProfile(encoder);
    }
  };

  const { hasAdminLevel, encoders, encoderProfiles, encodersVisible, filesVisible, mobileOutputsVisible,
    encodersLayout, translate, callDisplayFiles, canUsePlayback, openHelpModal } = props;
  return (
    <Fragment>
      <div className={ `layout-section encoders ${encodersVisible && !mobileOutputsVisible ? '' : 'hidden'}` }>
        <div className="section-header">
          <div className="title text-clip">
            <Translate id="genericLabel.ENCODERS.text"/>
            <Button className="basic icon"
                      size="sm"
                      onClick={() => openHelpModal("c_sh_encoders_overview.html")}>
              <AWIcon name="info_circle"/>
            </Button>
          </div>
          <div className="display-options">
            { hasAdminLevel &&
            <Button className="basic" title={translate('genericLabel.ENCODERS.text')}
                    onClick={() => props.callOpenEncodersProfilesSettings(false)}>
              <AWIcon name="options"/>
            </Button>
            }
            { canUsePlayback &&
              <Button className="basic icon"
                      title={translate('genericLabel.FILE_EXPLORER.text')}
                      size="sm"
                      onClick={() => callDisplayFiles(!filesVisible)}>
                <AWIcon name={ filesVisible ? 'folder_open' : 'folder' }/>
              </Button>
            }

            <Button className="basic icon"
                    title={translate('genericLabel.ENCODERS.text')}
                    size="sm"
                    color='primary'
                    style={{flexWrap: "wrap", alignContent: "center"}}
                    active>
              <AWIcon name="encoder_2"/>
            </Button>

            <LayoutSelector onChange={(layout) => props.callChangeEncodersLayout(layout)}
                            outline={false}
                            selected={encodersLayout}/>
          </div>
        </div>
        <AWControlGrid layout={encodersLayout} className="section-content pretty-scroll">
          { (!encoders || encoders.length === 0) &&
          <Empty icon="encoder_2"
                 text={<Translate id="genericLabel.ENCODER_PROFILES_NOT_ALLOWED.text"/>}/>
          }
          { encoders && encoders.filter(encoder => encoder.profileId !== '-1').map(encoder => (
            <Encoder key={encoder.id}
                     encoder={encoder}
                     encoderProfiles={encoderProfiles}
                     layout={encodersLayout}
                     onStart={props.callEncoderStart}
                     onStop={props.callEncoderStop}
                     onProfileChange={handleProfileChange}
                     onEditSettings={props.callEncoderSettings}
                     onSwitchInput={props.callEncoderSwitchInput}
                     selectedInputId={props.match.params.inputId}
                     onHoverInput={ props.highlightInput }
                     onHoverEncoder={ props.highlightEncoder } />
          ))}
          { hasAdminLevel && encoders && encoders.find(encoder => encoder.profileId === '-1') &&
            <NewEncoderButton onClick={() => props.callOpenEncodersProfilesSettings(true)}/>
          }
        </AWControlGrid>
      </div>
      <EncoderSettingsModal />
    </Fragment>
  )
};

EncoderSection.propTypes = propTypes;

const mapStateToProps = (state) => {
  const {
    ready,
    encodersLayout,
    encodersVisible,
    mobileOutputsVisible,
    filesVisible
  } = state.dashboard;

  const {
    encoderProfiles,
    encoders,
  } = state.streamhub;

  return {
    hasAdminLevel: state.identity.role === USER_ROLE_ADMIN,
    encoderProfiles,
    encoders,
    encodersVisible,
    encodersLayout,
    mobileOutputsVisible,
    ready,
    filesVisible,
    canUsePlayback: canUsePlayback(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callChangeEncodersLayout: (layout) => dispatch(changeEncodersLayout(layout)),
    callOpenEncodersProfilesSettings: (canSelect) => dispatch(encodersProfilesSettings(true, canSelect)),
    callEncoderStart: (encoder) => dispatch(encoderStart(encoder)),
    callEncoderStop: (encoder) => dispatch(encoderStop(encoder)),
    callEncoderAttachProfile: (encoder, profileId) => dispatch(encoderAttachProfile(encoder, profileId)),
    callEncoderDetachProfile: (encoder) => dispatch(encoderDetachProfile(encoder)),
    callEncoderSwitchInput: (encoder, inputId, forceStart) => dispatch(encoderSwitchInput(encoder, inputId, forceStart)),
    callEncoderSettings: (id) => dispatch(encoderEditSettings(id)),
    callDisplayFiles: (display) => dispatch(displayFiles(display)),
    openHelpModal: (filename) => dispatch(openHelpModal(filename, true)),
    highlightInput: (id) => dispatch(highlightInput(id)),
    highlightEncoder: (id) => dispatch(highlightEncoder(id)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLocalize(EncoderSection)));