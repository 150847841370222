import PropTypes from "prop-types";
import React from "react";
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Redirect, Route, Switch, withRouter} from "react-router";
import {Nav, NavItem, NavLink} from "reactstrap";

import PasswordForm from "./password-form";
import AdvancedForm from "./advanced-form";
import LdapForm from "./ldap-form";
import {USER_ROLE_ADMIN, USER_ROLE_USER, USER_ROLE_VIEWER} from "../../../../constants";
import {
  unblockAccounts,
  updateAdminPassword, updateSambaFTPPassword,
  updateSecuritySettings,
  updateLdapSettings,
  updateUserPassword, updateViewerPassword
} from "./security.settings.actions";

const propTypes = {
  callUpdatePassword: PropTypes.func.isRequired,
  callUpdateNetworkSettings: PropTypes.func.isRequired,
  callUpdateSecuritySettings: PropTypes.func.isRequired,
  callUpdateLdapSettings: PropTypes.func.isRequired,
  callUnblockAccounts: PropTypes.func.isRequired
};

const SecuritySettings = (props) => {
  const {
    callUpdatePassword,
    callUpdateNetworkSettings,
    callUpdateSecuritySettings,
    callUpdateLdapSettings,
    callUnblockAccounts,
    config,
    userEnable,
    viewerEnable,
    adminSessionTimeout,
    userSessionTimeout,
    viewerSessionTimeout,
    role,
    location,
    sessionsCount
  } = props;

  const hasPath = (path) => {
    return location.pathname.includes(path);
  };

  const hasAdminLevel = role === USER_ROLE_ADMIN;
  const hasUserLevel = role === USER_ROLE_ADMIN || role === USER_ROLE_USER;
  const hasViewerLevel = role === USER_ROLE_ADMIN || role === USER_ROLE_VIEWER;
  return (
    <div className="settings-section">
      <div className="header">
        <div className="title">
          <Translate id="genericLabel.SECURITY.text"/>
        </div>
      </div>
      <Nav tabs>
        { hasAdminLevel &&
        <NavItem>
          <NavLink active={hasPath('/admin')}
                   className="aw-nav-link"
                   tag={Link}
                   to="/settings/security/admin">
            <Translate id="genericLabel.ADMIN.text"/>
          </NavLink>
        </NavItem>
        }
        { hasUserLevel &&
        <NavItem>
          <NavLink active={hasPath('/user')}
                   className="aw-nav-link"
                   tag={Link}
                   to="/settings/security/user">
            <Translate id="genericLabel.USER.text"/>
          </NavLink>
        </NavItem>
        }
        { hasViewerLevel &&
        <NavItem>
          <NavLink active={hasPath('/viewer')}
                   className="aw-nav-link"
                   tag={Link}
                   to="/settings/security/viewer">
            <Translate id="genericLabel.VIEWER.text"/>
          </NavLink>
        </NavItem>
        }
        { hasAdminLevel &&
        <NavItem>
          <NavLink active={hasPath('/samba')}
                   className="aw-nav-link"
                   tag={Link}
                   to="/settings/security/samba">
            <Translate id="genericLabel.SAMBA_FTP.text"/>
          </NavLink>
        </NavItem>
        }
        { hasAdminLevel &&
        <NavItem>
          <NavLink active={ hasPath('/ldap') }
                   className="aw-nav-link"
                   tag={Link}
                   to="/settings/security/ldap">
            <Translate id="genericLabel.LDAP.text"/>
          </NavLink>
        </NavItem>
        }
        { hasAdminLevel &&
        <NavItem>
          <NavLink active={ hasPath('/advanced') }
                   className="aw-nav-link"
                   tag={Link}
                   to="/settings/security/advanced">
            <Translate id="genericLabel.ADVANCED.text"/>
          </NavLink>
        </NavItem>
        }
      </Nav>
      <div className="content with-menu pretty-scroll">
        <Switch>
          { hasAdminLevel &&
          <Route exact
                 path="/settings/security/admin"
                 component={(props) => {
                   return <PasswordForm { ...props }
                                        role={USER_ROLE_ADMIN}
                                        sessionTimeout={adminSessionTimeout}
                                        sessionsCount={sessionsCount}
                                        onSubmit={callUpdatePassword}/>
                 }}/>
          }

          { hasUserLevel &&
          <Route exact
                 path="/settings/security/user"
                 component={(props) => {
                   return <PasswordForm { ...props }
                                        role={USER_ROLE_USER}
                                        enable={userEnable}
                                        sessionTimeout={userSessionTimeout}
                                        sessionsCount={sessionsCount}
                                        onSubmit={callUpdatePassword}/>
                 }}/>
          }

          { hasViewerLevel &&
          <Route exact
                 path="/settings/security/viewer"
                 component={(props) => {
                   return <PasswordForm { ...props }
                                        role={USER_ROLE_VIEWER}
                                        enable={viewerEnable}
                                        sessionTimeout={viewerSessionTimeout}
                                        sessionsCount={sessionsCount}
                                        onSubmit={callUpdatePassword}/>
                 }}/>
          }

          <Route exact
                 path="/settings/security/samba"
                 component={(props) => {
                   return <PasswordForm { ...props }
                                        onSubmit={callUpdateNetworkSettings}/>
                 }}/>
          { config &&
          <Route exact
                 path="/settings/security/ldap"
                 component={(props) => {
                   return <LdapForm { ...props }
                                        config={config.ldap}
                                        onSubmit={callUpdateLdapSettings}
                                        onUnblockAccounts={callUnblockAccounts}/>
                 }}/>
          }
          { config &&
          <Route exact
                 path="/settings/security/advanced"
                 component={(props) => {
                   return <AdvancedForm { ...props }
                                        config={config}
                                        onSubmit={callUpdateSecuritySettings}
                                        onUnblockAccounts={callUnblockAccounts}/>
                 }}/>
          }

          <Redirect from="/settings/security*" to="/settings/security/admin"/>
        </Switch>
      </div>
    </div>
  )
};

SecuritySettings.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    role: state.identity.role,
    config: Object.keys(state.config).length > 0 ? state.config : null,
    userEnable: Object.keys(state.config).length > 0 ? state.config.auth.user.enable : false,
    viewerEnable: Object.keys(state.config).length > 0 ? state.config.auth.viewer.enable : false,
    adminSessionTimeout: Object.keys(state.config).length > 0 ? state.config.auth.admin.sessionTimeout : null,
    userSessionTimeout: Object.keys(state.config).length > 0 ? state.config.auth.user.sessionTimeout : null,
    viewerSessionTimeout: Object.keys(state.config).length > 0 ? state.config.auth.viewer.sessionTimeout : null,
    sessionsCount: state.streamhub.sessionsCount
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callUpdatePassword: ({ role, oldPassword, newPassword, enable, sessionTimeout }) => {
      switch(role){
        case USER_ROLE_ADMIN:
          dispatch(updateAdminPassword(oldPassword, newPassword, sessionTimeout));
          break;
        case USER_ROLE_USER:
          dispatch(updateUserPassword(newPassword, enable, sessionTimeout));
          break;
        case USER_ROLE_VIEWER:
          dispatch(updateViewerPassword(newPassword, enable, sessionTimeout));
          break;
        default:
          break;
      }
    },
    callUpdateNetworkSettings: ({ newPassword }) => dispatch(updateSambaFTPPassword(newPassword)),
    callUpdateSecuritySettings: (data) => dispatch(updateSecuritySettings(data)),
    callUpdateLdapSettings: (data) => dispatch(updateLdapSettings(data)),
    callUnblockAccounts: () => dispatch(unblockAccounts())
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SecuritySettings));