import {Formik} from "formik";
import PropTypes from "prop-types";
import React, {useState} from "react";
import { connect } from 'react-redux';
import {Button, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {Translate, withLocalize} from "react-localize-redux";
import AWSwitch from "@aviwest/ui-kit/dist/js/components/switch";

import {
  inputSrtPullURI,
  inputSrtPushLocalURIs,
  inputSrtPushPublicURI,
} from "../../../../misc/streamhub.adapters";
import {isEmptyString} from "../../../../utils/string-utils";
import {DEJITTER_BUFFER_MAX, DEJITTER_BUFFER_MIN, DEJITTER_BUFFER_DEFAULT, SRT} from "../../../../constants";
import PasswordRevealInput from "../../../common/password-reveal-input";
import { getAvailableSrtKeyLengths } from "../../../../misc/capabilities.selectors";
import AWConfirm from "@aviwest/ui-kit/dist/js/components/confirm";
import HelpLayout from "../../../common/help-layout";

const propTypes = {
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    serverMode: PropTypes.bool.isRequired,
    uri: PropTypes.string,
    port: PropTypes.number,
    latency: PropTypes.number,
    password: PropTypes.string,
    keyLength: PropTypes.string
  }),
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  forbiddenUris: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  localIps: PropTypes.arrayOf(PropTypes.string),
  publicIp: PropTypes.string,
  srtKeyLengths: PropTypes.arrayOf(PropTypes.string).isRequired,
  alert: PropTypes.node,
  protocol: PropTypes.node
};

const PORT_MIN = 1024;
const PORT_MAX = 65535;
const LATENCY_MIN = 20
const LATENCY_MAX = 5000

const SRTForm = (props) => {
  const { config, forbiddenNames, forbiddenUris, localIps, publicIp, translate, srtKeyLengths, alert, protocol } = props;
  const [passwordProtected, setPasswordProtected] = useState(config && config.password ? true : false)

  const uriList = (values) => {
    let uris = null;
    if(values.serverMode){
      uris = inputSrtPushLocalURIs(localIps, values);
      uris.push(inputSrtPushPublicURI(publicIp, values));
    }
    else {
      uris = [inputSrtPullURI(values)];
    }
    return uris;
  };

  const handleFormSubmit = (values) => {

    props.onSubmit(values);
  };

  const handleValidation = (values) => {
    const errors = {};

    // Name
    if(isEmptyString(values.name)){
      errors.name = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.name.length >= 32){
      errors.name = 'genericLabel.TOO_LONG.text';
    }
    else if(forbiddenNames.indexOf(values.name) !== -1){
      errors.name = 'genericLabel.DUPLICATED_VALUES.text';
    }

    if(!values.serverMode) {
      if (isEmptyString(values.uri)) {
        errors.uri = 'genericLabel.REQUIRED_FIELD.text';
      }
    }
    else {
      if (isEmptyString(values.port)) {
        errors.port = 'genericLabel.REQUIRED_FIELD.text';
      }
      else if (values.port < PORT_MIN || values.port > PORT_MAX) {
        errors.port = 'genericLabel.INVALID_FORMAT.text';
      }
      if (isEmptyString(values.keyLength)) {
        errors.latency = 'genericLabel.REQUIRED_FIELD.text';
      }
    }

    if (values.keyLength !== 'Disabled') {
      if (isEmptyString(values.password)) {
        errors.password = 'genericLabel.REQUIRED_FIELD.text';
      }

      // Password with length > 32 characters are not encrypted (Bugs #15905)
      if((!config || (config && config.password !== values.password)) && values.password.length > 32) {
        errors.password = 'genericLabel.TOO_LONG.text';
      }
    }

    if (isEmptyString(values.latency)) {
      errors.latency = 'genericLabel.REQUIRED_FIELD.text';
    } else if (values.latency < LATENCY_MIN || values.latency > LATENCY_MAX) {
      errors.latency = 'genericLabel.INVALID_FORMAT.text';
    }

    // DeJitterBuffer
    if(isEmptyString(values.dejitterBuffer)){
      errors.dejitterBuffer = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(parseInt(values.dejitterBuffer) < DEJITTER_BUFFER_MIN || parseInt(values.dejitterBuffer) > DEJITTER_BUFFER_MAX){
      errors.dejitterBuffer = 'genericLabel.INVALID_FORMAT.text';
    }

    const uris = uriList(values);
    if(uris !== null && uris.find(uri => forbiddenUris.indexOf(uri) !== -1) !== undefined){
      errors.uri = 'genericLabel.DUPLICATED_VALUES.text';
      errors.port = 'genericLabel.DUPLICATED_VALUES.text';
    }
    return errors;
  };

  return (
    <Formik initialValues={{
              id: config ? config.id : undefined,
              type: SRT,
              name: config ? config.name : '',
              serverMode: config ? config.serverMode : true,
              uri: config && config.uri ? config.uri : '',
              port: config && config.port ? config.port : 1024,
              latency: config && config.latency ? config.latency : 125,
              password: config && config.password ? config.password : '',
              keyLength: config && config.keyLength ? config.keyLength : 'Disabled',
              dejitterBuffer: config ? config.dejitterBuffer : DEJITTER_BUFFER_DEFAULT
            }}
            validate={ handleValidation }
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={ handleFormSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
          /* and other goodies */
        }) => (
        <Form onSubmit={ handleSubmit }>

          <HelpLayout
          filename={`c_sh_srt_input_profile.html`}
          form={<fieldset disabled={config && config.enable === true}>
            {alert}
            {protocol}

            <FormGroup>
              <Label for="name">
                <Translate id="genericLabel.NAME.text"/>
              </Label>
              <Input type="text"
                    name="name"
                    id="name"
                    invalid={errors.name !== undefined}
                    placeholder={ translate('genericLabel.NAME.text') }
                    value={values.name}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.name} />
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label for="serverMode">
                <Translate id="genericLabel.SERVER_MODE.text"/>
              </Label>
              <div className="push-pull" id="serverMode" >
                <div className="push">
                  <Translate id="genericLabel.CALLER.text"></Translate>
                </div>
                <AWSwitch checked={values.serverMode}
                          onChange={(checked) => setFieldValue('serverMode', !values.serverMode)}/>
                <div className="pull">
                  <Translate id="genericLabel.LISTENER.text"></Translate>
                </div>
              </div>
            </FormGroup>

            { values.serverMode === true &&
            <FormGroup>
              <Label for="port">
                <Translate id="genericLabel.PORT.text"/>
              </Label>
              <Input type="number"
                    name="port"
                    id="port"
                    min={PORT_MIN}
                    max={PORT_MAX}
                    invalid={errors.port !== undefined}
                    placeholder={ translate('genericLabel.PORT.text') }
                    value={values.port}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.port} />
              </FormFeedback>
            </FormGroup>
            }

            { values.serverMode === false &&
            <FormGroup>
              <Label for="uri">
                <Translate id="genericLabel.STREAM_URL.text"/>
              </Label>
              <Input type="text"
                    name="uri"
                    id="uri"
                    invalid={errors.uri !== undefined}
                    placeholder={ translate('genericLabel.STREAM_URL.text') }
                    value={values.uri}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.uri} />
              </FormFeedback>
            </FormGroup>
            }

            <FormGroup>
              <Label for="latency">
                <Translate id="genericLabel.LATENCY.text"/>
              </Label>
              <Input type="number"
                    name="latency"
                    id="latency"
                    min={LATENCY_MIN}
                    max={LATENCY_MAX}
                    invalid={errors.latency !== undefined}
                    placeholder={ translate('genericLabel.LATENCY.text') }
                    value={values.latency}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.latency} />
              </FormFeedback>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox"
                      name="passwordProtected"
                      onChange={() => {
                        setPasswordProtected(!passwordProtected);
                        // Do not show 'Disabled' value (Task #16694)
                        setFieldValue('keyLength', !passwordProtected ? srtKeyLengths[0] : 'Disabled');
                        setFieldValue('password', '');
                      }}
                      checked={passwordProtected}/>{' '}
                <Translate id="genericLabel.PASSWORD_PROTECTED.text"/>
              </Label>
            </FormGroup>
            { passwordProtected &&
            <>
              <FormGroup>
                <Label for="keyLength">
                  <Translate id="genericLabel.KEY_LENGTH.text"/>
                </Label>
                <Input type="select"
                      name="keyLength"
                      id="keyLength"
                      value={values.keyLength}
                      onChange={handleChange}>
                  { srtKeyLengths.map(keyLength => (
                    <option key={keyLength} value={keyLength}>{ keyLength }</option>
                  ))}
                </Input>
              </FormGroup>
              { values.keyLength !== 'Disabled' &&
              <FormGroup>
                <Label for="password">
                  <Translate id="genericLabel.PASSWORD.text"/>
                </Label>
                <PasswordRevealInput name="password"
                                    id="password"
                                    setFieldValue={setFieldValue}
                                    invalid={errors.password !== undefined}
                                    placeholder={ translate('genericLabel.PASSWORD.text') }
                                    value={values.password}
                                    onChange={handleChange}
                                    error={errors.password}/>
              </FormGroup>
              }
            </>
            }
            <FormGroup>
              <Label for="dejitterBuffer">
                <Translate id="genericLabel.DEJITTER_BUFFER.text"/>
              </Label>
              <Input type="number"
                    name="dejitterBuffer"
                    id="dejitterBuffer"
                    invalid={errors.dejitterBuffer !== undefined}
                    min={DEJITTER_BUFFER_MIN}
                    max={DEJITTER_BUFFER_MAX}
                    placeholder={ translate('genericLabel.DEJITTER_BUFFER.text') }
                    value={values.dejitterBuffer}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.dejitterBuffer} />
              </FormFeedback>
              <div className="indicator">
                <Translate id={`genericLabel.DEJITTER_BUFFER_HELP.text`}/>
              </div>
            </FormGroup>

            <div className="uri-preview">
              { uriList(values).map(uri => (<div key={uri} className="uri">{uri}</div>)) }
            </div>
            </fieldset>}

            buttons={<FormGroup className="buttons">
            { props.onDelete && !props.backToDashboard &&
              <AWConfirm
                onConfirm={props.onDelete}
                confirmationText={ props.translate("genericLabel.SECOND_CLICK_CONFIRM.text") }
                render={(ref, onClick) => (
                  <Button innerRef={ref}
                    onClick={onClick}
                    disabled={config && config.enable === true}
                    className="mr-auto"
                    outline
                    color="primary">
                    <Translate id="genericLabel.DELETE.text"/>
                  </Button>
                )} />
              }
              { props.onCancel && !props.backToDashboard &&
              <Button onClick={props.onCancel}
                outline
                color="primary">
                <Translate id="genericLabel.CANCEL.text"/>
              </Button>
              }
              <Button color="primary"
                      disabled={!dirty}
                      type="submit">
                <Translate id="genericLabel.SAVE.text"/>
              </Button>
            </FormGroup>
          } />
        </Form>
      )}
    </Formik>
  );
};

SRTForm.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  return {
    srtKeyLengths: getAvailableSrtKeyLengths(state)
  }
};

export default connect(mapStateToProps)(withLocalize(SRTForm));