import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {Alert, Button, Form, FormGroup, Label} from "reactstrap";
import {Formik} from "formik";
import PasswordRevealInput from "../password-reveal-input";
import { isEmptyString, isPasswordValid } from '../../../utils/string-utils';
import { updateAdminPassword } from "../../pages/settings/security/security.settings.actions";
import {Translate, withLocalize} from "react-localize-redux";
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";

const propTypes = {
  callUpdatePassword: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  opened: PropTypes.bool
};

const PasswordModal = (props) => {
  const { opened, translate, callUpdatePassword } = props;

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    callUpdatePassword(values)
    resetForm();
  };

  const handleValidation = (values) => {
    const errors = {};
    if(isEmptyString(values.oldPassword)){
      errors.oldPassword = 'genericLabel.REQUIRED_FIELD.text';
    }
    if(isEmptyString(values.newPassword)){
      errors.newPassword = 'genericLabel.REQUIRED_FIELD.text';
    }
    if(isEmptyString(values.newPasswordConfirmation)){
      errors.newPasswordConfirmation = 'genericLabel.REQUIRED_FIELD.text';
    }
    if(!isPasswordValid(values.newPassword)){
      errors.newPassword = 'genericLabel.INVALID_FORMAT.text';
    }
    if(values.newPassword !== values.newPasswordConfirmation){
      errors.newPasswordConfirmation = 'genericLabel.PASSWORD_MISMATCH.text';
    }
    return errors;
  };

  return (
  <AWModal icon="warning"
           open={opened}
           title={ translate("genericLabel.SECURITY_WARNING.text") }>
      { opened &&
        <Fragment>
          <Alert color="warning">
            <Translate id="genericLabel.CHANGE_THE_DEFAULT_CREDENTIALS.text"/>
          </Alert>
          <Formik initialValues={{
                    oldPassword: '',
                    newPassword: '',
                    newPasswordConfirmation: ''
                  }}
                  validate={ handleValidation }
                  validateOnBlur={false}
                  validateOnChange={false}
                  onSubmit={ handleSubmit }>
            {({
                values,
                errors,
                dirty,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
              <Form className="password-form"
                    onSubmit={handleSubmit}>

                <FormGroup>
                  <Label for="oldPassword">
                    <Translate id="genericLabel.PASSWORD.text"/>
                  </Label>
                  <PasswordRevealInput required
                                      name="oldPassword"
                                      id="oldPassword"
                                      invalid={errors.oldPassword !== undefined}
                                      placeholder={translate("genericLabel.PASSWORD.text")}
                                      value={values.oldPassword}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      error={errors.oldPassword}/>
                </FormGroup>

                <FormGroup>
                  <Label for="newPassword">
                    <Translate id="genericLabel.NEW_PASSWORD.text"/>
                  </Label>
                  <PasswordRevealInput required
                                      name="newPassword"
                                      id="newPassword"
                                      invalid={errors.newPassword !== undefined}
                                      placeholder={translate("genericLabel.NEW_PASSWORD.text")}
                                      value={values.newPassword}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      error={errors.newPassword}/>
                  <div className="indicator">
                    <Translate id="genericLabel.PASSWORD_HELP.text"/>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label for="newPasswordConfirmation">
                    <Translate id="genericLabel.CONFIRM_PASSWORD.text"/>
                  </Label>
                  <PasswordRevealInput required
                                      name="newPasswordConfirmation"
                                      id="newPasswordConfirmation"
                                      invalid={errors.newPasswordConfirmation !== undefined}
                                      placeholder={translate("genericLabel.CONFIRM_PASSWORD.text")}
                                      value={values.newPasswordConfirmation}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      error={errors.newPasswordConfirmation}/>
                </FormGroup>

                <FormGroup className="buttons">
                  <Button color="primary"
                          disabled={isSubmitting || !dirty}
                          type="submit">
                    <Translate id="genericLabel.UPDATE.text" />
                  </Button>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </Fragment>
      }
    </AWModal>
  );
};

PasswordModal.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    opened: state.identity.changePasswordRequired ? state.identity.changePasswordRequired : false
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callUpdatePassword: ({ oldPassword, newPassword }) => dispatch(updateAdminPassword(oldPassword, newPassword))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(PasswordModal));