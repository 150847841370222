import PropTypes from "prop-types";
import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";

import Thumbnail from "../../../../common/thumbnail/index";
import Controls from "./playback-controls";
import PlayerSeeker from "../../../../common/player-seeker/index";
import {inputPlaybackPause, inputPlaybackPlay, inputPlaybackSeek, inputPlaybackStop} from "../../dashboard.actions";
import {inputPropTypes} from "../../../../../utils/models-prop-types";
import {formatSeconds} from "../../../../../utils/global-utils";
import {DASHBOARD_LAYOUT_FULLSIZE, DASHBOARD_LAYOUT_GRID, DASHBOARD_LAYOUT_INLINE, STATUS_ERROR} from "../../../../../constants";
import { AWControlGridItemThumbnail, AWControlGridItemContentTitle, AWControlGridItemSelect } from "@aviwest/ui-kit/dist/js/components/control-grid";
import Button from "reactstrap/lib/Button";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import AWBadgeLabel from "@aviwest/ui-kit/dist/js/components/badge-label";
import { formatInputId } from "../../../../../utils/global-utils";

const propTypes = {
  input: inputPropTypes,
  hasViewerLevel: PropTypes.bool,
  layout: PropTypes.oneOf([DASHBOARD_LAYOUT_GRID, DASHBOARD_LAYOUT_INLINE, DASHBOARD_LAYOUT_FULLSIZE]),
  filePosition: PropTypes.number,
  fileDuration: PropTypes.number,
  onSeekTo: PropTypes.func.isRequired,
  onPlay: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  onStop: PropTypes.func.isRequired,
  onCloseDetails: PropTypes.func.isRequired,
  onShowDetails: PropTypes.func.isRequired,
  inputName: PropTypes.string,
};

class Playback extends Component {

  render(){
    const { filePosition, fileDuration, input, inputName } = this.props;
    let errorMessage = (input.status === STATUS_ERROR) ? <Translate id={`messageInfo.${input.deviceMessage}.text`} /> : null;
    return (
        <div className="playback">
          <div className="draggable">
          <AWControlGridItemThumbnail
            overlay={ filePosition && fileDuration &&
              <div>
                {formatSeconds(filePosition) + '/' + formatSeconds(fileDuration)}
                { input.inputInfo &&
                  <div>{ input.inputInfo }</div>
                }
              </div>
            }
            error={errorMessage}>
              <Thumbnail inputId={input.id } timestamp={new Date().getTime()}>
                <AWControlGridItemContentTitle>
                  <AWBadgeLabel left={formatInputId(input.id)}>{inputName}</AWBadgeLabel>
                </AWControlGridItemContentTitle>
              </Thumbnail>
              { !this.props.hasViewerLevel && filePosition && fileDuration &&
                <div className="progress">
                  <PlayerSeeker value={filePosition}
                                onChange={(seeking) => this.props.onSeekTo(input, seeking)}
                                fileDuration={fileDuration}/>
                </div>
              }
            </AWControlGridItemThumbnail>
          </div>

          <AWControlGridItemSelect>
            <Button disabled>
              <AWIcon name="file_input"/>
              <span className="text-clip" title={inputName}>{ inputName }</span>
            </Button>
          </AWControlGridItemSelect>

          <Controls input={input}
                    hasViewerLevel={this.props.hasViewerLevel}
                    layout={this.props.layout}
                    onGoToStart={ () => this.props.onSeekTo(input, 0) }
                    onGoToEnd={ () => this.props.onSeekTo(input, fileDuration) }
                    onPlay={ () => this.props.onPlay(input) }
                    onPause={ () => this.props.onPause(input) }
                    onCloseDetails={ this.props.onCloseDetails }
                    onShowDetails={ this.props.onShowDetails }/>
        </div>
    );
  }
}

Playback.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const { input } = ownProps;
  let filePosition = 0;
  let fileDuration = 0;
  const data = state.datastore.inputPlaybackStats[input.id];
  if(data){
    filePosition = data.filePosition;
    fileDuration = data.fileDuration;
  }
  return {
    filePosition,
    fileDuration
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPlay: (input) => dispatch(inputPlaybackPlay(input)),
    onPause: (input) => dispatch(inputPlaybackPause(input)),
    onSeekTo: (input, seeking) => dispatch(inputPlaybackSeek(input, seeking)),
    onStop: (input) => dispatch(inputPlaybackStop(input)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Playback);