import PropTypes from 'prop-types';
import React from 'react';

import {Translate} from "react-localize-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import { formatMonitorSize } from "../../../../utils/global-utils";

const propTypes = {
  info: PropTypes.shape({
    cpuPercent: PropTypes.number.isRequired,
    memoryPercent: PropTypes.number.isRequired,
    storagePercent: PropTypes.number.isRequired
  })
};

const warningValue = 70;
const alertValue = 90;

const Monitor = (props) => {
  const { info } = props;
  if(!info){
    return null;
  }
  const cpuClass = info.cpuPercent > alertValue ? 'aw-alert' : (info.cpuPercent > warningValue ? 'aw-warning' : '');
  const memoryClass = info.memoryPercent > alertValue ? 'aw-alert' : (info.memoryPercent > warningValue ? 'aw-warning' : '');
  const storageClass = info.storagePercent > alertValue ? 'aw-alert' : (info.storagePercent > warningValue ? 'aw-warning' : '');
  return (
    <div className="system-monitor">
      <div className={`section ${cpuClass}`}>
        <div className="title">
          <AWIcon name="hw_cpu_usage" className={ cpuClass }/>
          <Translate id="genericLabel.CPU.text"/>
        </div>
        <div className="value">
          {`${info.cpuPercent}%`}
        </div>
      </div>
      <div className={`section ${memoryClass}`}>
        <div className="title">
          <AWIcon name="hw_memory_usage" size="BIG" className={ memoryClass }/>
          <Translate id="genericLabel.MEMORY.text"/>
        </div>
        <div className="value">
          {`${info.memoryPercent}%`}
        </div>
        <small><Translate id="genericLabel.PERCENT_USED_OF.text"/> {formatMonitorSize(info.memoryTotal)}</small>
      </div>
      <div className={`section ${storageClass}`}>
        <div className="title">
          <AWIcon name="hw_disk_usage" size="BIG" className={ storageClass }/>
          <Translate id="genericLabel.DISK.text"/>
        </div>
        <div className="value">
          {`${info.storagePercent}%`}
        </div>
        <small><Translate id="genericLabel.PERCENT_USED_OF.text"/> {formatMonitorSize(info.storageCapacity)}</small>
      </div>
    </div>
  );
};

Monitor.propTypes = propTypes;

export default Monitor;