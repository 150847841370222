
export const isEmptyString = (input) => {
  return input !== undefined && typeof input === 'string' && input.trim().length === 0;
};

export const isString = (input) => {
  if(!input) return false;
  return typeof input === 'string' || input instanceof String;
};

export const randomString = (length) => {
  return Math.random().toString(36).substring(length);
};

export const generateUniqueId = () => {
  let result = "";
  const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 5; i++)
    result += possible.charAt(Math.floor(Math.random() * possible.length));
  return result;
};

export const isPasswordValid = (input) => {
  const regex = /^.*(?=.{8,})((?=.*[A-Z])(?=.*\d)(?=.*\W)|(?=.*[a-z])(?=.*\d)(?=.*\W)|(?=.*[a-z])(?=.*[A-Z])(?=.*\W)|(?=.*[a-z])(?=.*[A-Z])(?=.*\d)).*$/;
  return regex.test(input);
};

export const isTunIPValid = (input) => {
  const regex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-4])\.){2}0.0$/;
  return regex.test(input);
};

export const isIPValid = (input) => {
  const regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return regex.test(input);
};

export const isAESKeyValid = (input) => {
  const regex = /^[a-z0-9A-Z]*$/;
  return regex.test(input);
};

export const isLDAPValid = (input) => {
  const regex = /^ldap:\/\/.*$/;
  return regex.test(input);
};

export const isGroupValid = (input) => {
  const regex = /^[a-z0-9A-Z-]+$/;
  return regex.test(input);
};

export const isURLValid = (input) => {
  const regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
  return regex.test(input);
};

export const isFTPRuleValid = (input) => {
  // eslint-disable-next-line
  const regex = /^((\*\.\*)?|(([^\* ]+\*|\*[^\*\. ]+\*|\*[^\* ]*[^\* .]+|[^\* ]+\*[^\* ]*[^\* .]+)[ ]?)*)$/;
  return regex.test(input);
};

export const isFTPNameValid = (input) => {
  const regex = /^[a-zA-Z0-9- _]*$/;
  return regex.test(input);
};

export const isEncoderNameValid = (input) => {
  const regex = /^[a-zA-Z0-9- _]*$/;
  return regex.test(input);
};

export const containsUnderscore = (input) => {
  const regex = /^.*_.*$/;
  return regex.test(input);
};

export const isStreamNameValid = (input) => {
  const regex = /^[a-zA-Z0-9-_\-@.]*$/;
  return regex.test(input);
};