import PropTypes from "prop-types";
import React, {Fragment} from 'react';
import {Translate} from "react-localize-redux";
import {Label, Input, FormGroup, Button, Alert} from "reactstrap";
import {Form, Formik} from "formik";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {videoCardCapabilitiesPropTypes} from "../../../../utils/models-prop-types";
import {VIDEO_CARD_TYPE_DELTACAST} from "../../../../constants";

const propTypes = {
  config: PropTypes.shape({
    '4KMode': PropTypes.bool,
    forceFullFrameRate: false,
    nbSDIInput: PropTypes.number,
    sdiOrder: PropTypes.number,
    videoFrameRate: PropTypes.string
  }).isRequired,
  videoFrameRates: PropTypes.arrayOf(PropTypes.string),
  videoCard: videoCardCapabilitiesPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const VideoCardSettingsForm = (props) => {
  const { config, videoFrameRates, videoCard } = props;

  const handleSubmit = (values, { resetForm }) => {
    props.onSubmit(videoCard.id, values, (videoCard.sdiOrder != null && values.sdiOrder !== config.sdiOrder));
    resetForm(values);
  };

  let sdiOrderArray = null;
  if(videoCard.sdiOrder != null){
    sdiOrderArray = videoCard.sdiOrder.map(order => {
      return order.name.split(',').map(value => parseInt(value));
    });
  }

  return (
    <Formik initialValues={{
              '4KMode': config['4KMode'],
              sdiOrder: config.sdiOrder,
              videoFrameRate: config.videoFrameRate,
              forceFullFrameRate: config.forceFullFrameRate
            }}
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={ handleSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
          /* and other goodies */
        }) => (
        <Form onSubmit={handleSubmit}>
          { videoCard.has4K &&
          <FormGroup check>
            <Label check>
              <Input type="checkbox"
                     name="4KMode"
                     onChange={handleChange}
                     checked={values['4KMode']}/>{' '}
              <Translate id="genericLabel.4K_MODE.text"/>
            </Label>
          </FormGroup>
          }
          { sdiOrderArray &&
          <FormGroup>
            <Label for="sdiOrder">
              <Translate id="genericLabel.SDI_ORDER.text"/>
            </Label>
            { config.sdiOrder !== values.sdiOrder &&
            <Alert color="warning">
              <Translate id="genericLabel.RESTART_STREAMHUB_TO_TAKE_CHANGES_INTO_ACCOUNT.text"/>
            </Alert>
            }
            <div>
              { sdiOrderArray.map((order, index) => (
                <div key={index}
                     className="sdi-order">
                  <Button className="basic"
                          onClick={() => setFieldValue('sdiOrder', index)}>
                    <div className={`sdi-icon ref ${index === values.sdiOrder ? 'active' : ''}`}>
                      <AWIcon name="sdi"/>
                      <div className="sdi-name"><Translate id="genericLabel.SDI_REF.text"/></div>
                    </div>
                    { order.map(orderIndex => (
                      <div key={orderIndex}
                           className={`sdi-icon ${index === values.sdiOrder ? 'active' : ''}`}>
                        <AWIcon name="sdi"/>
                        <div className="sdi-name">{ orderIndex }</div>
                      </div>
                    ))}
                  </Button>
                </div>
              ))}
            </div>
          </FormGroup>
          }
          { videoCard.sdiCardType === VIDEO_CARD_TYPE_DELTACAST &&
          <Fragment>
            <FormGroup>
              <Label for="videoFrameRate">
                <Translate id="genericLabel.VIDEO_FRAME_RATE.text"/>
              </Label>
              <Input type="select"
                     name="videoFrameRate"
                     id="videoFrameRate"
                     value={values.videoFrameRate}
                     onBlur={handleBlur}
                     onChange={handleChange}>
                { videoFrameRates.map(frameRate => (
                  <option key={frameRate} value={frameRate}>{frameRate}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox"
                       name="forceFullFrameRate"
                       onChange={handleChange}
                       checked={values.forceFullFrameRate}/>{' '}
                <Translate id="genericLabel.FORCE_FULL_FRAME_RATE.text"/>
              </Label>
            </FormGroup>
          </Fragment>
          }
          <FormGroup className="buttons">
            <Button color="primary"
                    disabled={isSubmitting || !dirty}
                    type="submit">
              <Translate id="genericLabel.SAVE.text"/>
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

VideoCardSettingsForm.propTypes = propTypes;

export default VideoCardSettingsForm;