import PropTypes from "prop-types";
import React, { Component } from "react";
import {Button, Form, Input} from "reactstrap";
import {Formik} from "formik";
import {withLocalize} from "react-localize-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {filePropTypes} from "../../../utils/models-prop-types";
import {formatDate} from "../../../utils/global-utils";
import {isEmptyString} from "../../../utils/string-utils";
import withLogger from "../../hoc/with-logger";
import {FILE_LAYOUT_ICON, FILE_LAYOUT_INLINE} from "../../../constants";

const propTypes = {
  directory: filePropTypes.isRequired,
  display: PropTypes.oneOf([FILE_LAYOUT_INLINE, FILE_LAYOUT_ICON]),
  editing: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onRename: PropTypes.func,
  onCancel: PropTypes.func
};

const defaultProps = {
  display: FILE_LAYOUT_INLINE,
  editing: false
};

class Folder extends Component {

  constructor(props) {
    super(props);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleValidation(values){
    const errors = {};
    if(isEmptyString(values.name)){
      errors.name = this.props.translate('genericLabel.REQUIRED_FIELD.text');
    }
    return errors;
  }

  handleFormSubmit(values){
    let oldPath = this.props.directory.path;
    const newPath = oldPath.slice(0, oldPath.lastIndexOf(this.props.directory.filename)) + values.name;
    this.props.awlogger.info(oldPath);
    this.props.awlogger.info(newPath);
    if(this.props.onRename){
      this.props.onRename(oldPath, newPath);
    }
    if(this.props.onCancel){
      this.props.onCancel();
    }
  }

  renderForm(){
    const { directory, translate } = this.props;
    return (
      <Formik initialValues={{
                name: directory.filename
              }}
              validate={ this.handleValidation }
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={ this.handleFormSubmit }>
        {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            /* and other goodies */
          }) => (
          <Form onSubmit={ handleSubmit }>
            <Input autoFocus
                   type="text"
                   name="name"
                   id="name"
                   invalid={errors.name != null}
                   placeholder={ translate('genericLabel.NAME.text') }
                   value={values.name}
                   onBlur={this.props.onCancel}
                   onChange={handleChange}/>
            <Button type="submit" style={{ display: 'none' }}/>
          </Form>
        )}
      </Formik>
    );
  }

  render(){
    const { directory, display, editing } = this.props;
    return (
      <div className={ `directory basic ${display === FILE_LAYOUT_ICON ? 'file-grid-item' : ''}`}
           onClick={() => editing ? {} : this.props.onClick(directory.path)}>
        <div className="thumb">
          <AWIcon name="folder"/>
        </div>
        <div className="file-info">
          { editing === false && <div className="name text-clip" title={ directory.filename }>{ directory.filename }</div> }
          { editing === true && this.renderForm() }
          { (editing === false || display !== FILE_LAYOUT_ICON) && directory.stats && <div className="metadata date text-clip" title={formatDate(directory.stats.mtime)}>{ formatDate(directory.stats.mtime) }</div> }
        </div>
      </div>
    );
  }

}

Folder.propTypes = propTypes;
Folder.defaultProps = defaultProps;

export default withLocalize(withLogger(Folder));