import PropTypes from "prop-types";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Translate} from "react-localize-redux";
import {Nav, NavItem, NavLink} from "reactstrap";

import {updateVideoCardSettings} from "./videocards.settings.actions";
import withLogger from "../../../hoc/with-logger";
import {videoCardCapabilitiesPropTypes} from "../../../../utils/models-prop-types";
import Form from "./form";
import { getAvailableVideoCards, getAvailableVideoFrameRates } from "../../../../misc/capabilities.selectors";

const propTypes = {
  config: PropTypes.object,
  videoCards: PropTypes.arrayOf(videoCardCapabilitiesPropTypes),
  videoFrameRates: PropTypes.arrayOf(PropTypes.string).isRequired,
  callUpdateVideoCardSettings: PropTypes.func.isRequired
};

class VideoCardsSettings extends Component {

  constructor(props){
    super(props);

    this.handleCardIndexChange = this.handleCardIndexChange.bind(this);

    this.state = {
      currentCardIndex: (props.videoCards && props.videoCards.length > 0) ? 0 : null
    };
  }

  static getDerivedStateFromProps(props, state){
    if(state.currentCardIndex === null && (props.videoCards && props.videoCards.length > 0)){
      return {
        currentCardIndex: 0
      };
    }
    return null;
  }

  handleCardIndexChange(cardIndex){
    this.setState({
      currentCardIndex: cardIndex
    });
  }

  render() {
    const {
      config,
      videoCards,
      videoFrameRates,
      callUpdateVideoCardSettings
    } = this.props;
    const { currentCardIndex } = this.state;
    let currentVideoCardCapabilities = null;
    if(videoCards){
      currentVideoCardCapabilities = videoCards.find((videoCard, index) => index === currentCardIndex);
    }
    return (
      <div className="settings-section">
        <div className="header">
          <div className="title">
            <Translate id="genericLabel.VIDEO_CARDS.text"/>
          </div>
        </div>
        <Nav tabs>
          { videoCards && videoCards.map((videoCard, index) => (
            <NavItem key={index}>
              <NavLink active={index === currentCardIndex}
                       className="aw-nav-link"
                       href="#"
                       onClick={() => this.handleCardIndexChange(index)}>
                {videoCard.modelName}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        { currentVideoCardCapabilities && config &&
        <div className="content with-menu pretty-scroll">
          <Form config={config[currentVideoCardCapabilities.id]}
                videoFrameRates={videoFrameRates}
                videoCard={currentVideoCardCapabilities}
                onSubmit={callUpdateVideoCardSettings}/>
        </div>
        }
      </div>
    );
  }
}

VideoCardsSettings.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const config = state.config ? state.config.videoCards : null;
  return {
    config,
    videoCards: getAvailableVideoCards(state),
    videoFrameRates: getAvailableVideoFrameRates(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callUpdateVideoCardSettings: (id, data, restart) => dispatch(updateVideoCardSettings(id, data, restart))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withLogger(VideoCardsSettings));