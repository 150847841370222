import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import {Button, Table} from "reactstrap";

import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import File from "./file";
import Folder from "./folder";
import {FILE_LAYOUT_ICON, FILE_LAYOUT_INLINE} from "../../../constants";
import Empty from "../../common/empty";
import {isEmptyString} from "../../../utils/string-utils";
import {filePropTypes} from "../../../utils/models-prop-types";
import PlaybackSelector from "./playback-selector";
import AWConfirm from "@aviwest/ui-kit/dist/js/components/confirm";
import AWLoader from "@aviwest/ui-kit/dist/js/components/loader";

const propTypes = {
  hasAdminLevel: PropTypes.bool,
  hasViewerLevel: PropTypes.bool,
  enableEdition: PropTypes.bool,
  enableForward: PropTypes.bool,
  enableDND: PropTypes.bool,
  enableDownload: PropTypes.bool,
  enablePlayback: PropTypes.bool,
  enablePreview: PropTypes.bool,
  enableSelection: PropTypes.bool,
  files : PropTypes.arrayOf(filePropTypes),
  layout: PropTypes.oneOf([FILE_LAYOUT_ICON, FILE_LAYOUT_INLINE]),
  loading: PropTypes.bool,
  onDelete: PropTypes.func,
  onForward: PropTypes.func,
  onDeselect: PropTypes.func,
  onDisplayDirectoryContent: PropTypes.func.isRequired,
  onPlayback: PropTypes.func,
  onPreviewVideo: PropTypes.func,
  onRename: PropTypes.func,
  onSelect: PropTypes.func,
  path: PropTypes.string.isRequired,
  search: PropTypes.string,
  selectedFiles: PropTypes.arrayOf(filePropTypes),
  sort: PropTypes.object
};

const defaultProps = {
  hasAdminLevel: false,
  hasViewerLevel: false,
  enableEdition: false,
  enableForward: false,
  enableDownload: false,
  enablePlayback: false,
  enablePreview: false,
  enableSelection: false,
  enableDND: false,
  search: ''
};

class FileExplorer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editedElement: {}
    };

    this.cancelEdition = this.cancelEdition.bind(this);
    this.editElement = this.editElement.bind(this);
    this.renderMenu = this.renderMenu.bind(this);
  }

  componentDidMount(){
    this.props.onDisplayDirectoryContent(this.props.path);
  }

  handleInputCheckboxChange(e, element) {
    // Add a selected file
    if (e.target.checked) {
      if(this.props.onSelect){
        this.props.onSelect(element);
      }
    }
    // Remove a selected file
    else {
      if(this.props.onDeselect){
        this.props.onDeselect(element);
      }
    }
  }

  editElement(element) {
    this.setState({
      editedElement: element
    });
  }

  cancelEdition() {
    this.setState({
      editedElement: {}
    });
  }

  renderMenu(element){
    const {
      hasAdminLevel,
      hasViewerLevel,
      enablePlayback,
      enableEdition,
      enableForward,
      enableDownload,
      enablePreview,
      translate
    } = this.props;
    const isReady = element.stats ? element.stats.uid > 0 : true;
    const canDownload = enableDownload && element.type === 'file' && isReady;
    const isVideo = element.filetype === 'video';
    const canPlayback = enablePlayback && this.props.onPlayback && isVideo && isReady;
    const canPreview = enablePreview && this.props.onPreviewVideo && isVideo && element.player && isReady;
    const canEdit = enableEdition && isReady;
    const canDelete = hasAdminLevel && (!enableForward || element.type === 'file');
    const canForward = enableForward && element.type === 'file';
    if(!canPlayback && !canPreview && !canEdit){
      return null;
    }
    return (
      <div className="menu">
        { canDownload && !element.filename.endsWith('part') &&
        <a className="btn basic" href={ `/download/${element.token}` } target="_blank" rel="noopener noreferrer">
          <AWIcon name="download_cloud" title={translate("genericLabel.DOWNLOAD.text")}/>
        </a>
        }
        { !hasViewerLevel && canPlayback &&
        <PlaybackSelector onChange={(input) => this.props.onPlayback(input, element)}/>
        }
        { canPreview &&
        <Button className="basic"
                onClick={() => this.props.onPreviewVideo(element)}>
          <AWIcon name="preview"/>
        </Button>
        }
        { canEdit &&
        <Fragment>
          { canForward &&
          <Button className="basic"
                  disabled={hasViewerLevel}
                  onClick={() => this.props.onForward([element])}>
            <AWIcon name="forward"/>
          </Button>
          }
          <Button className="basic"
                  disabled={hasViewerLevel || element.filename.endsWith('part')}
                  onClick={() => this.editElement(element)}>
            <AWIcon name="edit"/>
          </Button>
          { canDelete &&
          <AWConfirm onConfirm={() => this.props.onDelete([element])}
                     confirmationText={translate("genericLabel.SECOND_CLICK_CONFIRM.text")}
                     render={(ref, onClick) => (
                     <Button innerRef={ref} className="basic" onClick={onClick}>
                       <AWIcon name="delete" size="SMALL"/>
                     </Button>
                     )}/>
          }
        </Fragment>
        }
      </div>
    )
  }


  render() {
    const {
      enableForward,
      enableDND,
      enableSelection,
      files,
      layout,
      loading,
      search,
      selectedFiles,
      sort
    } = this.props;
    const filteredFiles = files.filter(file => isEmptyString(search) || file.filename.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    console.log('filteredFiles', filteredFiles)
    if (sort.type === 'alpha') {
      filteredFiles.sort((a, b) => {return a.filename - b.filename})
    }
    if (sort.type === 'numeric') {
      filteredFiles.sort((a, b) => {return a.stats?.mtimeMs - b.stats?.mtimeMs})
    }
    if (sort.reverse) {
      filteredFiles.reverse()
    }
    const empty = filteredFiles.length === 0;
    return (
      <Fragment>
        { layout === FILE_LAYOUT_INLINE && empty === false && loading === false &&
        <Table borderless
               className="aw-table file-list"
               hover>
          <tbody>

          { filteredFiles.map(element => (
            <tr key={element.path}>
              { enableSelection &&
              <td className="selection">
                <input checked={selectedFiles.indexOf(element) !== -1 }
                       disabled={(enableForward && element.type === 'directory') || (element.stats && element.stats.uid === 0)}
                       type="checkbox"
                       onChange={(e) => this.handleInputCheckboxChange(e, element)}/>
              </td>
              }
              <td>
                { element.type === 'file' &&
                <File enableDND={enableDND}
                      editing={this.state.editedElement.filename === element.filename}
                      file={element}
                      onCancel={this.cancelEdition}
                      onRename={this.props.onRename}/>
                }
                { element.type === 'directory' &&
                <Folder directory={element}
                        editing={this.state.editedElement.filename === element.filename}
                        onRename={this.props.onRename}
                        onCancel={this.cancelEdition}
                        onClick={this.props.onDisplayDirectoryContent}/>
                }
              </td>
              <td className="actions">
                <div className="actions-menu">
                  { this.renderMenu(element) }
                </div>
              </td>
            </tr>
          ))}

          </tbody>
        </Table>
        }
        { layout === FILE_LAYOUT_ICON && empty === false && loading === false &&
        <div className="file-grid">
          { filteredFiles.map(element => (
            <div key={element.path}
                 className="file-item">
              { element.type === 'file' &&
              <File enableDND={enableDND}
                    display={FILE_LAYOUT_ICON}
                    editing={this.state.editedElement.filename === element.filename}
                    file={element}
                    onCancel={this.cancelEdition}
                    onRename={this.props.onRename}/>
              }
              { element.type === 'directory' &&
              <Folder directory={element}
                      display={FILE_LAYOUT_ICON}
                      editing={this.state.editedElement.filename === element.filename}
                      onRename={this.props.onRename}
                      onCancel={this.cancelEdition}
                      onClick={this.props.onDisplayDirectoryContent}/>
              }
              { this.renderMenu(element) }
            </div>
          ))}
        </div>
        }
        { loading === true && <AWLoader active={true} position="centered"/> }
        { empty === true && loading === false && isEmptyString(search) &&
        <Empty icon="folder_open"
               text={<Translate id="genericLabel.FILES_EMPTY.text"/>}/>
        }
        { empty === true && loading === false && !isEmptyString(search) &&
        <Empty icon="search"
               text={<Translate id="genericLabel.FILES_SEARCH_MATCH_EMPTY.text"/>}
               subText={`"${search}"`}/>
        }
      </Fragment>
    );
  }
}

FileExplorer.propTypes = propTypes;
FileExplorer.defaultProps = defaultProps;

export default withLocalize(FileExplorer);