import { Component } from 'react';
import { connect } from 'react-redux';
import {
  DEVICE_PRODUCT_DMNGPRO,
  USER_ROLE_ADMIN,
} from "../../../../../constants";
import PropTypes from 'prop-types';
import {identityPropTypes, inputPropTypes} from "../../../../../utils/models-prop-types";
import Logger from "../../../../../utils/logger";
import {getConfiguratorPort} from "../../../../../utils/global-utils";
import {remoteControlTokenReplace} from "../../dashboard.actions";

const propTypes = {
  identity: identityPropTypes.isRequired,
  input: inputPropTypes.isRequired,
  webProxyPort: PropTypes.number,
  closeModal: PropTypes.func,
  callRemoteControlTokenReplace: PropTypes.func
};

class RemoteControl extends Component {

  render(){
    const { input, identity, webProxyPort, closeModal, callRemoteControlTokenReplace } = this.props;
    const { role } = identity;
    let url = null;
    const baseUrl = `${window.location.protocol}//${window.location.hostname}`;
    const port = window.location.port;
    const configuratorPort = getConfiguratorPort(input);

    if (role === USER_ROLE_ADMIN) {
      if(input.deviceTunnelIPAddress) {
        if(input.deviceProduct === DEVICE_PRODUCT_DMNGPRO) {
          url = `${baseUrl}:${port}/webconf/${input.deviceTunnelIPAddress}:8888/index-pc-desktop.html`;
        }
        else {
          url = `${baseUrl}:${webProxyPort}/sso/${identity.token}/tunIp/${input.deviceTunnelIPAddress}:8888/encoderId/${parseInt(input.instanceId) - 1}`;
        }
      }
      else {
        url = `${baseUrl}:${port}/webconf/10.0.0.2:${configuratorPort}/index-pc-desktop.html`;
      }
    }
    else {
      if(input.deviceTunnelIPAddress) {
        if(input.deviceProduct === DEVICE_PRODUCT_DMNGPRO) {
          url = `${baseUrl}:${port}/webconf/${input.deviceTunnelIPAddress}:8888/dmngpro_index.html`;
        }
        else {
          url = `${baseUrl}:${webProxyPort}/sso/${identity.token}/tunIp/${input.deviceTunnelIPAddress}:8888/encoderId/${parseInt(input.instanceId) - 1}`;
        }
      }
      else {
        url = `${baseUrl}:${port}/webconf/10.0.0.2:${configuratorPort}/dmngpro_index.html`;
      }
    }

    // Recent devices need a token with a custom passphrase containing hardware identifier (User Story #16329)
    const version = input.deviceVersion ? parseInt(input.deviceVersion.split('.')[0]) : 0
    if (input.deviceTunnelIPAddress && input.deviceProduct !== DEVICE_PRODUCT_DMNGPRO) {
      if (
        // Air or Pro3 with version >= 5.X
        (version >= 5 && (input.firmwareName === 'air' || input.firmwareName === 'pro3'))
        ||
        // Other devices (pro4, rack4, etc.)
        (input.firmwareName !== 'air' && input.firmwareName !== 'pro3')
      ) {
        // Replace token with hardware identifier in secret
        callRemoteControlTokenReplace(url.replace('https', 'http'), input.deviceHardwareID)
      } else {
        // Replace token without hardware identifier in secret
        callRemoteControlTokenReplace(url.replace('https', 'http'), '')
      }
    } else {
      // webconf: force opening remote with http in a new tab and close modal
      window.open(url.replace('https', 'http'), 'aw-remote');
    }
    Logger.info('URL: ', url);
    closeModal();
    return (null);
  }

}

RemoteControl.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callRemoteControlTokenReplace: (url, deviceHardwareID) => dispatch(remoteControlTokenReplace(url, deviceHardwareID)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoteControl);