import {Formik, FieldArray} from "formik";
import PropTypes from "prop-types";
import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {Button, Form, FormFeedback, FormGroup, Input, Label, InputGroupAddon, Row, Col} from "reactstrap";
import {Translate, withLocalize} from "react-localize-redux";
import {isEmptyString, isGroupValid, isIPValid} from "../../../../utils/string-utils";
import {isEmpty} from "../../../../utils/global-utils";
import {DEJITTER_BUFFER_MAX, DEJITTER_BUFFER_MIN, DEJITTER_BUFFER_DEFAULT, NDI} from "../../../../constants";
import AWSwitch from "@aviwest/ui-kit/dist/js/components/switch";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import {getNDIStreams, prepNDIFinder} from "../settings.actions";
import AWConfirm from "@aviwest/ui-kit/dist/js/components/confirm";
import HelpLayout from "../../../common/help-layout";

const propTypes = {
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isLowBandwidth: PropTypes.bool,
    DeJitterBuffer: PropTypes.number,
    uri: PropTypes.string,
    groups: PropTypes.string,
    extraIPs: PropTypes.string
  }),
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  ndiStreams: PropTypes.arrayOf(PropTypes.string),
  callGetNDIStreams: PropTypes.func.isRequired,
  callPrepNDIFinder: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  alert: PropTypes.node,
  protocol: PropTypes.node
};

//const NDIForm = (props) => {
class NDIForm extends Component {

  constructor(props){
    super(props);

    this.toggleNDIStreamMode = this.toggleNDIStreamMode.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);

    this.state = {
      ndiStreamManual: true,
      step: 1
    }
  }

  handleNext(setErrors, values) {
    const errors = {};

    // Name
    if(isEmptyString(values.name)){
      errors.name = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.name.length >= 32){
      errors.name = 'genericLabel.TOO_LONG.text';
    }
    else if(this.props.forbiddenNames.indexOf(values.name) !== -1){
      errors.name = 'genericLabel.DUPLICATED_VALUES.text';
    }

    // DeJitterBuffer
    if(isEmptyString(values.dejitterBuffer)){
      errors.dejitterBuffer = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(parseInt(values.dejitterBuffer) < DEJITTER_BUFFER_MIN || parseInt(values.dejitterBuffer) > DEJITTER_BUFFER_MAX){
      errors.dejitterBuffer = 'genericLabel.INVALID_FORMAT.text';
    }

    // Groups
    values.groups.forEach((group, index, groups) => {
      if(!errors.groups){
        errors.groups = [];
      }
      if(isEmptyString(group)){
        errors.groups[index] = 'genericLabel.REQUIRED_FIELD.text';
      }
      else if (!isGroupValid(group)) {
        errors.groups[index] = 'genericLabel.INVALID_FORMAT.text';
      }
      else if (groups.indexOf(group) !== index) {
        errors.groups[index] = 'genericLabel.DUPLICATED_VALUES.text';
      }
      // Remove unused groups property from erros to allow submission
      if (errors.groups.length === 0) {
        delete(errors.groups)
      }
    })

    // Extra IP
    values.extraIPs.forEach((extraIP, index, extraIPs) => {
      if(!errors.extraIPs){
        errors.extraIPs = [];
      }
      if(isEmptyString(extraIP)){
        errors.extraIPs[index] = 'genericLabel.REQUIRED_FIELD.text';
      }
      else if (!isIPValid(extraIP)) {
        errors.extraIPs[index] = 'genericLabel.INVALID_FORMAT.text';
      }
      else if (extraIPs.indexOf(extraIP) !== index) {
        errors.extraIPs[index] = 'genericLabel.DUPLICATED_VALUES.text';
      }
      // Remove unused extraIPs property from erros to allow submission
      if (errors.extraIPs.length === 0) {
        delete(errors.extraIPs)
      }
    })

    if (!isEmpty(errors)) {
      setErrors(errors)
    }
    else {
      const { groups, extraIPs, ...otherProps } = values;
      otherProps.groups = groups.join(',');
      otherProps.extraIPs = extraIPs.join(',');
      // Prepare NDI Finder
      this.props.callPrepNDIFinder(otherProps.groups, otherProps.extraIPs);
      this.setState({
        step: 2
      });
    }
  }

  handlePrevious() {
    this.setState({
      ndiStreamManual: true,
      step: 1
    });
  }

  toggleNDIStreamMode(setFieldValue){
    setFieldValue('uri', '')
    this.setState({
      ndiStreamManual: !this.state.ndiStreamManual
    });
    this.props.callGetNDIStreams()
  }

  handleFormSubmit(values){
    const { groups, extraIPs, ...otherProps } = values;
    otherProps.groups = groups.join(',');
    otherProps.extraIPs = extraIPs.join(',');
    this.props.onSubmit(otherProps);
  };

  handleValidation(values){
    const errors = {};

    // uri
    let uriRegex = /^[a-zA-Z0-9-]+ \([a-zA-Z0-9- ]+\)$/;
    if(isEmptyString(values.uri)){
      errors.uri = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if (!uriRegex.test(values.uri)) {
      errors.uri = 'genericLabel.INVALID_FORMAT.text';
    }

    return errors;
  };

  render(){
    const { config, ndiStreams, translate, callGetNDIStreams, alert, protocol } = this.props;
    return (
      <Formik initialValues={{
                id: config ? config.id : undefined,
                type: NDI,
                name: config ? config.name : '',
                uri: config ? config.uri : '',
                isLowBandwidth: config ? config.isLowBandwidth : false,
                dejitterBuffer: config ? config.dejitterBuffer : DEJITTER_BUFFER_DEFAULT,
                groups: config && config.groups ? config.groups.split(',') : [],
                extraIPs: config && config.extraIPs ? config.extraIPs.split(',') : []
              }}
              validate={ this.handleValidation }
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={ this.handleFormSubmit }>
        {({
            values,
            errors,
            dirty,
            isValid,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setErrors
            /* and other goodies */
          }) => (
          <Form onSubmit={ handleSubmit }>
            <HelpLayout
            filename={`c_sh_ndi_input_profile.html`}
            form={<fieldset disabled={config && config.enable === true}>
              {alert}
              {protocol}

              { this.state.step === 1 &&
              <FormGroup>
                <Label for="name">
                  <Translate id="genericLabel.NAME.text"/>
                </Label>
                <Input type="text"
                      name="name"
                      id="name"
                      invalid={errors.name !== undefined}
                      placeholder={ translate('genericLabel.NAME.text') }
                      value={values.name}
                      onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.name} />
                </FormFeedback>
              </FormGroup>
              }
              { this.state.step === 2 &&
              <FormGroup>
                <Label for="uri">
                  <Translate id="genericLabel.NDI_STREAM_NAME.text"/>
                </Label>
                <div className="input-group">
                  <InputGroupAddon addonType="prepend">
                  <div className="push-pull">
                    <div className="push">
                      <Translate id="genericLabel.MANUAL.text"></Translate>
                    </div>
                    <AWSwitch checked={!this.state.ndiStreamManual}
                            onChange={() => this.toggleNDIStreamMode(setFieldValue)}/>
                    <div className="pull">
                      <Translate id="genericLabel.DISCOVERY.text"></Translate>
                    </div>
                  </div>
                  </InputGroupAddon>
                  { this.state.ndiStreamManual &&
                  <Input type="text"
                        name="uri"
                        id="uri"
                        invalid={errors.uri !== undefined}
                        placeholder={ translate('genericLabel.NDI_STREAM_PLACEHOLDER.text') }
                        value={values.uri}
                        onChange={handleChange}/>
                  }
                  { !this.state.ndiStreamManual &&
                  <Input type="select"
                        name="uri"
                        id="uri"
                        invalid={errors.uri !== undefined}
                        onChange={handleChange}
                        value={values.uri}>
                    <option value=""></option>
                    { ndiStreams.map((ndiStream, index) => (
                      <option key={`ndiStream${index}`} value={ndiStream}>{ ndiStream }</option>
                    ))}
                  </Input>
                  }
                  { !this.state.ndiStreamManual &&
                  <InputGroupAddon addonType="append">
                    <Button className="no-top-padding no-bottom-padding"
                            color="secondary"
                            onClick={callGetNDIStreams}>
                      <AWIcon name={ 'refresh' }/>
                    </Button>
                  </InputGroupAddon>
                  }
                  <FormFeedback>
                    <Translate id={errors.uri} />
                  </FormFeedback>
                </div>
              </FormGroup>
              }
              { this.state.step === 1 &&
              <FormGroup>
                <Label for="dejitterBuffer">
                  <Translate id="genericLabel.DEJITTER_BUFFER.text"/>
                </Label>
                <Input type="number"
                      name="dejitterBuffer"
                      id="dejitterBuffer"
                      invalid={errors.dejitterBuffer !== undefined}
                      min={DEJITTER_BUFFER_MIN}
                      max={DEJITTER_BUFFER_MAX}
                      placeholder={ translate('genericLabel.DEJITTER_BUFFER.text') }
                      value={values.dejitterBuffer}
                      onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.dejitterBuffer} />
                </FormFeedback>
                <div className="indicator">
                  <Translate id={`genericLabel.DEJITTER_BUFFER_HELP.text`}/>
                </div>
              </FormGroup>
              }
              { this.state.step === 1 &&
              <FormGroup check>
                <Label check>
                  <Input type="checkbox"
                        name="isLowBandwidth"
                        onChange={handleChange}
                        checked={values.isLowBandwidth}/>{' '}
                  <Translate id="genericLabel.IS_LOW_BANDWITH.text"/>
                </Label>
              </FormGroup>
              }
              { this.state.step === 1 &&
              <FormGroup>
                <Label>
                  <Translate id="genericLabel.NDI_GROUPS.text" />
                  <div className="indicator">
                    <Translate id={`genericLabel.NDI_GROUPS_HELP.text`}/>
                  </div>
                </Label>
                <FieldArray name="groups"
                            validateOnChange={false}>
                  {({ push, remove }) => {
                    return (
                      <Fragment>
                        { values.groups.map((group, index) => (
                          <Row key={index}
                              form>
                            <Col xs={10}>
                              <FormGroup>
                                <Input type="text"
                                      name={`groups[${index}]`}
                                      invalid={errors.groups && errors.groups[index] !== undefined}
                                      onChange={handleChange}
                                      value={values.groups[index]}/>
                                <FormFeedback>
                                  <Translate id={errors.groups && errors.groups[index]}/>
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col xs={2}>
                              { values.groups.length > 0 &&
                              <Button className="basic"
                                      onClick={() => remove(index)}>
                                <AWIcon name="delete"/>
                              </Button>
                              }
                            </Col>
                          </Row>
                        ))}
                        <div>
                          <Button disabled={values.groups.length >= 12}
                                  onClick={() => push('')}>
                            <Translate id="genericLabel.ADD_GROUP.text"/>
                          </Button>
                        </div>
                      </Fragment>
                    )}}
                </FieldArray>
              </FormGroup>
              }
              { this.state.step === 1 &&
              <FormGroup>
                <Label>
                  <Translate id="genericLabel.NDI_EXTRA_IP.text" />
                  <div className="indicator">
                    <Translate id={`genericLabel.NDI_EXTRA_IP_HELP.text`}/>
                  </div>
                </Label>
                <FieldArray name="extraIPs"
                            validateOnChange={false}>
                  {({ push, remove }) => {
                    return (
                      <Fragment>
                        { values.extraIPs.map((extraIP, index) => (
                          <Row key={index}
                              form>
                            <Col xs={10}>
                              <FormGroup>
                                <Input type="text"
                                      name={`extraIPs[${index}]`}
                                      invalid={errors.extraIPs && errors.extraIPs[index] !== undefined}
                                      onChange={handleChange}
                                      value={extraIP}/>
                                <FormFeedback>
                                  <Translate id={errors.extraIPs && errors.extraIPs[index]}/>
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col xs={2}>
                              { values.extraIPs.length > 0 &&
                              <Button className="basic"
                                      onClick={() => remove(index)}>
                                <AWIcon name="delete"/>
                              </Button>
                              }
                            </Col>
                          </Row>
                        ))}
                        <div>
                          <Button disabled={values.extraIPs.length >= 12}
                                  onClick={() => push('')}>
                            <Translate id="genericLabel.ADD_EXTRA_IP.text"/>
                          </Button>
                        </div>
                      </Fragment>
                    )}}
                </FieldArray>
              </FormGroup>
              }
              </fieldset>}

              buttons={<FormGroup className="buttons">
                { this.props.onDelete && !this.props.backToDashboard &&
                <AWConfirm
                  onConfirm={this.props.onDelete}
                  confirmationText={ this.props.translate("genericLabel.SECOND_CLICK_CONFIRM.text") }
                  render={(ref, onClick) => (
                    <Button innerRef={ref}
                      onClick={onClick}
                      disabled={config && config.enable === true}
                      className="mr-auto"
                      outline
                      color="primary">
                      <Translate id="genericLabel.DELETE.text"/>
                    </Button>
                  )} />
                }
                { this.props.onCancel && !this.props.backToDashboard &&
                <Button onClick={this.props.onCancel}
                  outline
                  color="primary">
                  <Translate id="genericLabel.CANCEL.text"/>
                </Button>
                }
                { this.state.step === 1 &&
                <Button color="primary"
                        outline
                        onClick={() => this.handleNext(setErrors, values)}>
                  <Translate id="genericLabel.NEXT.text"/>
                </Button>
                }
                { this.state.step === 2 &&
                <Button color="primary"
                        outline
                        onClick={() => this.handlePrevious()}>
                  <Translate id="genericLabel.PREVIOUS.text"/>
                </Button>
                }
                <Button color="primary"
                        disabled={ !dirty || this.state.step === 1 }
                        type="submit">
                  <Translate id="genericLabel.SAVE.text"/>
                </Button>
              </FormGroup>
            } />
          </Form>
        )}
      </Formik>
    )
  }
};

NDIForm.propTypes = propTypes;

const mapStateToProps = (state) => {
  let ndiStreams = state.datastore.ndiStreams;
  return {
    ndiStreams
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    callGetNDIStreams: () => dispatch(getNDIStreams()),
    callPrepNDIFinder: (groups, extraIPs) => dispatch(prepNDIFinder(groups, extraIPs))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(NDIForm));
