import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {DragSource} from 'react-dnd';
import {Formik} from "formik";
import {Button, Form, Input} from "reactstrap";
import {withLocalize} from "react-localize-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {DND_ITEM_TYPE_FILE, FILE_LAYOUT_ICON, FILE_LAYOUT_INLINE} from '../../../constants';
import {filePropTypes} from "../../../utils/models-prop-types";
import {fileImagePreviewUrl, formatDate, formatFileSize} from "../../../utils/global-utils";
import withLogger from "../../hoc/with-logger";
import {isEmptyString} from "../../../utils/string-utils";

const propTypes = {
  file: filePropTypes.isRequired,
  display: PropTypes.oneOf([FILE_LAYOUT_INLINE, FILE_LAYOUT_ICON]),
  editing: PropTypes.bool,
  enableDND: PropTypes.bool,
  connectDragSource: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onRename: PropTypes.func,
  isDragging: PropTypes.bool.isRequired
};

const defaultProps = {
  display: FILE_LAYOUT_INLINE,
  editing: false,
  enableDND: false
};

const dndInputSource = {
  beginDrag(props) {
    return {
      file: props.file
    };
  },
  canDrag(props){
    return props.file.filetype === 'video' && props.enableDND;
  }
};

const dndCollect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
};

class File extends Component {

  constructor(props) {
    super(props);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleValidation(values){
    const errors = {};
    if(isEmptyString(values.name)){
      errors.name = this.props.translate('genericLabel.REQUIRED_FIELD.text');
    }
    return errors;
  }

  handleFormSubmit(values){
    let oldPath = this.props.file.path;
    let newPath = oldPath.slice(0, oldPath.lastIndexOf(this.props.file.filename)) + `${values.name}${values.extension}`;
    this.props.awlogger.info(oldPath);
    this.props.awlogger.info(newPath);
    if(this.props.onRename){
      this.props.onRename(oldPath, newPath);
    }
    if(this.props.onCancel){
      this.props.onCancel();
    }
  }

  renderForm(){
    const { file, translate } = this.props;
    let name = null;
    let extension = null;
    if(file.filename.lastIndexOf(".") > -1){
      name = file.filename.slice(0, file.filename.lastIndexOf("."));
      extension = file.filename.slice(file.filename.lastIndexOf("."))
    }
    else {
      name = file.filename;
      extension = '';
    }
    return (
      <Formik initialValues={{
                name,
                extension
              }}
              validate={ this.handleValidation }
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={ this.handleFormSubmit }>
        {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleSubmit,
            /* and other goodies */
          }) => (
          <Form onSubmit={ handleSubmit }>
            <Input autoFocus
                   type="text"
                   name="name"
                   id="name"
                   invalid={errors.name != null}
                   placeholder={ translate('genericLabel.NAME.text') }
                   value={values.name}
                   onBlur={this.props.onCancel}
                   onChange={handleChange}/>
            <Button type="submit" style={{ display: 'none' }}/>
          </Form>
        )}
      </Formik>
    );
  }

  renderThumb(file){
    return (
      <div className="thumb">
        { file.filetype === 'video' && <AWIcon name="file_video"/> }
        { file.filetype === 'image' && <img src={fileImagePreviewUrl(file)} alt="thumb"/> }
        { file.filetype !== 'video' && file.filetype !== 'image' && <AWIcon name="file"/> }
      </div>
    );
  }

  render(){
    const { connectDragSource, display, editing, file } = this.props;
    return connectDragSource(
      <div className={ `file ${dndInputSource.canDrag(this.props) ? 'draggable' : ''} ${display === FILE_LAYOUT_ICON ? 'file-grid-item' : ''}`}>
        { this.renderThumb(file) }
        <div  className="file-info">
          { editing === false && <div className="name text-clip" title={file.filename}>{ file.filename }</div> }
          { editing === true && this.renderForm() }
          { (editing === false || display !== FILE_LAYOUT_ICON) && file.stats &&
          <Fragment>
            <div className="metadata date text-clip" title={formatDate(file.stats.mtime)}>{formatDate(file.stats.mtime)}</div>
            <div className="metadata file-size text-clip" title={formatFileSize(file.stats.size)}>{formatFileSize(file.stats.size)}</div>
          </Fragment>
          }
        </div>
      </div>
    );
  }
}

File.propTypes = propTypes;
File.defaultProps = defaultProps;

export default DragSource(DND_ITEM_TYPE_FILE, dndInputSource, dndCollect)(withLocalize(withLogger(File)));