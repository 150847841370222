import PropTypes from "prop-types";
import React from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {Button, Form, FormGroup} from "reactstrap";
import {Formik} from "formik";
import Dropzone from "react-dropzone";

import {hasFileExtension} from "../../../../utils/global-utils";
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";

const propTypes = {
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};

const ImportConfigModal = (props) => {
  const { onCancel, onUpdate } = props;

  const handleFormSubmit = (values) => {
    onUpdate(values.file);
  };

  const handleValidation = (values) => {
    const errors = {};
    if(!values.file){
      errors.file = props.translate('genericLabel.REQUIRED_FIELD.text');
    }
    if(values.file && !hasFileExtension(values.file.name, 'awj')){
      errors.file = props.translate('genericLabel.INVALID_EXTENSION.text', { 'param.extension': '.awj' });
    }
    return errors;
  };

  return (
  <AWModal confirm={true}
           icon="file_import"
           onClose={ onCancel }
           open={true}
           title={<Translate id="genericLabel.CHOOSE_FILE_FROM_PREVIOUS_EXPORT.text"/>}>
      <Formik initialValues={{
                file: null
              }}
              validate={ handleValidation }
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={ handleFormSubmit }>
        {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            /* and other goodies */
          }) => (
          <Form onSubmit={handleSubmit}>
            <div className="confirm-text">
              <Translate id="genericLabel.CHOOSE_FILE_FROM_PREVIOUS_EXPORT.text"/>
              <div className="indicator"><Translate id="genericLabel.IMPORT_CONFIG_STREAMHUB_RESTART.text"/></div>
            </div>
            <FormGroup>
              <Dropzone multiple={false}
                        accept=".awj"
                        onDrop={files => setFieldValue('file', files[0])}>
                {({getRootProps, getInputProps, isDragActive, acceptedFiles}) => (
                  <div className={`dropzone ${isDragActive ? 'target' : (errors.file != null ? 'error' : '')}`} {...getRootProps()}>
                            <span className="placeholder">
                              {acceptedFiles.length === 0 && <Translate id="genericLabel.CONFIG_DROPZONE_PLACEHOLDER.text"/>}
                              {acceptedFiles.length > 0 &&
                              <div className="filename">
                                {acceptedFiles.map(acceptedFile => acceptedFile.name)}
                              </div>
                              }
                              {errors.file != null && errors.file}
                            </span>
                    <input {...getInputProps()} />
                  </div>
                )}
              </Dropzone>
            </FormGroup>
            <FormGroup className="buttons">
              <Button color="primary"
                      disabled={!dirty}
                      size="lg"
                      type="submit">
                <Translate id="genericLabel.UPDATE.text"/>
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </AWModal>
  );
};

ImportConfigModal.propTypes = propTypes;

export default withLocalize(ImportConfigModal);