import PropTypes from "prop-types";
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Translate} from "react-localize-redux";
import {Button} from "reactstrap";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import EncodingCapabilities from "./encoding-capabilities";
import {systemPropTypes} from "../../../../utils/models-prop-types";
import {
  checkVideoCardFirmware,
  confirmFirmwareUpdate, generateReport,
  haltServer,
  haltServerModal, interruptFirmwareUpdate,
  rebootServer,
  rebootServerModal,
  restartStreamhub,
  restartStreamhubModal,
  updateFirmware,
  updateFirmwareModal,
  updateVideoCardFirmware,
  updateVideoCardFirmwareModal
} from "./system.tools.actions";
import RestartStreamhubModal from "./restart-streamhub-modal";
import RebootServerModal from "./reboot-server-modal";
import HaltServerModal from "./halt-server-modal";
import UpdateFirmwareModal from "./update-firmware-modal";
import UpdateVideoCardFirmwareModal from "./update-video-card-firmware-modal";
import Monitor from "./monitor";
import { USER_ROLE_USER, USER_ROLE_VIEWER } from "../../../../constants"

const propTypes = {
  hasUserLevel: PropTypes.bool,
  hasViewerLevel: PropTypes.bool,
  callConfirmFirmwareUpdate: PropTypes.func.isRequired,
  callRestartStreamhubModal: PropTypes.func.isRequired,
  callRebootServerModal: PropTypes.func.isRequired,
  callHaltServerModal: PropTypes.func.isRequired,
  callUpdateFirmwareModal: PropTypes.func.isRequired,
  callUpdateVideoCardFirmwareModal: PropTypes.func.isRequired,
  callGenerateReport: PropTypes.func.isRequired,
  monitor: PropTypes.object,
  system: systemPropTypes,
  restartStreamhubModalOpened: PropTypes.bool.isRequired,
  rebootServerModalOpened: PropTypes.bool.isRequired,
  haltServerModalOpened: PropTypes.bool.isRequired,
  updateFirmwareModalOpened: PropTypes.bool.isRequired,
  updateVideoCardFirmwareModalOpened: PropTypes.bool.isRequired,
  videoCardFirmwareState: PropTypes.number,
  uploadingFirmwareFile: PropTypes.instanceOf(File),
  uploadingFirmwareResult: PropTypes.number,
  uploadingProgress: PropTypes.number.isRequired,
  generatingReport: PropTypes.bool.isRequired,
  version: PropTypes.string
};

class SystemTools extends Component {

  render() {
    const {
      monitor,
      system,
      restartStreamhubModalOpened,
      rebootServerModalOpened,
      haltServerModalOpened,
      updateFirmwareModalOpened,
      updateVideoCardFirmwareModalOpened,
      videoCardFirmwareState,
      uploadingFirmwareFile,
      uploadingFirmwareResult,
      uploadingProgress,
      generatingReport,
      version,
      hasUserLevel,
      hasViewerLevel
    } = this.props;
    return (
        <div className="tools-section">
          <div className="header">
            <div className="title">
              <Translate id="genericLabel.SYSTEM.text"/>
            </div>
          </div>
          { system &&
          <div className="content pretty-scroll">
            <div className="hardware-spec">
              <ul>
                <li><span className="label"><Translate id="genericLabel.PROCESSOR.text"/></span>{system.cpuModelName}</li>
                <li><span className="label"><Translate id="genericLabel.INTEL_GPU_CAPABILITY.text"/></span><Translate id={`genericLabel.${system.enabledIntelGPU ? 'YES' : 'NO'}.text`}/></li>
                <li><span className="label"><Translate id="genericLabel.FIRMWARE_VERSION.text"/></span>{version}</li>
              </ul>
            </div>
            <Monitor info={monitor}/>
            <div className="system-actions">
              <Button className="square"
                      disabled={hasUserLevel || hasViewerLevel}
                      onClick={() => this.props.callRestartStreamhubModal(true)}>
                <AWIcon name="refresh"/>
                <span className="text"><Translate id="genericLabel.RESTART_STREAMHUB.text"/></span>
              </Button>
              <Button className="square"
                      disabled={hasUserLevel || hasViewerLevel}
                      onClick={() => this.props.callRebootServerModal(true)}>
                <AWIcon name="refresh"/>
                <span className="text"><Translate id="genericLabel.REBOOT_SERVER.text"/></span>
              </Button>
              <Button className="square"
                      disabled={hasUserLevel || hasViewerLevel}
                      onClick={() => this.props.callHaltServerModal(true)}>
                <AWIcon name="power_down"/>
                <span className="text"><Translate id="genericLabel.HALT_SERVER.text"/></span>
              </Button>
              <Button className="square"
                      disabled={hasUserLevel || hasViewerLevel || system.isDocker}
                      onClick={() => this.props.callUpdateFirmwareModal(true)}>
                <AWIcon name="update"/>
                <span className="text"><Translate id="genericLabel.UPDATE_STREAMHUB.text"/></span>
              </Button>
              <Button className="square"
                      disabled={hasUserLevel || hasViewerLevel || system.isDocker}
                      onClick={this.props.callCheckVideoCardFirmware}>
                <AWIcon name="update"/>
                <span className="text long"><Translate id="genericLabel.UPDATE_VIDEO_BOARD_FIRMWARE.text"/></span>
              </Button>
              <Button className="square"
                      disabled={generatingReport}
                      onClick={this.props.callGenerateReport}>
                <AWIcon name="file_download"/>
                <span className="text"><Translate id="genericLabel.GET_REPORT.text"/></span>
              </Button>
            </div>
            <EncodingCapabilities disabled={hasUserLevel || hasViewerLevel}/>
          </div>
          }
          { restartStreamhubModalOpened &&
          <RestartStreamhubModal onCancel={() => this.props.callRestartStreamhubModal(false)}
                                 onConfirm={this.props.callRestartStreamhub}/>
          }
          { rebootServerModalOpened &&
          <RebootServerModal onCancel={() => this.props.callRebootServerModal(false)}
                             onConfirm={this.props.callRebootServer}/>
          }
          { haltServerModalOpened &&
          <HaltServerModal onCancel={() => this.props.callHaltServerModal(false)}
                           onConfirm={this.props.callHaltServer}/>
          }
          { updateFirmwareModalOpened &&
          <UpdateFirmwareModal onCancel={() => this.props.callUpdateFirmwareModal(false)}
                               onInterrupt={this.props.callInterruptFirmwareUpdate}
                               onUpdate={this.props.callUpdateFirmware}
                               onReboot={ this.props.callConfirmFirmwareUpdate }
                               uploading={ uploadingFirmwareFile !== null }
                               uploadProgress={ uploadingProgress }
                               uploadResult={ uploadingFirmwareResult }/>
          }
          { updateVideoCardFirmwareModalOpened &&
          <UpdateVideoCardFirmwareModal onCancel={() => this.props.callUpdateVideoCardFirmwareModal(false)}
                                        onConfirm={this.props.callUpdateVideoCardFirmware}
                                        state={ videoCardFirmwareState }/>
          }
        </div>
    );
  }
}

SystemTools.propTypes = propTypes;

const mapStateToProps = (state) => {
  const {
    restartStreamhubModalOpened,
    rebootServerModalOpened,
    haltServerModalOpened,
    updateFirmwareModalOpened,
    updateVideoCardFirmwareModalOpened,
    videoCardFirmwareState,
    uploadingFirmwareFile,
    uploadingFirmwareResult,
    uploadingProgress,
    generatingReport
  } = state.tools.system;
  return {
    system: state.streamhub.system,
    monitor: state.datastore.system && state.datastore.system.cpuPercent ? state.datastore.system : null,
    restartStreamhubModalOpened,
    rebootServerModalOpened,
    haltServerModalOpened,
    updateFirmwareModalOpened,
    updateVideoCardFirmwareModalOpened,
    videoCardFirmwareState,
    uploadingFirmwareFile,
    uploadingFirmwareResult,
    uploadingProgress,
    generatingReport,
    version: state.streamhub.version,
    hasUserLevel: state.identity.role === USER_ROLE_USER,
    hasViewerLevel: state.identity.role === USER_ROLE_VIEWER,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callRestartStreamhubModal: (open) => dispatch(restartStreamhubModal(open)),
    callRebootServerModal: (open) => dispatch(rebootServerModal(open)),
    callHaltServerModal: (open) => dispatch(haltServerModal(open)),
    callUpdateFirmwareModal: (open) => dispatch(updateFirmwareModal(open)),
    callUpdateVideoCardFirmwareModal: (open) => dispatch(updateVideoCardFirmwareModal(open)),
    callCheckVideoCardFirmware: () => dispatch(checkVideoCardFirmware()),
    callGenerateReport: () => dispatch(generateReport()),
    callRestartStreamhub: () => {
      dispatch(restartStreamhub());
      dispatch(restartStreamhubModal(false));
    },
    callRebootServer: () => {
      dispatch(rebootServer());
      dispatch(rebootServerModal(false));
    },
    callHaltServer: () => {
      dispatch(haltServer());
      dispatch(haltServerModal(false));
    },
    callUpdateFirmware: (file) => {
      dispatch(updateFirmware(file));
    },
    callInterruptFirmwareUpdate: () => {
      dispatch(interruptFirmwareUpdate());
    },
    callConfirmFirmwareUpdate: () => {
      dispatch(confirmFirmwareUpdate());
      dispatch(updateFirmwareModal(false));
    },
    callUpdateVideoCardFirmware: () => {
      dispatch(updateVideoCardFirmware());
      dispatch(updateFirmwareModal(false));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemTools);