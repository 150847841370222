import { randomString } from "../utils/string-utils";
import {
  BANNER_QUEUE_MESSAGE,
  BANNER_DISMISS_MESSAGE,
  BANNER_PRESENT_MESSAGE,
  BANNER_LEVEL_ERROR,
  BANNER_LEVEL_WARNING,
  BANNER_LEVEL_INFO,
  BANNER_MESSAGE_RECONNECTION_ATTEMPT,
  BANNER_MESSAGE_SERVER_ERROR,
  BANNER_MESSAGE_SERVER_SUCCESS,
  BANNER_MESSAGE_REQUEST_AUTHENTIFICATION_KEY,
  BANNER_LEVEL_SUCCESS,
  BANNER_OVERRIDE_MESSAGE,
  BANNER_MESSAGE_CONFIRM_GET_LOGS,
  BANNER_MESSAGE_CONFIRM_GET_REPORT,
  BANNER_MESSAGE_CONFIRM_EXPORT_CONFIG,
  BANNER_MESSAGE_CONFIRM_EXPORT_STREAMHUB_PROFILE,
  BANNER_MESSAGE_TIMEOUT_WARNING,
  BANNER_MESSAGE_UPLOAD_IN_PROGRESS,
  TIMEOUT_RESET
} from '../constants';
import Logger from '../utils/logger';

export const appendBannerMessage = (bannerMessageId, options = null) => (dispatch, gs) => {
  switch(bannerMessageId){
    case BANNER_MESSAGE_RECONNECTION_ATTEMPT:
      return dispatch(appendBanner({
        id: bannerMessageId,
        priority: 10,
        level: BANNER_LEVEL_INFO,
        message: 'genericLabel.RECONNECTION_ATTEMPT.text',
        icon: {
          name: 'refresh',
          rotating: true
        },
        dismissible: false
      }));
    case BANNER_MESSAGE_TIMEOUT_WARNING:
      return dispatch(appendBanner({
        id: bannerMessageId,
        priority: 9,
        level: BANNER_LEVEL_WARNING,
        title: 'genericLabel.TIMEOUT_WARNING_TITLE.text',
        message: 'genericLabel.TIMEOUT_WARNING.text',
        icon: {
          name: 'update'
        },
        dismissible: false,
        action: {
          title: 'genericLabel.TIMEOUT_WARNING_RESET.text',
          onClick: () => {
            dispatch(timeoutReset());
            dispatch(dismissBanner(bannerMessageId));
          }
        }
      }));
    case BANNER_MESSAGE_REQUEST_AUTHENTIFICATION_KEY:
      return dispatch(appendBanner({
        id: bannerMessageId,
        priority: 1,
        level: BANNER_LEVEL_INFO,
        title: 'genericLabel.STREAMHUB_LICENSE.text',
        message: 'genericLabel.STREAMHUB_LICENSE_REQUEST_AUTHENTIFICATION_KEY.text',
        icon: {
          name: 'license'
        },
        dismissible: true
      }));
    case BANNER_MESSAGE_SERVER_ERROR:
      if(!options.key) throw Error("'BANNER_MESSAGE_SERVER_ERROR' should have a specified 'key' property as option");
      return dispatch(appendBanner({
        id: `${bannerMessageId}-${options.key}`,
        priority: 10,
        level: BANNER_LEVEL_ERROR,
        message: `serverError.${options.key}.text`,
        icon: {
          name: 'error'
        },
        dismissible: true,
        autoDismissAfter: 3000
      }));
    case BANNER_MESSAGE_SERVER_SUCCESS:
      if(!options.key) throw Error("'BANNER_MESSAGE_SERVER_SUCCESS' should have a specified 'key' property as option");
      return dispatch(appendBanner({
        id: `${bannerMessageId}-${options.key}`,
        priority: 1,
        level: BANNER_LEVEL_SUCCESS,
        message: `serverSuccess.${options.key}.text`,
        icon: {
          name: 'check_circle'
        },
        dismissible: true,
        autoDismissAfter: 3000
      }));
    case BANNER_MESSAGE_CONFIRM_GET_LOGS:
      if(!options.fileName) throw Error("'BANNER_MESSAGE_CONFIRM_GET_LOGS' should have a specified 'fileName' property as option");
      return dispatch(appendBanner({
        id: bannerMessageId,
        priority: 5,
        level: BANNER_LEVEL_INFO,
        title: 'genericLabel.GET_LOGS.text',
        message: `genericLabel.LOGS_AVAILABLE.text`,
        icon: {
          name: 'download'
        },
        dismissible: true,
        action: {
          title: 'genericLabel.DOWNLOAD.text',
          onClick: () => {
            const downloadLink = `${window.location.protocol}//${window.location.host}/getLog/${options.fileName}`;
            Logger.info('Download link : ', downloadLink);
            window.open(downloadLink, '_blank');
            dispatch(dismissBanner(bannerMessageId));
          }
        }
      }));
    case BANNER_MESSAGE_CONFIRM_GET_REPORT:
      if(!options.fileName) throw Error("'BANNER_MESSAGE_CONFIRM_GET_REPORT' should have a specified 'fileName' property as option");
      return dispatch(appendBanner({
        id: bannerMessageId,
        priority: 5,
        level: BANNER_LEVEL_INFO,
        title: 'genericLabel.GET_REPORT.text',
        message: `genericLabel.REPORT_AVAILABLE.text`,
        icon: {
          name: 'file_download'
        },
        dismissible: true,
        action: {
          title: 'genericLabel.DOWNLOAD.text',
          onClick: () => {
            const downloadLink = `${window.location.protocol}//${window.location.host}/getReport/${options.fileName}`;
            Logger.info('Download link : ', downloadLink);
            window.open(downloadLink, '_blank');
            dispatch(dismissBanner(bannerMessageId));
          }
        }
      }));
    case BANNER_MESSAGE_CONFIRM_EXPORT_CONFIG:
      if(!options.fileName) throw Error("'BANNER_MESSAGE_CONFIRM_EXPORT_CONFIG' should have a specified 'fileName' property as option");
      return dispatch(appendBanner({
        id: bannerMessageId,
        priority: 5,
        level: BANNER_LEVEL_INFO,
        title: 'genericLabel.EXPORT_CONFIG.text',
        message: 'genericLabel.CONFIG_AVAILABLE.text',
        icon: {
          name: 'file_export'
        },
        dismissible: true,
        action: {
          title: 'genericLabel.DOWNLOAD.text',
          onClick: () => {
            const downloadLink = `${window.location.protocol}//${window.location.host}/exportConfig/${options.fileName}`;
            Logger.info('Download link : ', downloadLink);
            window.open(downloadLink, '_blank');
            dispatch(dismissBanner(bannerMessageId));
          }
        }
      }));
    case BANNER_MESSAGE_CONFIRM_EXPORT_STREAMHUB_PROFILE:
      if(!options.fileName) throw Error("'BANNER_MESSAGE_CONFIRM_EXPORT_STREAMHUB_PROFILE' should have a specified 'fileName' property as option");
      return dispatch(appendBanner({
        id: bannerMessageId,
        priority: 5,
        level: BANNER_LEVEL_INFO,
        title: 'genericLabel.EXPORT_PROFILE.text',
        message: 'genericLabel.PROFILE_AVAILABLE.text',
        icon: {
          name: 'connect'
        },
        dismissible: true,
        action: {
          title: 'genericLabel.DOWNLOAD.text',
          onClick: () => {
            const downloadLink = `${window.location.protocol}//${window.location.host}/getStreamHubProfile/${options.fileName}`;
            Logger.info('Download link : ', downloadLink);
            window.open(downloadLink, '_blank');
            dispatch(dismissBanner(bannerMessageId));
          }
        }
      }));
    case BANNER_MESSAGE_UPLOAD_IN_PROGRESS:
      return dispatch(appendBanner({
        id: bannerMessageId,
        priority: 0,
        level: BANNER_LEVEL_INFO,
        message: 'genericLabel.UPLOAD_IN_PROGRESS.text',
        icon: {
          name: 'upload'
        },
        dismissible: true
      }));
    default:
      break;
  }
};

export const clearBannerMessage = (bannerMessageId) => {
  return dismissBanner(bannerMessageId);
};

export const appendBanner = ({ id, priority, level, title, message, icon, dismissible, autoDismissAfter, action }) => {
  const bannerAction = {
    type: BANNER_QUEUE_MESSAGE,
    id : id ? id : randomString(6),
    priority,
    level,
    message,
    title,
    icon,
    dismissible
  };
  if(action !== null){
    bannerAction.action = action;
  }
  if(autoDismissAfter && autoDismissAfter > 0){
    bannerAction.autoDismissAfter = autoDismissAfter;
  }
  return bannerAction;
};

export const presentBanner = (id) => {
  return {
    type: BANNER_PRESENT_MESSAGE,
    id
  }
};

export const overrideBanner = (id) => {
  return {
    type: BANNER_OVERRIDE_MESSAGE,
    id
  }
};

export const dismissBanner = (id) => {
  return {
    type: BANNER_DISMISS_MESSAGE,
    id
  }
};

export const timeoutReset = () => {
  return {
    type: TIMEOUT_RESET,
  }
};