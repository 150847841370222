import PropTypes from "prop-types";
import React from "react";
import {Translate} from "react-localize-redux";
import {Button} from "reactstrap";
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";

const propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

const RebootServerModal = (props) => {
  const { onCancel, onConfirm } = props;
  return (
    <AWModal confirm={true}
           icon="refresh"
           open={true}
             onClose={true}
           title={<Translate id="genericLabel.REBOOT_SERVER.text"/>}>
      <div className="confirm-text">
        <Translate id="genericLabel.CONFIRM_REBOOT_SERVER.text"/>
        <div className="indicator"><Translate id="genericLabel.CONNECTED_FIELD_UNITS_WILL_BE_DISCONNECTED.text"/></div>
      </div>
      <div className="buttons">
        <Button className="btn-cancel"
                size="lg"
                onClick={onCancel}>
          <Translate id="genericLabel.NO.text"/>
        </Button>
        <Button color="primary"
                size="lg"
                onClick={onConfirm}>
          <Translate id="genericLabel.YES.text"/>
        </Button>
      </div>
    </AWModal>
  );
};

RebootServerModal.propTypes = propTypes;

export default RebootServerModal;