import React, {Component} from 'react';
import {Translate} from "react-localize-redux";
import CopyToClipboardButton from '../copy-to-clipboard-button';

import {inputIpProfilePropTypes} from "../../../utils/models-prop-types";

const propTypes = {
  ipProfile: inputIpProfilePropTypes.isRequired
};

class IPProfileMetadata extends Component {

  render(){
    const { ipProfile } = this.props;
    return (
        <div className="ip-profile-metadata">
          { ipProfile.uri &&
          <div className="metadata-section">
            <span className="metadata-type uri"><Translate id="genericLabel.URI.text"/> :</span>
            <span className="uri text-clip" title={ipProfile.uri}>{ipProfile.uri}</span>
            <span className="button-copy"><CopyToClipboardButton ipProfile={ipProfile} uri={ipProfile.uri}/></span>
          </div>
          }
          { ipProfile.uris && ipProfile.uris.map((uri, index) => (
              <div key={uri}
                   className="metadata-section">
                <span className="metadata-type uri"><Translate id="genericLabel.URI.text"/>{` ${index + 1} :`}</span>
                <span className="uri text-clip" title={uri}>{uri}</span>
                <span className="button-copy"><CopyToClipboardButton ipProfile={ipProfile} uri={uri}/></span>
              </div>
          ))}
          { ipProfile.localUris && ipProfile.localUris.map(localUri => (
              <div key={localUri}
                   className="metadata-section">
                <span className="metadata-type local-uri"><Translate id="genericLabel.LOCAL_URI.text"/> :</span>
                <span className="local-uri text-clip" title={localUri}>{localUri}</span>
                <span className="button-copy"><CopyToClipboardButton ipProfile={ipProfile} uri={localUri}/></span>
              </div>
          ))}
          { ipProfile.publicUri &&
          <div className="metadata-section">
            <span className="metadata-type public-uri"><Translate id="genericLabel.PUBLIC_URI.text"/> :</span>
            <span className="public-uri text-clip" title={ipProfile.publicUri}>{ipProfile.publicUri}</span>
            <span className="button-copy"><CopyToClipboardButton ipProfile={ipProfile} uri={ipProfile.publicUri}/></span>
          </div>
          }
          { ipProfile.port &&
          <div className="metadata-section">
            <span className="metadata-type port"><Translate id="genericLabel.PORT.text"/> :</span>
            <span className="port text-clip" title={ipProfile.port}>{ipProfile.port}</span>
          </div>
          }
          { ipProfile.encoderVideoType && ipProfile.videoBitrate &&
          <div className="metadata-section">
            <span className="metadata-type port"><Translate id="genericLabel.VIDEO.text"/> :</span>
            <span className="port text-clip" title={`${ipProfile.encoderVideoType} ${ipProfile.videoBitrate}Kbps`}>{`${ipProfile.encoderVideoType} ${ipProfile.videoBitrate}Kbps`}</span>
          </div>
          }
          { ipProfile.encoderAudioType && ipProfile.audioBitrate &&
          <div className="metadata-section">
            <span className="metadata-type port"><Translate id="genericLabel.AUDIO.text"/> :</span>
            <span className="port text-clip" title={`${ipProfile.encoderAudioType} ${ipProfile.audioBitrate}Kbps`}>{`${ipProfile.encoderAudioType} ${ipProfile.audioBitrate}Kbps`}</span>
          </div>
          }
          { ipProfile.sdiIdx &&
          <div className="metadata-section">
            <span className="metadata-type sdi-index"><Translate id="genericLabel.SDI_IDX.text"/> :</span>
            <span className="sdi-index text-clip" title={ipProfile.sdiIdx}>{ipProfile.sdiIdx}</span>
          </div>
          }
          { ipProfile.sdiNbChannels &&
          <div className="metadata-section">
            <span className="metadata-type sdi-channels"><Translate id="genericLabel.SDI_NB_CHANNELS.text"/> :</span>
            <span className="sdi-channels text-clip" title={ipProfile.sdiNbChannels}>{ipProfile.sdiNbChannels}</span>
          </div>
          }
          <div className="metadata-section-empty" />
        </div>
    )
  }
}

IPProfileMetadata.propTypes = propTypes;

export default IPProfileMetadata;