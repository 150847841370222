import PropTypes from "prop-types";
import React from 'react';
import {Translate} from "react-localize-redux";
import {FormFeedback, Label, Input, FormGroup, Button, Alert} from "reactstrap";
import {isEmptyString} from "../../../../utils/string-utils";
import {Form, Formik} from "formik";

const propTypes = {
  config: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const formNdi = (props) => {
  const { config } = props;

  const handleSubmit = (values, { resetForm }) => {
    if(!values.enable && isEmptyString(values.netmask)){
      values.netmask = props.config.multicast.netmask;
    }
    if(!values.enable && isEmptyString(values.netprefix)){
      values.netprefix = props.config.multicast.netprefix;
    }
    props.onSubmit(values);
    resetForm(values);
  };

  const handleValidation = (values) => {
    const errors = {};
    const ipRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))$/;

    if(values.enable){
      // netprefix
      let netprefixRegex = /^2((2[4-9]|[3-4][0-9]|5[0-5])\.)(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){2}(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))$/
      if(isEmptyString(values.netprefix)) {
        errors.netprefix = 'genericLabel.REQUIRED_FIELD.text';
      } else if (!netprefixRegex.test(values.netprefix)) {
        errors.netprefix = 'genericLabel.INVALID_FORMAT.text';
      }

      // netmask
      if(isEmptyString(values.netmask)) {
        errors.netmask = 'genericLabel.REQUIRED_FIELD.text';
      }
      else if (!ipRegex.test(values.netmask)) {
        errors.netmask = 'genericLabel.INVALID_FORMAT.text';
      }
    }
    // ndiMessagingServers
    if (!isEmptyString(values.ndiMessagingServers) && !ipRegex.test(values.ndiMessagingServers)) {
      errors.ndiMessagingServers = 'genericLabel.INVALID_FORMAT.text';
    }
    return errors;
  };

  const willNeedRestart = (values) => {
    const { enable, netmask, netprefix } = props.config.multicast;
    if(values.enable !== enable
      || values.netmask !== netmask
      || values.netprefix !== netprefix){
      return true;
    }
    return false;
  }

  return (
    <Formik initialValues={{
              enable: config.multicast.enable,
              netmask: config.multicast.netmask,
              netprefix: config.multicast.netprefix,
              ndiMessagingServers: config.ndiMessagingServers
            }}
            validateOnBlur={false}
            validateOnChange={true}
            validate={ handleValidation }
            onSubmit={ handleSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
        <Form onSubmit={handleSubmit}>
          { willNeedRestart(values)  &&
          <Alert color="warning">
            <Translate id="genericLabel.NDI_OUTPUTS_WILL_BE_DISCONNECTED.text"/>
          </Alert>
          }
          { values.ndiMessagingServers && config.ndiMessagingServers !== values.ndiMessagingServers &&
          <Alert color="warning">
            <Translate id="genericLabel.RESTART_STREAMHUB_TO_TAKE_CHANGES_INTO_ACCOUNT.text"/>
          </Alert>
          }
          <FormGroup check>
            <Label check>
              <Input type="checkbox"
                     name="enable"
                     onChange={handleChange}
                     checked={values.enable}/>{' '}
              <Translate id="genericLabel.ENABLE_NDI_MULTICAST.text"/>
              <div className="indicator">
                <Translate id="genericLabel.ENABLE_NDI_MULTICAST_HELP.text"/>
              </div>
            </Label>
          </FormGroup>
          <FormGroup>
            <Label for="netprefix">
              <Translate id="genericLabel.NDI_MULTICAST_NETPREFIX.text"/>
            </Label>
            <Input type="string"
                   name="netprefix"
                   id="netprefix"
                   invalid={ errors.netprefix !== undefined }
                   disabled={!values.enable}
                   value={values.netprefix}
                   onBlur={handleBlur}
                   onChange={handleChange}/>
            <FormFeedback>
              <Translate id={errors.netprefix}/>
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="netmask">
              <Translate id="genericLabel.NDI_MULTICAST_NETMASK.text"/>
            </Label>
            <Input type="string"
                   name="netmask"
                   id="netmask"
                   invalid={ errors.netmask !== undefined }
                   disabled={!values.enable}
                   value={values.netmask}
                   onBlur={handleBlur}
                   onChange={handleChange}/>
            <FormFeedback>
              <Translate id={errors.netmask}/>
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="ndiMessagingServers">
              <Translate id="genericLabel.NDI_MESSAGING_SERVER.text"/>
            </Label>
            <Input type="string"
                   name="ndiMessagingServers"
                   id="ndiMessagingServers"
                   invalid={ errors.ndiMessagingServers !== undefined }
                   value={values.ndiMessagingServers}
                   onBlur={handleBlur}
                   onChange={handleChange}/>
            <FormFeedback>
              <Translate id={errors.ndiMessagingServers}/>
            </FormFeedback>
          </FormGroup>
          <FormGroup className="buttons">
            <Button color="primary"
                    disabled={isSubmitting || !dirty}
                    type="submit">
              <Translate id="genericLabel.SAVE.text"/>
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

formNdi.propTypes = propTypes;

export default formNdi;