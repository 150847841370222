import PropTypes from "prop-types";
import React, {Component} from "react";
import {Translate} from "react-localize-redux";
import Slider from "rc-slider";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {deviceLiveInfoPropTypes} from "../../../../../utils/models-prop-types";
import {Button} from "reactstrap";
import {formatMilliToSeconds, formatBitrate} from "../../../../../utils/global-utils";
import { VIDEO_BITRATE_MODE_CBR } from "../../../../../constants";

const propTypes = {
  sliderLatencyCapability: PropTypes.bool.isRequired,
  liveInfo: deviceLiveInfoPropTypes.isRequired,
  onLatencyChange: PropTypes.func.isRequired,
  onCappedBitrateChange: PropTypes.func.isRequired
};

const STEP = 100;

class LatencyBitrateControls extends Component {

  constructor(props){
    super(props);

    this.handleLatencySliderChange = this.handleLatencySliderChange.bind(this);
    this.handleBitrateSliderChange = this.handleBitrateSliderChange.bind(this);

    this.state = {
      delay: props.liveInfo.delay,
      videoCappedBitrate: props.liveInfo.videoCappedBitrate
    };
  }

  componentWillUnmount(){
    this.clearLatencyTimer();
    this.clearBitrateTimer();
  }

  componentDidUpdate(prevProps){
    if (this.props.liveInfo.delay !== prevProps.liveInfo.delay) {
      this.setState({delay: this.props.liveInfo.delay})
    }

    if (this.props.liveInfo.videoCappedBitrate !== prevProps.liveInfo.videoCappedBitrate) {
      this.setState({videoCappedBitrate: this.props.liveInfo.videoCappedBitrate})
    }
  }

  handleLatencySliderChange(value){
    this.setState({
      delay: value
    });

    this.clearLatencyTimer();
    this.latencyTimer = setTimeout(() => {
      this.props.onLatencyChange(value);
    }, 1000);
  }

  handleBitrateSliderChange(value){
    this.setState({
      videoCappedBitrate: value
    });

    this.clearBitrateTimer();
    this.bitrateTimer = setTimeout(() => {
      this.props.onCappedBitrateChange(value);
    }, 1000);
  }

  clearLatencyTimer(){
    if(this.latencyTimer){
      clearTimeout(this.latencyTimer);
      this.latencyTimer = null;
    }
  }

  clearBitrateTimer(){
    if(this.bitrateTimer){
      clearTimeout(this.bitrateTimer);
      this.bitrateTimer = null;
    }
  }

  render() {
    const { liveMinLatency, liveMaxLatency, videoMinBitrate, videoMaxBitrate, videoBitrateMode } = this.props.liveInfo;

    // We handle dynamic latency only when
    const showLatencyControls = this.props.sliderLatencyCapability && (liveMinLatency !== null && liveMaxLatency !== null);
    
    // If CBR, we don't handle videoCappedBitrate
    const showBitrateControls = videoBitrateMode !== VIDEO_BITRATE_MODE_CBR;
    
    if(!showLatencyControls && !showBitrateControls){
      return null;
    }

    const { delay, videoCappedBitrate } = this.state;
    return (
      <div className="settings-controls">
        <div className="title">
          <label className="text-secondary"><Translate id="genericLabel.SETTINGS.text"/></label>
        </div>
        <div className="controls rounded">
          {showLatencyControls && <div className="latency-controls">
            <label className="text-secondary">
              <Translate id="genericLabel.LATENCY.text"/>
            </label>
            <div className="manual">
              <Button className="basic"
                      disabled={ delay - STEP < liveMinLatency }
                      onClick={() => this.handleLatencySliderChange(delay - STEP)}>
                <AWIcon name="remove_square"/>
              </Button>
              <span className="value">{formatMilliToSeconds(delay)}</span>
              <Button className="basic"
                      disabled={ delay + STEP > liveMaxLatency }
                      onClick={() => this.handleLatencySliderChange(delay + STEP)}>
                <AWIcon name="add_square"/>
              </Button>
            </div>
            <Slider className="aw-theme"
                    horizontal
                    min={liveMinLatency}
                    max={liveMaxLatency}
                    step={STEP}
                    onChange={this.handleLatencySliderChange}
                    value={delay}/>
            <span className="min-value">
              { formatMilliToSeconds(liveMinLatency) }
            </span>
            <span className="max-value">
              { formatMilliToSeconds(liveMaxLatency) }
            </span>
          </div>}
          {showBitrateControls && <div className="bitrate-controls">
            <label className="text-secondary">
              <Translate id="genericLabel.VIDEO_CAPPED_BITRATE.text"/>
            </label>
            <div className="manual">
              <Button className="basic"
                      disabled={ videoCappedBitrate - STEP < videoMinBitrate }
                      onClick={() => this.handleBitrateSliderChange(videoCappedBitrate - STEP)}>
                <AWIcon name="remove_square"/>
              </Button>
              <span className="value">{formatBitrate(videoCappedBitrate * 1000)}</span>
              <Button className="basic"
                      disabled={ videoCappedBitrate + STEP > videoMaxBitrate }
                      onClick={() => this.handleBitrateSliderChange(videoCappedBitrate + STEP)}>
                <AWIcon name="add_square"/>
              </Button>
            </div>
            <Slider className="aw-theme"
                    horizontal
                    min={videoMinBitrate}
                    max={videoMaxBitrate}
                    step={STEP}
                    onChange={this.handleBitrateSliderChange}
                    value={videoCappedBitrate}/>
            <span className="min-value">
              { formatBitrate(videoMinBitrate * 1000) }
            </span>
            <span className="max-value">
              { formatBitrate(videoMaxBitrate * 1000) }
            </span>
          </div>}
        </div>
      </div>
    );
  }
}

LatencyBitrateControls.propTypes = propTypes;

export default LatencyBitrateControls;