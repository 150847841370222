import PropTypes from "prop-types";
import React from 'react';
import {Translate} from "react-localize-redux";
import {Label, Input, FormGroup, Form, Button} from "reactstrap";
import {Formik} from "formik";

const encoderSectionPropTypes = PropTypes.shape({
  enable: PropTypes.bool.isRequired,
  deleteFileAfterTranscode: PropTypes.bool.isRequired,
  outputFormat: PropTypes.string.isRequired,
  outputFrameRate: PropTypes.string.isRequired,
  outputResolution: PropTypes.string.isRequired,
  outputScanMode: PropTypes.string
});

const capabilitiesPropTypes = PropTypes.shape({
  resolutions: PropTypes.arrayOf(PropTypes.string),
  scanModes: PropTypes.arrayOf(PropTypes.string),
  frameRates: PropTypes.object,
  formats: PropTypes.arrayOf(PropTypes.string)
});

const propTypes = {
  config: encoderSectionPropTypes.isRequired,
  transcoderCapabilities: capabilitiesPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const MediaSettingsFormTranscoder = (props) => {
  const { config, transcoderCapabilities } = props;

  const handleSubmit = (values, { resetForm }) => {
    props.onSubmit(values);
    resetForm(values);
  };

  const {
    resolutions,
    // scanModes,
    frameRates,
    formats
  } = transcoderCapabilities;
  return (
    <Formik initialValues={{
              enable: config.enable,
              deleteFileAfterTranscode: config.deleteFileAfterTranscode,
              outputFormat: config.outputFormat,
              outputFrameRate: config.outputFrameRate,
              outputResolution: config.outputResolution,
              outputScanMode: config.outputScanMode
            }}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={ handleSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
          /* and other goodies */
        }) => (
        <Form onSubmit={handleSubmit}>
          <FormGroup check>
            <Label check>
              <Input type="checkbox"
                     name="enable"
                     onChange={handleChange}
                     checked={values.enable}/>{' '}
              <Translate id="genericLabel.AUTOMATICALLY_TRANSCODE_AFTER_FORWARD.text"/>
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="checkbox"
                     name="deleteFileAfterTranscode"
                     id="deleteFileAfterTranscode"
                     disabled={!values.enable}
                     onChange={handleChange}
                     checked={values.deleteFileAfterTranscode}/>{' '}
              <Translate id="genericLabel.AUTOMATICALLY_DELETE_AFTER_TRANSCODE.text"/>
            </Label>
          </FormGroup>
          <FormGroup>
            <Label for="outputResolution">
              <Translate id="genericLabel.OUTPUT_RESOLUTION.text"/>
            </Label>
            <Input type="select"
                   name="outputResolution"
                   id="outputResolution"
                   disabled={!values.enable}
                   value={values.outputResolution}
                   onBlur={handleBlur}
                   onChange={ (e) => {
                     handleChange(e);

                     // Setting automatically framerate depending on chosen resolution
                     const newResolution = e.target.value;
                     const matchingFrameRates = frameRates[newResolution];
                     let outputFrameRate = null;
                     if(matchingFrameRates.length === 1){
                       outputFrameRate = matchingFrameRates[0];
                     }
                     else {
                       outputFrameRate = matchingFrameRates.find(frameRate => frameRate === values.outputFrameRate || parseFloat(frameRate) === parseFloat(values.outputFrameRate) * 2); // parseFloat find the frameRate in string (stripping 'fps')
                     }
                     if(outputFrameRate){
                       setFieldValue('outputFrameRate', outputFrameRate);
                     }
                   }}>
              { resolutions.map(resolution => (
                <option key={resolution} value={resolution}>{ resolution }</option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="outputFrameRate">
              <Translate id="genericLabel.OUTPUT_FRM_RATE.text"/>
            </Label>
            <Input type="select"
                   name="outputFrameRate"
                   id="outputFrameRate"
                   disabled={!values.enable}
                   value={values.outputFrameRate}
                   onBlur={handleBlur}
                   onChange={handleChange}>
              { frameRates[values.outputResolution] && frameRates[values.outputResolution].map(frameRate => (
                <option key={frameRate} value={frameRate}>{ frameRate }</option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="outputFormat">
              <Translate id="genericLabel.OUTPUT_FORMAT.text"/>
            </Label>
            <Input type="select"
                   name="outputFormat"
                   id="outputFormat"
                   disabled={!values.enable}
                   value={values.outputFormat}
                   onBlur={handleBlur}
                   onChange={handleChange}>
              { formats.map(format => (
                <option key={format} value={format}>{ format }</option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup className="buttons">
            <Button color="primary"
                    disabled={isSubmitting || !dirty}
                    type="submit">
              <Translate id="genericLabel.SAVE.text"/>
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

MediaSettingsFormTranscoder.propTypes = propTypes;

export default MediaSettingsFormTranscoder;