import PropTypes from 'prop-types';
import React, {Component} from 'react';
import { Badge } from 'reactstrap';
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import {Translate} from "react-localize-redux";

const propTypes = {
  info: PropTypes.shape({
    cpuPercent: PropTypes.number.isRequired,
    memoryPercent: PropTypes.number.isRequired,
    storagePercent: PropTypes.number
  }),
  transcoderQueueSize: PropTypes.number.isRequired,
  activeEncodersCount: PropTypes.number.isRequired,
  enableEncodersCount: PropTypes.number.isRequired,
};

const warningValue = 70;
const alertValue = 90;

class Monitor extends Component {

  render(){
    const { info, transcoderQueueSize, activeEncodersCount, enableEncodersCount } = this.props;
    if(!info){
      return null;
    }

    const encoderClass = activeEncodersCount > 0 ? 'aw-activated' : null;
    const cpuClass = info.cpuPercent > alertValue ? 'aw-alert' : (info.cpuPercent > warningValue ? 'aw-warning' : null);
    const memoryClass = info.memoryPercent > alertValue ? 'aw-alert' : (info.memoryPercent > warningValue ? 'aw-warning' : null);
    const storageClass = info.storagePercent > alertValue ? 'aw-alert' : (info.storagePercent > warningValue ? 'aw-warning' : null);
    return (
        <div className="monitor">
          { transcoderQueueSize > 0 &&
            <Badge className="aw-activated" ><Translate id='genericLabel.FILE_TRANSCODING.text'/> : {transcoderQueueSize}</Badge>
          }
          { enableEncodersCount > 0 &&
            <Badge className={ encoderClass } ><Translate id='genericLabel.LIVE_TRANSCODING.text'/> : {activeEncodersCount}/{enableEncodersCount}</Badge>
          }
          <Badge className={ cpuClass } ><AWIcon id="monitor-cpu" name="hw_cpu_usage"/><Translate id='genericLabel.CPU.text'/> : {info.cpuPercent}%</Badge>
          <Badge className={ memoryClass }><AWIcon id="monitor-memory" name="hw_memory_usage"/><Translate id='genericLabel.MEMORY.text'/> : {info.memoryPercent}%</Badge>
          <Badge className={ storageClass }><AWIcon id="monitor-storage" name="hw_disk_usage"/><Translate id='genericLabel.DISK.text'/> : {info.storagePercent}%</Badge>
        </div>
    )
  }
}

Monitor.propTypes = propTypes;

export default Monitor;