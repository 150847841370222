import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Button } from 'reactstrap';

import {
  FILE_LAYOUT_ICON, FILE_LAYOUT_INLINE
} from '../../../constants';
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

const propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.oneOf([FILE_LAYOUT_ICON, FILE_LAYOUT_INLINE]).isRequired
};

const FileLayoutSelector = (props) => {
  const { onChange, selected } = props;
  return (
    <Fragment>
      <Button active={ selected === FILE_LAYOUT_ICON}
              onClick={() => onChange(FILE_LAYOUT_ICON)}
              className='basic icon'
              size="sm"
              color='primary' >
        <AWIcon name="layout_grid"/>
      </Button>
      <Button active={ selected === FILE_LAYOUT_INLINE}
              onClick={() => onChange(FILE_LAYOUT_INLINE)}
              className='basic icon'
              size="sm"
              color='primary' >
        <AWIcon name="layout_inline"/>
      </Button>
    </Fragment>
  )
};

FileLayoutSelector.propTypes = propTypes;

export default FileLayoutSelector;