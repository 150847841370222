import PropTypes from 'prop-types';
import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Alert} from "reactstrap";
import {Translate} from "react-localize-redux";

import {INPUT_TYPE_IP, HLS, RTMP, RTSP, SRT, TS, SDI, NDI, WEBRTC} from "../../../../constants";
import HLSForm from "./hls-form";
import RTMPForm from "./rtmp-form";
import RTSPForm from "./rtsp-form";
import SRTForm from "./srt-form";
import TSForm from "./ts-form";
import SDIForm from "./sdi-form";
import NDIForm from "./ndi-form";
import LiveGuestForm from './liveguest-form';
import { getAvailableLiveGuestResolutions } from '../../../../misc/capabilities.selectors';
import { inputIpProfilePropTypes } from '../../../../utils/models-prop-types';
import AWIcon from '@aviwest/ui-kit/dist/js/components/icon';
import { inputIcon } from '../../../../utils/global-utils';

const propTypes = {
  editingProfileId: PropTypes.string,
  lgStep: PropTypes.number,
  protocolSet: PropTypes.string,
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  forbiddenUDPPorts: PropTypes.arrayOf(PropTypes.number).isRequired,
  forbiddenUris: PropTypes.arrayOf(PropTypes.string).isRequired,
  config: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  publicIp: PropTypes.string,
  localIps: PropTypes.arrayOf(PropTypes.string),
  inputIPProfiles: PropTypes.arrayOf(inputIpProfilePropTypes).isRequired,
  tmpProfilesLoading: PropTypes.object,
  liveGuestResolutions: PropTypes.arrayOf(PropTypes.object).isRequired,
  backToDashboard: PropTypes.bool
};

class InputIPProfileForms extends Component {

  constructor(props){
    super(props);

    this.state = {
      type: props.config ? props.config.type : (props.protocolSet ?? props.protocols[0])
    };
  }

  render() {
    const { type } = this.state;
    const { config, forbiddenNames, forbiddenUris, forbiddenUDPPorts, localIps, onCancel, onSubmit, onDelete, publicIp, inputIPProfiles,
      tmpProfilesLoading, liveGuestResolutions, lgStep, backToDashboard } = this.props;

    const alert = config && config.enable === true ?
      <Alert color="info">
        <Translate id="genericLabel.IP_INPUT_RUNNING_PROFILE.text"/>
      </Alert> : null

    const protocol =
      <div className="protocol">
        <AWIcon name={inputIcon(INPUT_TYPE_IP, type)} />
      </div>
    return (
        <>
          { type === HLS &&
          <HLSForm config={config}
                   forbiddenNames={forbiddenNames}
                   onCancel={onCancel}
                   onSubmit={onSubmit}
                   onDelete={onDelete}
                   alert={alert}
                   backToDashboard={backToDashboard}
                   protocol={protocol}/>
          }
          { type === RTMP &&
          <RTMPForm config={config}
                    forbiddenNames={forbiddenNames}
                    forbiddenUris={forbiddenUris}
                    localIps={localIps}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    publicIp={publicIp}
                    onDelete={onDelete}
                    alert={alert}
                    backToDashboard={backToDashboard}
                    protocol={protocol}/>
          }
          { type === RTSP &&
          <RTSPForm config={config}
                    forbiddenNames={forbiddenNames}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    alert={alert}
                    backToDashboard={backToDashboard}
                    protocol={protocol}/>
          }
          { type === SRT &&
          <SRTForm config={config}
                   forbiddenNames={forbiddenNames}
                   forbiddenUris={forbiddenUris}
                   localIps={localIps}
                   onCancel={onCancel}
                   onSubmit={onSubmit}
                   publicIp={publicIp}
                   onDelete={onDelete}
                   alert={alert}
                   backToDashboard={backToDashboard}
                   protocol={protocol}/>
          }
          { type === TS &&
          <TSForm config={config}
                  forbiddenNames={forbiddenNames}
                  forbiddenUDPPorts={forbiddenUDPPorts}
                  onCancel={onCancel}
                  onSubmit={onSubmit}
                  onDelete={onDelete}
                  alert={alert}
                  backToDashboard={backToDashboard}
                  protocol={protocol}/>
          }
          { type === SDI &&
          <SDIForm config={config}
                   forbiddenNames={forbiddenNames}
                   onCancel={onCancel}
                   onSubmit={onSubmit}
                   alert={alert}
                   backToDashboard={backToDashboard}
                   protocol={protocol}/>
          }
          { type === NDI &&
          <NDIForm config={config}
                   forbiddenNames={forbiddenNames}
                   onCancel={onCancel}
                   onSubmit={onSubmit}
                   onDelete={onDelete}
                   alert={alert}
                   backToDashboard={backToDashboard}
                   protocol={protocol}/>
          }
          { type === WEBRTC &&
          <LiveGuestForm  config={config}
                          forbiddenNames={forbiddenNames}
                          step={lgStep}
                          tmpProfilesLoading={tmpProfilesLoading}
                          liveGuestResolutions={liveGuestResolutions}
                          onCancel={onCancel}
                          onSubmit={onSubmit}
                          onDelete={onDelete}
                          alert={alert}
                          protocol={protocol}
                          backToDashboard={backToDashboard}
                          inputIPProfiles={inputIPProfiles} />
          }
        </>
    );
  }
}

InputIPProfileForms.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const config = state.config.inputProtocol ? state.config.inputProtocol[ownProps.editingProfileId] : null;
  const publicIp = state.streamhub.publicIp;
  const localIps = state.streamhub.localIps;
  const forbiddenNames = [];
  const forbiddenUris = [];
  const forbiddenUDPPorts = state.streamhub.udpUsedPort.atpGeneralBasePorts.concat(
      state.streamhub.udpUsedPort.inputProtocolPorts,
      state.streamhub.udpUsedPort.intercomPorts,
      state.streamhub.udpUsedPort.channelPorts).filter(port => config ? port !== config.port : true);
  const inputIPProfiles = state.streamhub.inputIPProfiles;
  inputIPProfiles.forEach(ipProfile => {
    if(!config || ipProfile.id !== config.id){
      forbiddenNames.push(ipProfile.name);
      if(ipProfile.uri){
        forbiddenUris.push(ipProfile.uri);
      }
      if(ipProfile.publicUri){
        forbiddenUris.push(ipProfile.publicUri);
      }
      if(ipProfile.localUris){
        ipProfile.localUris.forEach(localUri => forbiddenUris.push(localUri));
      }
      if(ipProfile.uris){
        ipProfile.uris.forEach(uri => forbiddenUris.push(uri));
      }
    }
  });
  if(config){
    config.id = ownProps.editingProfileId;
  }
  return {
    forbiddenNames,
    forbiddenUDPPorts,
    forbiddenUris,
    config,
    publicIp,
    localIps,
    inputIPProfiles,
    tmpProfilesLoading: state.streamhub.tmpProfilesLoading,
    liveGuestResolutions: getAvailableLiveGuestResolutions(state),
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(InputIPProfileForms);