import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {withLocalize} from 'react-localize-redux';
import { Link } from 'react-router-dom';
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import Badge from 'reactstrap/lib/Badge';

import {isString} from "../../../utils/string-utils";
import {LS_THEME, THEME_DARK, THEME_LIGHT} from "../../../constants";
import {apiDocLink} from "../../../utils/global-utils";
import AWNavBar from "@aviwest/ui-kit/dist/js/components/navbar";
import StreamHubLogo from '../streamhub-logo/index'
import {USER_ROLE_ADMIN} from "../../../constants";

const propTypes = {
  hasAdminLevel: PropTypes.bool,
  helpLink: PropTypes.string,
  apiKey: PropTypes.string,
  identity: PropTypes.object,
  location: PropTypes.object,
  outputsDisplayed: PropTypes.bool,
  onBurgerMenuClick: PropTypes.func,
  onOutputsButtonClick: PropTypes.func,
  onLogoutRequested: PropTypes.func,
  onLicenseDetailsRequested: PropTypes.func,
  onLanguageChangeRequested: PropTypes.func,
  warningCount: PropTypes.number,
  errorCount: PropTypes.number,
  expirationDateWarning: PropTypes.bool
};

const NavBar = (props) => {
  const {
    hasAdminLevel,
    apiKey,
    helpLink,
    identity,
    location,
    // outputsDisplayed,
    onBurgerMenuClick,
    onLogoutRequested,
    onLanguageChangeRequested,
    onLicenseDetailsRequested,
    // onOutputsButtonClick,
    warningCount,
    errorCount,
    translate,
    expirationDateWarning
  } = props;

  const hasPath = (path) => {
    if(isString(location)){
      return location.includes(path);
    }
    return location.pathname.includes(path);
  };

  const switchTheme = () => {
    const body = document.body;
    let theme = null;
    if(body.classList.contains(THEME_DARK)){
      body.classList.remove(THEME_DARK);
      body.classList.add(THEME_LIGHT);
      theme = THEME_LIGHT;
    }
    else {
      body.classList.remove(THEME_LIGHT);
      body.classList.add(THEME_DARK);
      theme = THEME_DARK;
    }
    localStorage.setItem(LS_THEME, theme);
  };

  const tabs = [
    {
      title: translate('genericLabel.DASHBOARD.text'),
      active: hasPath('/dashboard'),
      as: Link,
      to: '/dashboard'
    },
    {
      title: translate('genericLabel.FILE_EXPLORER.text'),
      active: hasPath('/files'),
      as: Link,
      to: '/files'
    }
  ];
  if (hasAdminLevel) {
    tabs.push(
      {
        title: translate('genericLabel.SETTINGS.text'),
        active: hasPath('/settings'),
        as: Link,
        to: '/settings'
      }
    )
  }
  tabs.push(
    {
      title: translate('genericLabel.TOOLS.text'),
      active: hasPath('/tools'),
      as: Link,
      to: '/tools',
      badge: errorCount > 0 ? errorCount : (warningCount > 0 ? warningCount : undefined),
      badgeColor: errorCount > 0 ? 'danger' : undefined
    }
  )
  const rightMenu = [
    [
      // {
      //   title: translate('genericLabel.SWITCH_THEME.text'),
      //   icon: 'theme',
      //   as: "a",
      //   onClick: switchTheme
      // },
      {
        title: translate('genericLabel.LANGUAGE.text'),
        icon: 'language',
        as: "a",
        onClick: onLanguageChangeRequested
      }
    ]
  ];
  const additionalItems = [];
  if (hasAdminLevel){
    additionalItems.push(
      {
        title: translate('genericLabel.LICENSE.text'),
        icon: 'license',
        className: expirationDateWarning ? 'icon text-warning' : 'icon',
        as: "a",
        onClick: onLicenseDetailsRequested
      }
    )
  }
  if(helpLink){
    additionalItems.push({
      title: translate('genericLabel.HELP.text'),
      icon: 'faq',
      as: "a",
      href: helpLink,
      target: '_blank'
    });
  }
  if(apiKey && hasAdminLevel){
    additionalItems.push({
      title: translate('genericLabel.REST_API_DOC.text'),
      icon: 'documentation',
      as: "a",
      href: apiDocLink(apiKey),
      target: '_blank'
    });
  }
  if(additionalItems.length > 0){
    rightMenu.push(additionalItems);
  }
  rightMenu.push([{
    title: translate('genericLabel.LOG_OUT.text'),
    icon: 'log_out',
    as: "a",
    onClick: onLogoutRequested
  }]);
  return (
  <AWNavBar beta={false}
            logo={<StreamHubLogo />}
            collapsible={false}
            onBurgerMenuClick={ onBurgerMenuClick }
            tabs={tabs}
            rightTrigger={
              <span>
                <AWIcon name="account"/> {identity.username}
                { expirationDateWarning &&
                  <Badge color="warning" className="ml-2">
                    <AWIcon name="license" />
                  </Badge>
                }
              </span>
            }
            rightMenu={rightMenu}/>
  );
}

NavBar.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    hasAdminLevel: state.identity.role === USER_ROLE_ADMIN,
    warningCount: state.tools.logs.warningCount,
    errorCount: state.tools.logs.errorCount,
    expirationDateWarning: state.license.expirationDateWarning,
  }
};

export default connect(mapStateToProps)(withLocalize(NavBar));