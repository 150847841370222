import { createSelector } from 'reselect'

import {
  INPUT_TYPE_DEVICE,
  STATUS_ERROR,
  STATUS_LIVE,
  STATUS_OFF,
  STATUS_ON
} from "../../../constants";
import {canUseEncoders, getCurrentIPOutputsCount} from "../../../misc/license.selectors";

const getInputs = (state) => state.streamhub.inputs;
const getMultiView = (state) => state.streamhub.multiView;
const getInputsFilters = (state) => state.dashboard.inputsFilters;
const getHardwareOutputs = (state) => state.streamhub.hardwareOutputs;
const getNDIOutputs = (state) => state.streamhub.ndiOutputs;
const getIPOutputs = (state) => state.streamhub.ipOutputs;
const getOutputsFilters = (state) => state.dashboard.outputsFilters;
const getInputIPProfiles = (state) => state.streamhub.inputIPProfiles;
const getEncoders = (state) => state.streamhub.encoders;

export const getVisibleInputs = createSelector(
    [ getInputs, getInputsFilters ],
    (inputs, filters) => {
      return inputs.filter(input => {
        if(!filters.devices && input.type === INPUT_TYPE_DEVICE){
          return false;
        }
        if(!filters.ipInputs && input.ipProfileId != null){
          return false;
        }
        if(filters.statusLive && input.status === STATUS_LIVE){
          return true;
        }
        if(filters.statusOn && (input.status === STATUS_ON || input.status === STATUS_ERROR)){
          return true;
        }
        if(filters.statusOff && input.status === STATUS_OFF){
          return true;
        }
        return false;
      });
    }
);

export const getVisibleMultiView = createSelector(
    [getMultiView, getInputsFilters],
    (multiView, filters) => {
      if(!multiView || !filters.multiView){
        return null;
      }
      if(filters.statusLive && multiView.status === STATUS_LIVE){
        return multiView;
      }
      if(filters.statusOn && (multiView.status === STATUS_ON || multiView.status === STATUS_ERROR)){
        return multiView;
      }
      if(filters.statusOff && multiView.status === STATUS_OFF){
        return multiView;
      }
      return null;
    }
);

export const getHardwareOutputsCount = createSelector(
  [ getHardwareOutputs ],
  (outputs) => {
    if(!outputs){
      return 0;
    }
    return outputs.length;
  }
);

export const getNDIOutputsCount = createSelector(
  [ getNDIOutputs ],
  (outputs) => {
    if(!outputs){
      return 0;
    }
    return outputs.length;
  }
);

export const getOutputsCount = createSelector(
  [ getHardwareOutputsCount, getNDIOutputsCount, getCurrentIPOutputsCount ],
  (hardwareCount, ndiCount, ipCount) => {
    return hardwareCount + ndiCount + ipCount;
  }
);

export const getVisibleHardwareOutputs = createSelector(
    [ getHardwareOutputs, getOutputsFilters ],
    (outputs, filters) => {
      if(!filters.hardware){
        return [];
      }
      return outputs.filter(output => {
        if(filters.statusLive && output.status === STATUS_LIVE){
          return true;
        }
        if(filters.statusOn && (output.status === STATUS_ON || output.status === STATUS_ERROR)){
          return true;
        }
        if(filters.statusOff && output.status === STATUS_OFF){
          return true;
        }
        return false;
      });
    }
);

export const getVisibleNDIOutputs = createSelector(
    [ getNDIOutputs, getOutputsFilters ],
    (outputs, filters) => {
      if(!filters.ndiOutputs){
        return [];
      }
      return outputs.filter(output => {
        if(filters.statusLive && output.status === STATUS_LIVE){
          return true;
        }
        if(filters.statusOn && (output.status === STATUS_ON || output.status === STATUS_ERROR)){
          return true;
        }
        if(filters.statusOff && output.status === STATUS_OFF){
          return true;
        }
        return false;
      });
    }
);

export const getVisibleIPOutputs = createSelector(
    [ getIPOutputs, getOutputsFilters ],
    (outputs, filters) => {
      if(!filters.ipOutputs){
        return [];
      }
      return outputs
        .filter(output => {
          if(filters.statusLive && output.status === STATUS_LIVE){
            return true;
          }
          if(filters.statusOn && (output.status === STATUS_ON || output.status === STATUS_ERROR)){
            return true;
          }
          if(filters.statusOff && output.status === STATUS_OFF){
            return true;
          }
          return false;
        })
        .sort((output1, output2) => {
          return output1.index - output2.index;
      });
    }
);

export const getAvailableInputIPProfiles = createSelector(
    [ getInputs, getInputIPProfiles ],
    (inputs, ipProfiles) => {
      const attributedProfileIds = inputs.map(input => input.ipProfileId);
      return ipProfiles.filter(ipProfile => attributedProfileIds.indexOf(ipProfile.id) === -1);
    }
);

export const activeEncodersCount = createSelector(
  [ canUseEncoders, getEncoders ],
  (allowed, encoders) => {
    if(!allowed || !encoders){
      return -1;
    }
    return encoders.filter(encoder => (encoder.status === STATUS_LIVE && encoder.enable === true)).length;
  }
);

export const enableEncodersCount = createSelector(
  [ canUseEncoders, getEncoders ],
  (allowed, encoders) => {
    if(!allowed || !encoders){
      return -1;
    }
    return encoders.filter(encoder => encoder.enable === true).length;
  }
);