import {
  SETTINGS_GENERAL_EXPORT_CONFIG, SETTINGS_GENERAL_EXPORT_STREAMHUB_PROFILE,
  SETTINGS_GENERAL_IMPORT_CONFIG,
  SETTINGS_GENERAL_IMPORT_CONFIG_MODAL,
  SETTINGS_GENERAL_RESET_CONFIG,
  SETTINGS_GENERAL_RESET_CONFIG_MODAL,
  SETTINGS_GENERAL_UPDATE
} from "../../../../constants";

export const updateGeneralSettings = (data) => {
  return {
    type: SETTINGS_GENERAL_UPDATE,
    data
  }
};

export const exportConfig = () => {
  return {
    type: SETTINGS_GENERAL_EXPORT_CONFIG
  }
};

export const importConfigModal = (open) => {
  return {
    type: SETTINGS_GENERAL_IMPORT_CONFIG_MODAL,
    open
  }
};

export const importConfig = (file) => {
  return {
    type: SETTINGS_GENERAL_IMPORT_CONFIG,
    file
  }
};

export const resetConfigModal = (open) => {
  return {
    type: SETTINGS_GENERAL_RESET_CONFIG_MODAL,
    open
  }
};

export const resetConfig = () => {
  return {
    type: SETTINGS_GENERAL_RESET_CONFIG
  }
};

// Redux Thunk middleware action
export const exportStreamhubProfile = () => (dispatch, getState) => {
  const { config, streamhub } = getState();

  const profile = {
    valid: {
      name: config.device.Identifier,
      serverType: 0,
      ipAddress: streamhub.publicIp,
      atpPort: config.ebonding.atpGeneralBasePort,
      username: config.ebonding.username,
      password: config.ebonding.password,
      deviceIdentifier: 'APP'
    }
  };

  dispatch({
    type: SETTINGS_GENERAL_EXPORT_STREAMHUB_PROFILE,
    profile
  });
};