import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import {Button} from 'reactstrap';
import {connect} from "react-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {inputIpProfilePropTypes, inputPropTypes} from "../../../utils/models-prop-types";
import {inputDisplayName, inputMatchingIPProfile} from "../../../utils/global-utils";
import {STATUS_OFF, PLAYBACK_STATUS_PAUSED} from "../../../constants";
import AWPopup, {AWPopupCol, AWPopupRow} from "@aviwest/ui-kit/dist/js/components/popup";

const propTypes = {
  inputIPProfiles: PropTypes.arrayOf(inputIpProfilePropTypes).isRequired,
  inputs: PropTypes.arrayOf(inputPropTypes).isRequired,
  onChange: PropTypes.func.isRequired
};

const PlaybackSelector = (props) => {
  const {
    inputs,
    inputIPProfiles,
  } = props;

  const handleSelection = (input, close) => {
    props.onChange(input);
    close();
  };

  const handleMouseLeave = (close) => {
    close();
  };

  const canPlayback = (input) => {
    return ((input.status === STATUS_OFF || input.playbackStatus === PLAYBACK_STATUS_PAUSED ) && !input.booking);
  }
  return (
  <AWPopup portal={true}
           className={'playback-selector'}
           scrollable
           shadow={true}
           direction="right"
           renderTrigger={({ ref, onClick, className }) => (
             <Button className={`basic playback-selector ${className}`}
                     innerRef={ref}
                     onClick={onClick}>
               <AWIcon name="playback"/>
             </Button>
           )}>
      {({ close }) => (
      <AWPopupRow>
        <AWPopupCol>
          <ul className="list" onMouseLeave={ () => handleMouseLeave(close) }>
            { inputs
            .map(currentInput => {
              const inputName = inputDisplayName(currentInput, inputMatchingIPProfile(inputIPProfiles, currentInput));
              return (
                <Fragment key={currentInput.id}>
                { canPlayback(currentInput) &&
                <li
                    className="list-item"
                    onClick={ () => handleSelection(currentInput, close) }>
                  <div className="text-clip" title={ inputName }>{ inputName }</div>
                </li>
                }
                { !canPlayback(currentInput) &&
                <li
                    className="disabled">
                  <div className="text-clip" title={ inputName }>{ inputName }</div>
                </li>
                }
                </Fragment>
              );
            })}
          </ul>
        </AWPopupCol>
      </AWPopupRow>
      )}
    </AWPopup>
  );
};

PlaybackSelector.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const {
    inputIPProfiles,
    inputs,
  } = state.streamhub;

  return {
    inputIPProfiles,
    inputs
  };
};

export default connect(mapStateToProps)(PlaybackSelector);