import PropTypes from "prop-types";
import React, { Component } from "react";
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Tooltip} from "reactstrap";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {inputIpProfilePropTypes, outputIpProfilePropTypes} from "../../../utils/models-prop-types";
import {withLocalize} from "react-localize-redux";

const propTypes = {
  ipProfile: PropTypes.oneOfType([inputIpProfilePropTypes, outputIpProfilePropTypes]),
  uri: PropTypes.string,
  translate: PropTypes.func.isRequired
};

class CopyToClipboardButton extends Component {

  constructor(props){
    super(props);

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleContainerRef = this.handleContainerRef.bind(this);
    this.handleCopyToClipboard = this.handleCopyToClipboard.bind(this);

    this.state = {
      tooltip: false,
      containerRef: null,
      dropdownOpen: false
    };
  }

  handleContainerRef(ref){
    this.setState({
      containerRef: ref
    });
  }

  componentWillUnmount(){
    clearTimeout(this.timer);
  }

  handleCopyToClipboard(value){
    //Copying
    const el = document.createElement('textarea');
    el.value = value;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.setState({
      tooltip: true
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({
        tooltip: false
      });
    }, 1000);
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  render() {
    const { ipProfile, uri } = this.props;
    if(!ipProfile || (!ipProfile.uri && !ipProfile.uris && !ipProfile.localUris && !ipProfile.publicUri)){
      return null;
    }
    let button = null;
    if (uri) {
      button = <Button className="basic icon"
                       onClick={() => this.handleCopyToClipboard(uri)}>
        <AWIcon name="link" id={`link_${ipProfile.id}`}/>
      </Button>;
    }
    else if(ipProfile.uri){
      button = <Button className="basic icon"
              onClick={() => this.handleCopyToClipboard(ipProfile.uri)}>
        <AWIcon name="link" id={`link_${ipProfile.id}`}/>
      </Button>;
    }
    else {
      button = <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
        <DropdownToggle
            tag="button"
            className="btn basic icon"
            onClick={this.toggleDropdown}
            data-toggle="dropdown"
            aria-expanded={this.state.dropdownOpen}>

          <AWIcon name="link" id={`link_${ipProfile.id}`}/>
        </DropdownToggle>
        <DropdownMenu right>
          { ipProfile.uris && ipProfile.uris.map((uri, index) => (
              <DropdownItem key={uri}
                            onClick={() =>  this.handleCopyToClipboard(uri)}>
                { `${this.props.translate("genericLabel.URI.text")} ${index + 1}` }
              </DropdownItem>
          ))}
          { ipProfile.localUris && ipProfile.localUris.map((uri, index) => (
              <DropdownItem key={uri}
                            onClick={() =>  this.handleCopyToClipboard(uri)}>
                { `${this.props.translate("genericLabel.LOCAL_URI.text")} ${index + 1}` }
              </DropdownItem>
          ))}
          { ipProfile.publicUri &&
          <DropdownItem onClick={() =>  this.handleCopyToClipboard(ipProfile.publicUri)}>
            {this.props.translate("genericLabel.PUBLIC_URI.text")}
          </DropdownItem>
          }
        </DropdownMenu>
      </Dropdown>;
    }
    return (
        <span ref={this.handleContainerRef} className="copy-to-clipboard">
          {button}
          { this.state.containerRef &&
          <Tooltip position="top-end" isOpen={this.state.tooltip} target={`link_${ipProfile.id}`}>
            { this.props.translate("genericLabel.CLIPBOARD_COPY.text") }
          </Tooltip>
          }
        </span>
    );
  }
}

CopyToClipboardButton.propTypes = propTypes;

export default withLocalize(CopyToClipboardButton);
