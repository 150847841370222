import {Formik} from "formik";
import PropTypes from "prop-types";
import React from "react";
import {Button, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {Translate, withLocalize} from "react-localize-redux";

import {outputHlsLocalURIs, outputHlsPublicURI} from "../../../../misc/streamhub.adapters";
import {containsUnderscore, isEmptyString} from "../../../../utils/string-utils";
import {HLS} from "../../../../constants";
import HelpLayout from "../../../common/help-layout";

const propTypes = {
  additionalActions: PropTypes.node,
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    dir: PropTypes.string
  }),
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  forbiddenUris: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  localIps: PropTypes.arrayOf(PropTypes.string),
  publicIp: PropTypes.string,
  alert: PropTypes.node,
  protocol: PropTypes.node
};

const HLSForm = (props) => {
  const { config, forbiddenNames, forbiddenUris, localIps, publicIp, translate, alert, protocol } = props;

  const uriList = (values) => {
    const uris = outputHlsLocalURIs(localIps, values);
    uris.push(outputHlsPublicURI(publicIp, values));
    return uris;
  };

  const handleFormSubmit = (values) => {

    props.onSubmit(values);
  };

  const handleValidation = (values) => {
    const errors = {};

    // Name
    if(isEmptyString(values.name)){
      errors.name = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.name.length >= 32){
      errors.name = 'genericLabel.TOO_LONG.text';
    }
    else if(forbiddenNames.indexOf(values.name) !== -1){
      errors.name = 'genericLabel.DUPLICATED_VALUES.text';
    }

    if(isEmptyString(values.dir)){
      errors.dir = 'genericLabel.REQUIRED_FIELD.text';
    }

    if(containsUnderscore(values.dir)){
      errors.dir = 'genericLabel.INVALID_FORMAT.text';
    }

    const uris = uriList(values);
    if(uris !== null && uris.find(uri => forbiddenUris.filter( element => element.uri === uri).length !== 0) !== undefined){
      errors.duplicateUriFrom = forbiddenUris.find(element => uris.filter(uri => uri === element.uri).length !== 0).name
      errors.dir = 'genericLabel.DUPLICATED_VALUES_FROM.text';
    }
    return errors;
  };

  return (
    <Formik initialValues={{
              id: config ? config.id : undefined,
              mode: HLS,
              name: config ? config.name : '',
              dir: config ? config.dir : ''
            }}
            validate={ handleValidation }
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={ handleFormSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          /* and other goodies */
        }) => (
        <Form onSubmit={ handleSubmit }>

          <HelpLayout
          filename={`c_sh_hls_output_profile.html`}
          form={<fieldset disabled={config && config.enable === true}>
            {alert}
            {protocol}

            <FormGroup>
              <Label for="name">
                <Translate id="genericLabel.NAME.text"/>
              </Label>
              <Input type="text"
                    name="name"
                    id="name"
                    invalid={errors.name !== undefined}
                    placeholder={ translate('genericLabel.NAME.text') }
                    value={values.name}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.name} />
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label for="dir">
                <Translate id="genericLabel.FOLDER.text"/>
              </Label>
              <Input type="text"
                    name="dir"
                    id="dir"
                    invalid={errors.dir !== undefined}
                    placeholder={ translate('genericLabel.FOLDER.text') }
                    value={values.dir}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.dir} /> { errors.duplicateUriFrom }
              </FormFeedback>
              <div className="uri-preview">
                { uriList(values).map(uri => (<div key={uri} className="uri">{uri}</div>)) }
              </div>
            </FormGroup>
            </fieldset>}

            buttons={<FormGroup className="buttons">
              { props.additionalActions }
              <Button color="primary"
                      disabled={!dirty}
                      type="submit">
                <Translate id="genericLabel.SAVE.text"/>
              </Button>
            </FormGroup>
          } />
        </Form>
      )}
    </Formik>
  );
};

HLSForm.propTypes = propTypes;

export default withLocalize(HLSForm);
