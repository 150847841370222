import PropTypes from 'prop-types';
import React from 'react';
import { AWControlGridItem } from '@aviwest/ui-kit/dist/js/components/control-grid';
import AWIcon from '@aviwest/ui-kit/dist/js/components/icon';

const propTypes = {
  onClick: PropTypes.func.isRequired
};

const NewEncoderButton = (props) => {
  const {
    onClick
  } = props;
  return (
    <AWControlGridItem className="aw-encoder new" available collapsed onClick={onClick}>
      <AWIcon name="add" />
    </AWControlGridItem>
  );
};

NewEncoderButton.propTypes = propTypes;

export default NewEncoderButton;