import PropTypes from 'prop-types';
import React from 'react';
import {Button, Table} from 'reactstrap';
import {Translate} from "react-localize-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import AWPopup, {AWPopupCol, AWPopupRow} from "@aviwest/ui-kit/dist/js/components/popup";

const propTypes = {
  sessionsCount: PropTypes.number,
  version: PropTypes.string,
  hardwareIdentifier: PropTypes.string,
  publicIp: PropTypes.string,
  localIps: PropTypes.arrayOf(PropTypes.string),
};

const Info = React.memo((props) => {
  const {
    hardwareIdentifier,
    sessionsCount,
    version,
    publicIp,
    localIps
  } = props;

  return (
  <div className="info">
    <AWPopup direction="top-start"
             shadow={true}
             renderTrigger={({ ref, onClick, className }) => (
             <Button innerRef={ref}
                     className={`${className} basic`}
                     onClick={ onClick }>
               <AWIcon name="info_circle"/>
             </Button>
             )}>
      {() => (
      <AWPopupRow>
        <AWPopupCol>
          <Table size="sm" borderless={true}>
            <tbody>
            <tr>
              <td><Translate id="genericLabel.STREAMHUB_USERS.text"/></td>
              <td className="value">{sessionsCount}</td>
            </tr>
            <tr>
              <td><Translate id="genericLabel.FIRMWARE_VERSION.text"/></td>
              <td className="value">{version}</td>
            </tr>
            <tr>
              <td><Translate id="genericLabel.HARDWARE_ID.text"/></td>
              <td className="value">{hardwareIdentifier}</td>
            </tr>
            <tr>
              <td><Translate id="genericLabel.PUBLIC_IP.text"/></td>
              <td className="value">{publicIp}</td>
            </tr>
            <tr>
              <td><Translate id="genericLabel.LOCAL_IP.text"/></td>
              <td className="value">{localIps != null && localIps.join(', ')}</td>
            </tr>

            </tbody>
          </Table>
        </AWPopupCol>
      </AWPopupRow>
      )}
    </AWPopup>
  </div>
  )
})

Info.propTypes = propTypes;
export default Info;