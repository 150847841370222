import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Split from "react-split";
import { withRouter } from "react-router";

import InputSection from "./input-section";
import EncoderSection from "./encoder-section";
import OutputSection from './output-section';
import FileSection from "./file-section";

import {
  multiViewPropTypes
} from '../../../utils/models-prop-types';

import {
  changeSizes,
} from './dashboard.actions';

import {
  getAvailableInputIPProfiles,
  getVisibleInputs,
  getVisibleMultiView
} from "./dashboard.selectors";
import AWIcon from '@aviwest/ui-kit/dist/js/components/icon';
import IpInputProfilesSettingsModal from "./input-section/ip-input-profiles-settings/";
import EncoderProfilesSettingsModal from './encoder-section/encoder-profiles-settings';
import IpOutputProfilesSettingsModal from "./output-section/ip-output-profiles-settings/";

const propTypes = {
  callChangeSizes: PropTypes.func.isRequired,
  horizontalSectionsSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  verticalSectionsSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  encodersFilesMinHeight: PropTypes.number,
  inputsMinWidth: PropTypes.number,
  inputsMinHeight: PropTypes.number,
  outputsMinWidth: PropTypes.number,
  mobileOutputsVisible: PropTypes.bool,
  encodersVisible: PropTypes.bool,
  filesVisible: PropTypes.bool,
  isMobile: PropTypes.bool,

  multiView: multiViewPropTypes,

  ready: PropTypes.bool.isRequired
};

class DashboardPage extends Component {

  renderGutter(index, direction, pairElement) {
    if (pairElement) {
      return pairElement.previousSibling;
    }
    const gutter = document.createElement('div');
    ReactDOM.render(
      <div className="separator">
        <AWIcon className="handle" name={`grip_${direction === "horizontal" ? "vertical" : "horizontal"}`} />
      </div>,
      gutter)
    gutter.className = `gutter gutter-${direction}`;
    return gutter;
  }

  render() {
    const {
      callChangeSizes,
      horizontalSectionsSizes,
      verticalSectionsSizes,
      encodersFilesMinHeight,
      inputsMinWidth,
      inputsMinHeight,
      outputsMinWidth,
      encodersVisible,
      filesVisible,
      isMobile
    } = this.props;

    return (
      <Fragment>
        {isMobile ? (
          <div className="dashboard mobile pretty-scroll">
            <InputSection />
            <EncoderSection />
            <FileSection />
            <OutputSection />
          </div>
        ) : (
          <div className="dashboard">
            <Split className="flex-horizontal"
              direction="horizontal"
              gutter={this.renderGutter}
              gutterSize={isMobile ? 0 : 15}
              onDragEnd={(sizes) => callChangeSizes(true, sizes)}
              minSize={[inputsMinWidth, outputsMinWidth]}
              sizes={verticalSectionsSizes}>
              <Split className="flex-vertical"
                direction="vertical"
                gutter={this.renderGutter}
                gutterSize={encodersVisible || filesVisible ? 15 : 0}
                onDragEnd={(sizes) => callChangeSizes(false, sizes)}
                minSize={[inputsMinHeight, encodersFilesMinHeight]}
                sizes={horizontalSectionsSizes}>
                <InputSection />
                <div>
                  <EncoderSection />
                  <FileSection />
                </div>
              </Split>
              <OutputSection />
            </Split>
          </div>
        )}
        <IpInputProfilesSettingsModal />
        <EncoderProfilesSettingsModal />
        <IpOutputProfilesSettingsModal />
      </Fragment>
    )
  }
}

DashboardPage.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const {
    ready,
    isMobile,
    horizontalSectionsSizes,
    verticalSectionsSizes,
    encodersFilesMinHeight,
    inputsMinWidth,
    inputsMinHeight,
    outputsMinWidth,
    mobileOutputsVisible,
    encodersVisible,
    filesVisible
  } = state.dashboard;

  const {
    inputs,
    inputIPProfiles,
  } = state.streamhub;

  return {
    ready,

    availableInputIPProfiles: getAvailableInputIPProfiles(state),
    inputIPProfiles,
    inputsCount: inputs.length,
    inputsVisible: getVisibleInputs(state),
    multiView: getVisibleMultiView(state),
    horizontalSectionsSizes,
    verticalSectionsSizes,
    mobileOutputsVisible,
    encodersFilesMinHeight,
    inputsMinWidth,
    inputsMinHeight,
    outputsMinWidth,
    isMobile,
    encodersVisible,
    filesVisible
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callChangeSizes: (vertical, positions) => dispatch(changeSizes(vertical, positions))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardPage));