import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {Col, Row} from "reactstrap";
import DayJs from "dayjs";

import { licenseFeaturesPropTypes } from "../../../utils/models-prop-types";
import {LICENSE_STATUS_INVALID, LICENSE_STATUS_VALID, LICENSE_MS_TO_EXPIRATION} from "../../../constants";

const LICENSE_DATE_FORMAT = 'YYYY-MM-DD';

const propTypes = {
  features: licenseFeaturesPropTypes,
  status: PropTypes.oneOf([LICENSE_STATUS_VALID, LICENSE_STATUS_INVALID]),
  translate: PropTypes.func.isRequired
};

const formatExpirationDate = (dateString, translate) => {
  if(dateString === '' || dateString === undefined ){
    return translate('genericLabel.PERMANENT.text');
  }
  return (
    <span className={(Date.parse(dateString) - Date.now()) < LICENSE_MS_TO_EXPIRATION ? 'text-warning' : ''}>
      {DayJs(dateString, LICENSE_DATE_FORMAT).format('YYYY-MM-DD')}
    </span>
  )
};

const LicenseFeature = ({ name, label, features, translate }) => {
  const feature = features[name];
  if(!feature){
    return null;
  }
  let value = '';
  let details = null;
  let expirationDate = null;
  if(Array.isArray(feature)){
    value = feature.reduce((prev, next) => prev + next.count, 0 );
    details = (
      <ul className="secondary">
        { feature.map((feat, index) => (
            <li key={index}>
              {feat.count} {formatExpirationDate(feat.expirationDate, translate)}
            </li>
        ))}
      </ul>
    );
  }
  else {
    value = feature.enabled ? translate('genericLabel.YES.text') : translate('genericLabel.NO.text');
    expirationDate = feature.enabled ? formatExpirationDate(feature.expirationDate, translate) : null;
  }
  return (
    <Fragment>
      {label}
      <span className="value">{value}</span> { expirationDate && <span className="expiration-date">{expirationDate}</span> }
      {details}
    </Fragment>
  )
};

const LicenseDetails = (props) => {

  const {hardwareIdentifier, details, features, status, translate, orionConnectionStatus, hotdeployInProgress } = props;
  const licenseExpirationDate = formatExpirationDate(features.expirationDate, translate);
  if(!features){
    return null;
  }
  return (
    <div className="license">
      <div className="status">
        <Translate id="genericLabel.LICENSE_STATUS.text"/>: <span className={ status === LICENSE_STATUS_VALID ? 'valid' : 'invalid' }>{ status }</span>
        { !orionConnectionStatus &&
        <span className="badge badge-dark"><Translate id="genericLabel.LICENSE_STANDALONE.text"/></span>
        }
        { orionConnectionStatus &&
        <span className={`badge ${hotdeployInProgress ? 'badge-warning' : 'badge-success'}`}><Translate id="genericLabel.LICENSE_CONNECTED.text"/></span>
        }
        <span className="badge badge-dark ml-2">{licenseExpirationDate}</span>
      </div>
      <Row className="row details">
        <Col xs={12} sm={6}>
          <ul>
            <li>
              <Translate id="genericLabel.HARDWARE_ID.text"/>
              <span className="value">{ features.hardwareIdentifier || hardwareIdentifier }</span>
            </li>
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.INPUTS.text') } name="input" translate={translate}/>
            </li>
            { details['DMNGApp'] !== 0 &&
            <li>
              <LicenseFeature features={features} label={ 'DMNG App' } name="DMNGApp" translate={translate}/>
            </li>
            }
            { details['DMNGApp'] === 0 &&
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.MOJOPRO.text') } name="MOJOPRO" translate={translate}/>
            </li>
            }
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.STANDARD_IP_PROTOCOLS.text') } name="IPInput" translate={translate}/>
            </li>
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.LOCAL_OUTPUTS.text') } name="physicalOutput" translate={translate}/>
            </li>
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.CONTRIBUTION_CHANNELS.text') } name="IPOutput" translate={translate}/>
            </li>
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.NDI_INPUTS.text') } name="NDIInput" translate={translate}/>
            </li>
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.NDI_OUTPUTS.text') } name="NDIOutput" translate={translate}/>
            </li>
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.WEB_RTC_INPUT.text') } name="WebRTCInput" translate={translate}/>
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={6}>
          <ul>
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.ENCODERS.text') } name="encoder" translate={translate}/>
            </li>
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.FILE_TRANSFER_DESTINATIONS.text') } name="FTPPush" translate={translate}/>
            </li>
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.PLAYBACK.text') } name="playback" translate={translate}/>
            </li>
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.RECORD.text') } name="record" translate={translate}/>
            </li>
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.INTERCOM.text') } name="intercom" translate={translate}/>
            </li>
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.AES_ENCRYPTION.text') } name="AES" translate={translate}/>
            </li>
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.UHD.text') } name="FourK" translate={translate}/>
            </li>
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.MULTI_VIEW.text') } name="multiView" translate={translate}/>
            </li>
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.HOT_SPOT.text') } name="HotSpot" translate={translate}/>
            </li>
            <li>
              <LicenseFeature features={features} label={ translate('genericLabel.VIDEO_RETURN.text') } name="videoReturn" translate={translate}/>
            </li>
            {(features.HotSpot_PayAsYouGo || features.MOJOPRO_PayAsYouGo) &&
            <li>
              <Translate id="genericLabel.PAY_AS_YOU_GO.text"/>
              <ul className="secondary">
                <li>
                  <LicenseFeature features={features} label={ translate('genericLabel.HOT_SPOT.text') } name="HotSpot_PayAsYouGo" translate={translate}/>
                </li>
                <li>
                  <LicenseFeature features={features} label={ translate('genericLabel.MOJOPRO.text') } name="MOJOPRO_PayAsYouGo" translate={translate}/>
                </li>
              </ul>
            </li>
            }
          </ul>
        </Col>
      </Row>

    </div>
  )
};

LicenseDetails.propTypes = propTypes;

export default withLocalize(LicenseDetails);