import PropTypes from 'prop-types';
import React, {Component} from 'react';
import { Button } from 'reactstrap';
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {
  DASHBOARD_LAYOUT_FULLSIZE,
  DASHBOARD_LAYOUT_GRID,
  DASHBOARD_LAYOUT_INLINE,
  PLAYBACK_STATUS_OFF,
  PLAYBACK_STATUS_PLAYING,
} from "../../../../../constants";
import {inputPropTypes} from "../../../../../utils/models-prop-types";
import { AWControlGridItemActions } from '@aviwest/ui-kit';

const propTypes = {
  input: inputPropTypes,
  hasViewerLevel: PropTypes.bool,
  onGoToStart: PropTypes.func.isRequired,
  onGoToEnd: PropTypes.func.isRequired,
  onPlay: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  onCloseDetails: PropTypes.func.isRequired,
  onShowDetails: PropTypes.func.isRequired,
  layout: PropTypes.oneOf([DASHBOARD_LAYOUT_GRID, DASHBOARD_LAYOUT_INLINE, DASHBOARD_LAYOUT_FULLSIZE]),
};

class PlaybackControls extends Component {

  render(){
    const {
      input,
      onGoToStart,
      onGoToEnd,
      onPlay,
      onPause,
      onCloseDetails,
      onShowDetails,
      layout,
      hasViewerLevel
    } = this.props;
    const playbackStatus = input.playbackStatus;

    if(playbackStatus === PLAYBACK_STATUS_OFF) {
      return null;
    }
    return (
      <AWControlGridItemActions>
        <Button className="basic"
                disabled={hasViewerLevel}
                onClick={ onGoToStart }>
          <AWIcon name="player_step_backward"/>
        </Button>
        <Button active={playbackStatus === PLAYBACK_STATUS_PLAYING}
                className="basic"
                disabled={hasViewerLevel}
                onClick={ () => playbackStatus === PLAYBACK_STATUS_PLAYING ? onPause() : onPlay() }>
          <AWIcon name={ playbackStatus === PLAYBACK_STATUS_PLAYING ? 'pause' : 'play' }/>
        </Button>
        <Button className="basic"
                disabled={hasViewerLevel}
                onClick={ onGoToEnd }>
          <AWIcon name="player_step_forward"/>
        </Button>
        <Button className="basic"
                disabled={hasViewerLevel}
                onClick={ () => layout === DASHBOARD_LAYOUT_FULLSIZE ? onCloseDetails(input) : onShowDetails(input) }>
          <AWIcon name={layout === DASHBOARD_LAYOUT_FULLSIZE ? 'shrink' : 'expand'}/>
        </Button>
      </AWControlGridItemActions>
    );
  }
}

PlaybackControls.propTypes = propTypes;

export default PlaybackControls;