import { ClipLoader } from 'react-spinners'
import React from 'react';
import {Translate} from "react-localize-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import { inputPropTypes } from "../../../../../utils/models-prop-types";
import {
  FORWARD_STATUS_FORWARDING,
  LIVE_STATUS_CONNECTING, LIVE_STATUS_ERROR
} from "../../../../../constants";
import Badge from 'reactstrap/lib/Badge';

const propTypes = {
  input: inputPropTypes.isRequired
};

const DeviceStatus = (props) => {
  let { deviceForwardStatus, deviceLiveStatus, deviceMessage, } = props.input;

  if(!deviceMessage){
    return null;
  }

  let icon = null;
  if (deviceLiveStatus === LIVE_STATUS_ERROR) {
    icon = <AWIcon name="warning"/>;
  }
  else if (deviceLiveStatus === LIVE_STATUS_CONNECTING) {
    icon = <span className="loader"><ClipLoader loading={true} size={12} css={{ verticalAlign: 'top' }}/></span>
  }
  else if (deviceForwardStatus === FORWARD_STATUS_FORWARDING) {
    icon = <AWIcon name="forward"/>
  }
  else {
    icon = <AWIcon name="check_circle"/>
  }
  return (
      <Badge className="text-clip">
        { icon } <Translate id={`messageInfo.${deviceMessage}.text`}></Translate>
      </Badge>
  );
};

DeviceStatus.propTypes = propTypes;

export default DeviceStatus;