import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {Translate, withLocalize} from 'react-localize-redux';
import { Button, Form, FormGroup} from 'reactstrap';
import {Formik} from "formik";

import {hasFileExtension} from "../../../utils/global-utils";
import Dropzone from "react-dropzone";
import {ClipLoader} from "react-spinners";
import {SPINNER_UPLOAD_COLOR} from "../../../constants";

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  result: PropTypes.shape({
    res: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
  }),
  translate: PropTypes.func.isRequired
};

const LicenseForm = (props) => {

  const { loading, result } = props;

  const handleFormSubmit = (values) => {
    props.onSubmit(values.file);
  };

  const handleValidation = (values) => {
    const errors = {};
    if(!values.file){
      errors.file = props.translate('genericLabel.REQUIRED_FIELD.text');
    }
    else if(!hasFileExtension(values.file.name, 'awl') && !hasFileExtension(values.file.name, 'json')){
      errors.file = props.translate('genericLabel.INVALID_EXTENSION_CHOICE.text', { 'param.firstChoice': '.awl', 'param.secondChoice': '.json' });
    }
    return errors;
  };

  return (
    <Formik initialValues={{
              file: null
            }}
            validate={ handleValidation }
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={ handleFormSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          /* and other goodies */
        }) => (
        <Form onSubmit={handleSubmit}>
          { result &&
          <div className={`license-message ${result.res === 0 ? 'success' : 'error' }`}>
            { result.message }
          </div>
          }
          { !loading &&
          <FormGroup>
            <Dropzone multiple={false}
                      accept=".json"
                      onDrop={files => setFieldValue('file', files[0])}>
              {({getRootProps, getInputProps, isDragActive, acceptedFiles}) => (
                <div
                  className={`dropzone ${isDragActive ? 'target' : (errors.file != null ? 'error' : '')}`} {...getRootProps()}>
                    <span className="placeholder">
                      {acceptedFiles.length === 0 && <Translate id="genericLabel.LICENSE_DROPZONE_PLACEHOLDER.text"/>}
                      {acceptedFiles.length > 0 &&
                      <div className="filename">
                        {acceptedFiles.map(acceptedFile => acceptedFile.name)}
                      </div>
                      }
                      {errors.file != null && errors.file}
                    </span>
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </FormGroup>
          }
          { loading &&
            <Fragment>
              <ClipLoader loading={true} color={SPINNER_UPLOAD_COLOR} size={35}/>
              <div className="filename">
                { values.file && values.file.name }
              </div>
            </Fragment>
          }
          <FormGroup className="buttons">
            <Button color="primary"
                    disabled={!dirty || loading || errors.file != null }
                    size="lg"
                    type="submit">
              <Translate id="genericLabel.UPDATE.text"/>
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

LicenseForm.propTypes = propTypes;

export default withLocalize(LicenseForm);