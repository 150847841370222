import {
  LICENSE_MODAL,
  LICENSE_UPLOAD, LICENSE_UPLOAD_REPONSE,
} from "../constants";


export const openLicenseModal = (open) => {
  return {
    type: LICENSE_MODAL,
    open
  }
};

export const uploadLicense = (file) => {
  return {
    type: LICENSE_UPLOAD,
    file
  }
};

export const licenseUploadResponse = (result) => {
  return {
    type: LICENSE_UPLOAD_REPONSE,
    result
  }
};