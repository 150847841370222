import PropTypes from "prop-types";
import React, { Fragment } from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import {Alert} from "reactstrap";
import {filePropTypes, outputIpProfilePropTypes} from "../../../utils/models-prop-types";
import Player from "../player";
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";

const propTypes = {
  onClose: PropTypes.func.isRequired,
  file: filePropTypes,
  outputIPProfile: outputIpProfilePropTypes,
  streamhubLocalIps: PropTypes.arrayOf(PropTypes.string)
};

const PlayerModal = (props) => {
  const { file, outputIPProfile, streamhubLocalIps } = props;
  const open = file != null || outputIPProfile != null;
  return (
  <AWModal icon="playback"
           onClose={props.onClose}
           open={open}
           title={ props.translate('genericLabel.VIDEO_PLAYER.text') }>
        { open &&
        <Fragment>
          <Alert color="warning">
            <Translate id="genericLabel.VIDEO_HEVC_NOT_SUPPORTED.text"/>
          </Alert>
          <Player controls={true}
                  outputIPProfile={outputIPProfile}
                  file={file}
                  streamhubLocalIps={streamhubLocalIps}/>
          </Fragment>
        }
      </AWModal>
  );
};

PlayerModal.propTypes = propTypes;

export default withLocalize(PlayerModal);