import { routerMiddleware, connectRouter, push, ConnectedRouter } from 'connected-react-router';
import Qs from 'query-string';
import React from 'react';
import { render } from 'react-dom';
import { LocalizeProvider } from 'react-localize-redux';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import ThunkMiddleware from 'redux-thunk';
import { createBrowserHistory } from "history";
import HTML5Backend from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

import Api from './utils/api';
import App from './App';
import BannerMiddleware from './misc/banner.middleware';
import Logger from './utils/logger';
import Reducers from './reducers';
import ResponsiveMiddleware from './misc/responsive.middleware';
import SocketMiddleware from './misc/socket.middleware';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'rc-slider/dist/rc-slider.min.css';
import './assets/fonts/material-icons.min.css';
import './index.scss';
import {
  LS_DEBUG,
  LS_THEME,
  RECEIVED_ENCODERS_PREVIEW,
  RECEIVED_INPUT_INTERCOM_INFO,
  RECEIVED_INPUT_INTERCOM_VOLUME,
  RECEIVED_MONITOR_INFO,
  RECEIVED_OUTPUT_STATS,
  RECEIVED_STREAM_STATS,
  DASHBOARD_HIGHLIGHT_ENCODER,
  DASHBOARD_HIGHLIGHT_INPUT,
  THEME_DARK
} from "./constants";

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory({
  basename: '/'
});

function addLocationQuery(history) {
  history.location = {
    ...history.location,
    query: Qs.parse(history.location.search)
  }
}

// Google storage need in one index.html per route (/login it will look at /login/index.html)
// but when you refresh the site, it redirect you to index.html and our router doesn't
// find this route. So before initializing the app we detect if there is file extention
// and we dispatch a react router redux push action to use the correct route
function removeFileExtention(history, store) {
  const htmlExtMatched = history.location.pathname.match(/(.*)\/index\.html$/);
  if (htmlExtMatched && htmlExtMatched[1]) {
    store.dispatch(push({
      ...history.location,
      pathname: htmlExtMatched[1]
    }));
  }
}

const baseUrl = `${window.location.protocol}//${window.location.host}`;

const api = new Api({
  baseUrl: baseUrl
});

let middlewares = [
  ThunkMiddleware.withExtraArgument(api),
  SocketMiddleware({
    socketUrl: baseUrl
  }),
  BannerMiddleware,
  ResponsiveMiddleware,
  routerMiddleware(history)
];

const debugLevel = localStorage.getItem(LS_DEBUG);

// Do we authorize debug or not
if (debugLevel !== undefined) {
  // Middleware logging is usefull only for dev purpose so set it only in level DEBUG
  if (debugLevel >= 7) {

    const logExceptions = [
      RECEIVED_ENCODERS_PREVIEW,
      RECEIVED_STREAM_STATS,
      RECEIVED_OUTPUT_STATS,
      RECEIVED_MONITOR_INFO,
      RECEIVED_INPUT_INTERCOM_VOLUME,
      RECEIVED_INPUT_INTERCOM_INFO,
      DASHBOARD_HIGHLIGHT_ENCODER,
      DASHBOARD_HIGHLIGHT_INPUT
    ];

    middlewares.push(createLogger({
      predicate: (getState, action) => logExceptions.indexOf(action.type) === -1
    }));
  }
  Logger.setLevel(debugLevel);
} else {
  Logger.disable();
}

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const store = createStore(
    connectRouter(history)(combineReducers(Reducers)),
    applyMiddleware(...middlewares)
);

addLocationQuery(history);
removeFileExtention(history, store);

history.listen(() => {
  addLocationQuery(history)
});

let theme = localStorage.getItem(LS_THEME);
if(!theme){
  theme = THEME_DARK;
}

render(
    <Provider store={store}>
      <LocalizeProvider store={store}>
        <DndProvider backend={HTML5Backend}>
          <ConnectedRouter history={history}>
            <App theme={theme}/>
          </ConnectedRouter>
        </DndProvider>
      </LocalizeProvider>
    </Provider>,
    document.getElementById('root')
);
