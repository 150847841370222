import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Info from "./info";
import Monitor from "./monitor";

import { activeEncodersCount, enableEncodersCount } from "../../../components/pages/dashboard/dashboard.selectors";

const propTypes = {
  monitor: PropTypes.object,
  hardwareIdentifier: PropTypes.string,
  sessionsCount: PropTypes.number,
  version: PropTypes.string,
  transcoderQueueSize: PropTypes.number.isRequired,
  activeEncodersCount: PropTypes.number.isRequired,
  enableEncodersCount: PropTypes.number.isRequired,
};

const Footer = (props) => {

  const { hardwareIdentifier, sessionsCount, monitor, version, publicIp, localIps,
    transcoderQueueSize, activeEncodersCount, enableEncodersCount } = props;
  return (
    <footer className="aw-footer">
      <Info hardwareIdentifier={hardwareIdentifier}
            sessionsCount={sessionsCount}
            version={version}
            publicIp={publicIp}
            localIps={localIps}/>
      <div className="flex-right">
        <Monitor info={monitor} transcoderQueueSize={transcoderQueueSize} activeEncodersCount={activeEncodersCount} enableEncodersCount={enableEncodersCount}/>
      </div>
    </footer>
  )
};

Footer.propTypes = propTypes;

const mapStateToProps = (state) => {
  const { hardwareIdentifier, version, publicIp, localIps, sessionsCount } = state.streamhub;
  return {
    hardwareIdentifier,
    version,
    publicIp,
    localIps,
    sessionsCount,
    monitor: state.datastore.system && state.datastore.system.cpuPercent ? state.datastore.system : null,
    transcoderQueueSize: state.datastore.transcoderStatus && state.datastore.transcoderStatus.status === 2 ? state.datastore.transcoderStatus.queueSize: 0,
    activeEncodersCount: activeEncodersCount(state),
    enableEncodersCount: enableEncodersCount(state),
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);