import {Formik} from "formik";
import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap";
import {Translate, withLocalize} from "react-localize-redux";

import {isEmptyString, isIPValid} from "../../../../utils/string-utils";
import {DEJITTER_BUFFER_MAX, DEJITTER_BUFFER_MIN, DEJITTER_BUFFER_DEFAULT, TS} from "../../../../constants";
import AWConfirm from "@aviwest/ui-kit/dist/js/components/confirm";
import HelpLayout from "../../../common/help-layout";

const propTypes = {
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    enableMulticast: PropTypes.bool,
    uri: PropTypes.string,
    port: PropTypes.number.isRequired,
    dejitterBuffer: PropTypes.number
  }),
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  forbiddenUDPPorts: PropTypes.arrayOf(PropTypes.number).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  alert: PropTypes.node,
  protocol: PropTypes.node
};

const PORT_MIN = 21;
const PORT_MAX = 65535;

const TSForm = (props) => {
  const { config, forbiddenNames, forbiddenUDPPorts, translate, alert, protocol } = props;

  const handleFormSubmit = (values) => {
    const data = { ...values };
    if(!data.enableMulticast){
      delete data.uri; // Check here if it is necessary
    }
    props.onSubmit(data);
  };

  const handleValidation = (values) => {
    const errors = {};

    // Name
    if(isEmptyString(values.name)){
      errors.name = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.name.length >= 32){
      errors.name = 'genericLabel.TOO_LONG.text';
    }
    else if(forbiddenNames.indexOf(values.name) !== -1){
      errors.name = 'genericLabel.DUPLICATED_VALUES.text';
    }

    if(values.enableMulticast){
      if(isEmptyString(values.uri)){
        errors.uri = 'genericLabel.REQUIRED_FIELD.text';
      }
      else if(!isIPValid(values.uri)){
        errors.uri = 'genericLabel.INVALID_IP_ADDRESS.text';
      }
    }

    if(isEmptyString(values.port)){
      errors.port = 'genericLabel.REQUIRED_FIELD.text';
    } else if (values.port < PORT_MIN || values.port > PORT_MAX) {
      errors.port = 'genericLabel.INVALID_FORMAT.text';
    }

    if(forbiddenUDPPorts.indexOf(parseInt(values.port)) !== -1){
      errors.port = 'genericLabel.PORT_ALREADY_USED.text';
    }

    // DeJitterBuffer
    if(isEmptyString(values.dejitterBuffer)){
      errors.dejitterBuffer = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(parseInt(values.dejitterBuffer) < DEJITTER_BUFFER_MIN || parseInt(values.dejitterBuffer) > DEJITTER_BUFFER_MAX){
      errors.dejitterBuffer = 'genericLabel.INVALID_FORMAT.text';
    }

    return errors;
  };

  return (
    <Formik initialValues={{
              id: config ? config.id : undefined,
              type: TS,
              name: config ? config.name : '',
              enableMulticast: config && config.enableMulticast != null ? config.enableMulticast : false,
              port: config ? config.port : '',
              uri: config && config.uri != null ? config.uri : '',
              dejitterBuffer: config ? config.dejitterBuffer : DEJITTER_BUFFER_DEFAULT
            }}
            validate={ handleValidation }
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={ handleFormSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          /* and other goodies */
        }) => (
        <Form onSubmit={ handleSubmit }>

          <HelpLayout
          filename={`c_sh_ts_input_profile.html`}
          form={<fieldset disabled={config && config.enable === true}>
            {alert}
            {protocol}

            <FormGroup>
              <Label for="name">
                <Translate id="genericLabel.NAME.text"/>
              </Label>
              <Input type="text"
                    name="name"
                    id="name"
                    invalid={errors.name !== undefined}
                    placeholder={ translate('genericLabel.NAME.text') }
                    value={values.name}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.name} />
              </FormFeedback>
            </FormGroup>

            <FormGroup check>
              <Label check>
                <Input type="checkbox"
                      name="enableMulticast"
                      onChange={handleChange}
                      checked={values.enableMulticast}/>{' '}
                <Translate id="genericLabel.ENABLE_MULTICAST.text"/>
              </Label>
            </FormGroup>

            { values.enableMulticast === false &&
            <FormGroup>
              <Label for="port">
                <Translate id="genericLabel.PORT.text"/>
              </Label>
              <Input type="number"
                    name="port"
                    id="port"
                    min={PORT_MIN}
                    max={PORT_MAX}
                    invalid={errors.port !== undefined}
                    placeholder={translate('genericLabel.PORT.text')}
                    value={values.port}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.port}/>
              </FormFeedback>
            </FormGroup>
            }

            { values.enableMulticast === true &&
              <Fragment>
                <FormGroup>
                  <Label>
                    <Translate id="genericLabel.SOURCE.text" />
                  </Label>
                  <Row form>
                    <Col xs={8}>
                      <FormGroup>
                        <Input type="text"
                              name="uri"
                              id="uri"
                              invalid={errors.uri !== undefined}
                              onChange={handleChange}
                              placeholder={ translate('genericLabel.URI.text') }
                              value={values.uri}/>
                        <FormFeedback>
                          <Translate id={errors.uri} />
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs={4}>
                      <FormGroup>
                        <Input type="number"
                              name="port"
                              id="port"
                              min={PORT_MIN}
                              max={PORT_MAX}
                              invalid={errors.port !== undefined}
                              onChange={handleChange}
                              placeholder={ translate('genericLabel.PORT.text') }
                              value={values.port}/>
                        <FormFeedback>
                          <Translate id={errors.port} />
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
                <div className="uri-preview">
                  <div className="uri">{`udp://${values.uri}:${values.port}`}</div>
                </div>
              </Fragment>
            }

            <FormGroup>
              <Label for="dejitterBuffer">
                <Translate id="genericLabel.DEJITTER_BUFFER.text"/>
              </Label>
              <Input type="number"
                    name="dejitterBuffer"
                    id="dejitterBuffer"
                    invalid={errors.dejitterBuffer !== undefined}
                    min={DEJITTER_BUFFER_MIN}
                    max={DEJITTER_BUFFER_MAX}
                    placeholder={ translate('genericLabel.DEJITTER_BUFFER.text') }
                    value={values.dejitterBuffer}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.dejitterBuffer} />
              </FormFeedback>
              <div className="indicator">
                <Translate id={`genericLabel.DEJITTER_BUFFER_HELP.text`}/>
              </div>
            </FormGroup>
            </fieldset>}

            buttons={<FormGroup className="buttons">
              { props.onDelete && !props.backToDashboard &&
              <AWConfirm
                onConfirm={props.onDelete}
                confirmationText={ props.translate("genericLabel.SECOND_CLICK_CONFIRM.text") }
                render={(ref, onClick) => (
                  <Button innerRef={ref}
                    className="mr-auto"
                    disabled={config && config.enable === true}
                    onClick={onClick}
                    outline
                    color="primary">
                    <Translate id="genericLabel.DELETE.text"/>
                  </Button>
                )} />
              }
              { props.onCancel && !props.backToDashboard &&
              <Button onClick={props.onCancel}
                outline
                color="primary">
                <Translate id="genericLabel.CANCEL.text"/>
              </Button>
              }
              <Button color="primary"
                      disabled={!dirty}
                      type="submit">
                <Translate id="genericLabel.SAVE.text"/>
              </Button>
            </FormGroup>
          } />
        </Form>
      )}
    </Formik>
  );
};

TSForm.propTypes = propTypes;

export default withLocalize(TSForm);