import {
  SETTINGS_NETWORK_UPDATE,
  SETTINGS_NDI_UPDATE
} from "../../../../constants";


export const updateNetworkSettingsAndRestartIfNecessary = (data, restart) => (dispatch) => {
  dispatch(updateNetworkSettings(data));
  if(restart){
    //TODO: Restart here
  }
};

export const updateNetworkSettings = (data) => {
  return {
    type: SETTINGS_NETWORK_UPDATE,
    data
  }
};

export const updateNdiSettings = (data) => {
  return {
    type: SETTINGS_NDI_UPDATE,
    data
  }
}