import {
  RECEIVED_REPORT_STATUS,
  TOOLS_SYSTEM_CHECK_VIDEOCARD_FIRMWARE,
  TOOLS_SYSTEM_CHECK_VIDEOCARD_FIRMWARE_RESULT, TOOLS_SYSTEM_GENERATE_REPORT,
  TOOLS_SYSTEM_HALT_SERVER_MODAL,
  TOOLS_SYSTEM_REBOOT_SERVER_MODAL,
  TOOLS_SYSTEM_RESTART_STREAMHUB_MODAL,
  TOOLS_SYSTEM_UPDATE_FIRMWARE_MODAL,
  TOOLS_SYSTEM_UPDATE_VIDEOCARD_FIRMWARE_MODAL,
  TOOLS_SYSTEM_UPLOAD_FIRMWARE_CANCEL,
  TOOLS_SYSTEM_UPLOAD_FIRMWARE_RESULT,
  TOOLS_SYSTEM_UPLOAD_FIRMWARE_START,
  TOOLS_SYSTEM_UPLOADING_FIRMWARE
} from "../../../../constants";

const initialState = {
  restartStreamhubModalOpened: false,
  rebootServerModalOpened: false,
  haltServerModalOpened: false,
  updateFirmwareModalOpened: false,
  updateVideoCardFirmwareModalOpened: false,
  videoCardFirmwareState: null,
  uploadingFirmwareFile: null,
  uploadingProgress: 0,
  uploadingFirmwareResult: null,
  generatingReport: false
};

const systemReducer = (state = initialState, action) => {

  if(action.type === TOOLS_SYSTEM_RESTART_STREAMHUB_MODAL){
    return {
      ...state,
      restartStreamhubModalOpened: action.open
    }
  }

  if(action.type === TOOLS_SYSTEM_REBOOT_SERVER_MODAL){
    return {
      ...state,
      rebootServerModalOpened: action.open
    }
  }

  if(action.type === TOOLS_SYSTEM_HALT_SERVER_MODAL){
    return {
      ...state,
      haltServerModalOpened: action.open
    }
  }

  if(action.type === TOOLS_SYSTEM_UPDATE_FIRMWARE_MODAL){
    return {
      ...state,
      updateFirmwareModalOpened: action.open
    }
  }

  if(action.type === TOOLS_SYSTEM_UPDATE_FIRMWARE_MODAL){
    return {
      ...state,
      updateVideoCardFirmwareModalOpened: action.open
    }
  }

  if(action.type === TOOLS_SYSTEM_UPDATE_VIDEOCARD_FIRMWARE_MODAL){
    return {
      ...state,
      updateVideoCardFirmwareModalOpened: action.open
    }
  }

  if(action.type === TOOLS_SYSTEM_CHECK_VIDEOCARD_FIRMWARE){
    return {
      ...state,
      updateVideoCardFirmwareModalOpened: true,
      videoCardFirmwareState: null
    }
  }

  if(action.type === TOOLS_SYSTEM_CHECK_VIDEOCARD_FIRMWARE_RESULT){
    return {
      ...state,
      updateVideoCardFirmwareModalOpened: true,
      videoCardFirmwareState: action.result
    }
  }

  if(action.type === TOOLS_SYSTEM_UPLOAD_FIRMWARE_START){
    return {
      ...state,
      uploadingFirmwareFile: action.file,
      uploadingProgress: 0
    }
  }

  if(action.type === TOOLS_SYSTEM_UPLOAD_FIRMWARE_CANCEL){
    return {
      ...state,
      uploadingFirmwareFile: null,
      uploadingProgress: 0,
      uploadingFirmwareResult: null
    }
  }

  if(action.type === TOOLS_SYSTEM_UPLOADING_FIRMWARE){
    return {
      ...state,
      uploadingProgress: action.progress
    }
  }

  if(action.type === TOOLS_SYSTEM_UPLOAD_FIRMWARE_RESULT){
    return {
      ...state,
      uploadingFirmwareFile: null,
      uploadingFirmwareResult: action.result,
      uploadingProgress: 0
    }
  }

  if(action.type === TOOLS_SYSTEM_GENERATE_REPORT){
    return {
      ...state,
      reportFile: null
    }
  }

  if(action.type === RECEIVED_REPORT_STATUS){
    return {
      ...state,
      generatingReport: action.preparing
    }
  }

  return state;
};

export default systemReducer;