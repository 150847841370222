import PropTypes from "prop-types";
import React from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import {Button, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {Formik} from "formik";

import {isEmptyString} from "../../../../utils/string-utils";

const propTypes = {
  config: PropTypes.shape({
    device: PropTypes.shape({
      Identifier: PropTypes.string,
      Language: PropTypes.string
    }).isRequired,
  }).isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    code_long: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),
  onSubmit: PropTypes.func.isRequired
};

const GeneralSettingsForm = (props) => {

  const { config, /*languages,*/ translate } = props;

  const handleSubmit = (values, { resetForm }) => {
    let data = { ...values };
    props.onSubmit(data);
    resetForm(values);
  };

  const handleValidation = (values) => {
    const errors = {};

    //Identifier
    if(isEmptyString(values.identifier)){
      errors.identifier = 'genericLabel.REQUIRED_FIELD.text';
    }

    return errors;
  };

  return (
    <Formik initialValues={{
              identifier: config.device.Identifier,
              language: config.device.Language
            }}
            validate={ handleValidation }
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={ handleSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
        <Form onSubmit={ handleSubmit }>
          <section className="form-section">
            <FormGroup>
              <Label for="identifier">
                <Translate id="genericLabel.STREAMHUB_ID.text"/>
              </Label>
              <Input type="text"
                     name="identifier"
                     id="identifier"
                     invalid={errors.identifier !== undefined}
                     placeholder={ translate('genericLabel.STREAMHUB_ID.text') }
                     value={values.identifier}
                     onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.identifier} />
              </FormFeedback>
            </FormGroup>
            {/*<FormGroup>*/}
              {/*<Label for="language">*/}
                {/*<Translate id="genericLabel.LANGUAGE.text"/>*/}
              {/*</Label>*/}
              {/*<Input type="select"*/}
                     {/*name="language"*/}
                     {/*id="language"*/}
                     {/*value={values.language}*/}
                     {/*onChange={handleChange}>*/}
                {/*{ languages && languages.map(language => (*/}
                  {/*<option key={language.code_long} value={language.code_long}>{ language.name }</option>*/}
                {/*))}*/}
              {/*</Input>*/}
            {/*</FormGroup>*/}
          </section>
          <FormGroup className="buttons">
            <Button color="primary"
                    disabled={isSubmitting || !dirty}
                    type="submit">
              <Translate id="genericLabel.SAVE.text"/>
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
};

GeneralSettingsForm.propTypes = propTypes;

export default withLocalize(GeneralSettingsForm);