import React from "react";
import PropTypes from 'prop-types';

const propTypes = {
  width: PropTypes.number,
};

const StreamHubLogo = ({width = 150}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 326 91"
    >
      <g fontFamily="Prototype">
        <text
          fill="#ec502e"
          fontSize="56"
          transform="translate(-3269 -3984) translate(3489 4062)"
        >
          <tspan x="0" y="0">
            HUB
          </tspan>
        </text>
        <text
          fill="#fff"
          fontSize="56"
          transform="translate(-3269 -3984) translate(3269 4062)"
        >
          <tspan x="0" y="0">
            STREAM
          </tspan>
        </text>
        <text
          fill="#88888e"
          fontSize="22"
          opacity="0.8"
          transform="translate(-3269 -3984) translate(3269 4005)"
        >
          <tspan x="0" y="0">
            AVIWEST
          </tspan>
        </text>
      </g>
    </svg>
  );
}

StreamHubLogo.propTypes = propTypes;

export default StreamHubLogo;
