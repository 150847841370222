import {
  RECEIVED_LOG_COUNTER_RESET,
  RECEIVED_LOGS,
  RECEIVED_NEW_LOG,
  TOOLS_LOGS_FILTERS_CHANGE
} from "../../../../constants";

const initialState = {
  list: null,
  warningCount: 0,
  errorCount: 0,
  filters: {
    info: true,
    warning: true,
    error: true
  }
};

const logsReducer = (state = initialState, action) => {
  if(action.type === RECEIVED_LOGS){
    return {
        ...state,
      list: action.logs.filter((log, index, self) => index === self.findIndex(other => other.label === log.label && other.timestamp.getTime() === log.timestamp.getTime())), // Removing duplicates
      warningCount: action.counterWarning,
      errorCount: action.counterDanger
    };
  }

  if(action.type === RECEIVED_NEW_LOG){
    const logs = state.list ? [...state.list] : [];
    logs.unshift(action.log);
    return {
      ...state,
      list: logs,
      warningCount: action.counterWarning,
      errorCount: action.counterDanger
    };
  }

  if(action.type === RECEIVED_LOG_COUNTER_RESET){
    return {
      ...state,
      warningCount: 0,
      errorCount: 0
    };
  }

  if(action.type === TOOLS_LOGS_FILTERS_CHANGE){
    return {
      ...state,
      filters: action.filters
    }
  }

  return state;
};

export default logsReducer;