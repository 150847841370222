import {Formik} from "formik";
import PropTypes from "prop-types";
import React, { Component } from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import { connect } from 'react-redux';
import {Button, Form, FormFeedback, FormGroup, Input, Label, Alert} from "reactstrap";

import {isAESKeyValid, isEmptyString, isTunIPValid} from "../../../../utils/string-utils";
import {isPortInRange} from "../../../../utils/global-utils";
import {licensePropTypes} from "../../../../utils/models-prop-types";
import PasswordRevealInput from "../../../common/password-reveal-input";

import {
  forbiddenTCPPorts
} from "../../../../constants";

const propTypes = {
  config: PropTypes.shape({
    authentificationRetryMax: PropTypes.number,
    ebonding: PropTypes.shape({
      atpGeneralBasePort: PropTypes.number,
      username: PropTypes.string,
      password: PropTypes.string,
    }).isRequired,
    'abus-proxy': PropTypes.shape({
      tcpPort: PropTypes.number.isRequired
    }).isRequired,
    tun: PropTypes.shape({
      baseTunIPAddr: PropTypes.string.isRequired
    }).isRequired,
    tunRemoteControl: PropTypes.shape({
      webProxyPort: PropTypes.number.isRequired
    }).isRequired,
    AESkey: PropTypes.string,
  }).isRequired,
  license: licensePropTypes.isRequired,
  forbiddenTCPPorts: PropTypes.arrayOf(PropTypes.number),
  forbiddenUDPPorts: PropTypes.arrayOf(PropTypes.number),
  onSubmit: PropTypes.func.isRequired
};

class NetworkSettingsForm extends Component {

  constructor(props){
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
  }

  handleSubmit(values, { setSubmitting }){
    let data = values;
    this.props.onSubmit(data, this.willNeedRestart(values));
    setSubmitting(false);
  }

  handleValidate(values){
    const errors = {};
    const { AESkey } = this.props.config;
    //Base port
    const forbiddenBasePorts = this.props.forbiddenUDPPorts;/*concat(values.abusProxyTcpPort);*/
    if(isEmptyString(values.atpGeneralBasePort)){
      errors.atpGeneralBasePort = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(!isPortInRange(values.atpGeneralBasePort, 1024, 65535)){
      errors.atpGeneralBasePort = 'genericLabel.BASE_PORT_HELP.text';
    }
    else if(forbiddenBasePorts.indexOf(values.atpGeneralBasePort) !== -1){
      errors.atpGeneralBasePort = 'genericLabel.PORT_ALREADY_USED.text';
    }

    //Manager port
    const forbiddenManagerPorts = this.props.forbiddenTCPPorts;/*.concat(values.atpGeneralBasePort);*/
    if(isEmptyString(values.abusProxyTcpPort)){
      errors.abusProxyTcpPort = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(!isPortInRange(values.abusProxyTcpPort, 1024, 65535)){
      errors.abusProxyTcpPort = 'genericLabel.MANAGER_PORT_HELP.text';
    }
    else if(forbiddenManagerPorts.indexOf(values.abusProxyTcpPort) !== -1){
      errors.abusProxyTcpPort = 'genericLabel.PORT_ALREADY_USED.text';
    }

    //Username
    if(isEmptyString(values.username)){
      errors.username = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.username.length < 6){
      errors.username = 'genericLabel.TOO_SHORT.text';
    }
    else if(values.username.length > 20){
      errors.username = 'genericLabel.TOO_LONG.text';
    }

    //Password
    if(isEmptyString(values.password)){
      errors.password = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.password.length < 6){
      errors.password = 'genericLabel.TOO_SHORT.text';
    }
    else if(values.password.length > 20){
      errors.password = 'genericLabel.TOO_LONG.text';
    }

    //AESkey
    if(this.props.license.AES > 0 && (values.aesKey !== AESkey || isEmptyString(values.aesKey))){
      if(isEmptyString(values.aesKey)){
        errors.aesKey = 'genericLabel.REQUIRED_FIELD.text';
      }
      else if(values.aesKey.length < 6){
        errors.aesKey = 'genericLabel.TOO_SHORT.text';
      }
      else if(values.aesKey.length > 32){
        errors.aesKey = 'genericLabel.TOO_LONG.text';
      }
      else if(!isAESKeyValid(values.aesKey)){
        errors.aesKey = 'genericLabel.INVALID_FORMAT.text';
      }
    }

    //Tun IP
    if(values.baseTunIPAddr.length === 0){
      errors.baseTunIPAddr = 'genericLabel.REQUIRED_FIELD.text';
    }
    if(!isTunIPValid(values.baseTunIPAddr)){
      errors.baseTunIPAddr = 'genericLabel.IP_TUNNELING_PATTERN.text';
    }

    //tunRemoteControl
    if(isEmptyString(values.webProxyPort)){
      errors.webProxyPort = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(!isPortInRange(values.webProxyPort, 8885, 65535)){
      errors.webProxyPort = 'genericLabel.INVALID_FORMAT.text';
    }

    return errors;
  }

  willNeedRestart(values){
    const { ebonding, AESkey, tun, tunRemoteControl, 'abus-proxy': abusProxy } = this.props.config;
    if(values.atpGeneralBasePort !== ebonding.atpGeneralBasePort
      || values.username !== ebonding.username
      || values.password !== ebonding.password){
      return true;
    }
    if(!isEmptyString(values.aesKey) && values.aesKey !== AESkey){
      return true;
    }
    if(values.baseTunIPAddr !== tun.baseTunIPAddr){
      return true;
    }
    if(values.webProxyPort !== tunRemoteControl.webProxyPort){
      return true;
    }
    if(values.abusProxyTcpPort !== abusProxy.tcpPort){
      return true;
    }
    return false;
  }

  render() {
    const { config, translate, license } = this.props;
    return (
      <Formik initialValues={{
                abusProxyTcpPort: config['abus-proxy'].tcpPort,
                atpGeneralBasePort: config.ebonding.atpGeneralBasePort,
                username: config.ebonding.username,
                password: config.ebonding.password,
                aesKey: config.AESkey ? config.AESkey : '',
                baseTunIPAddr: config.tun.baseTunIPAddr,
                webProxyPort: config.tunRemoteControl.webProxyPort
              }}
              validate={ this.handleValidate }
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={ this.handleSubmit }>
        {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
          <Form onSubmit={ handleSubmit }>
            { this.willNeedRestart(values)  &&
            <Alert color="warning">
              <Translate id="genericLabel.RESTART_STREAMHUB_TO_TAKE_CHANGES_INTO_ACCOUNT.text"/>
            </Alert>
            }
            <FormGroup>
              <Label for="atpGeneralBasePort">
                <Translate id="genericLabel.BASE_PORT.text"/>
              </Label>
              <Input type="number"
                     name="atpGeneralBasePort"
                     id="atpGeneralBasePort"
                     invalid={errors.atpGeneralBasePort !== undefined}
                     placeholder={ translate('genericLabel.BASE_PORT.text') }
                     value={values.atpGeneralBasePort}
                     onBlur={handleBlur}
                     onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.atpGeneralBasePort} />
              </FormFeedback>
              <div className="indicator">
                <Translate id="genericLabel.BASE_PORT_HELP.text" />
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="abusProxyTcpPort">
                <Translate id="genericLabel.MANAGER_PORT.text"/>
              </Label>
              <Input type="number"
                     name="abusProxyTcpPort"
                     id="abusProxyTcpPort"
                     invalid={errors.abusProxyTcpPort !== undefined}
                     placeholder={ translate('genericLabel.MANAGER_PORT.text') }
                     value={values.abusProxyTcpPort}
                     onBlur={handleBlur}
                     onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.abusProxyTcpPort} />
              </FormFeedback>
              <div className="indicator">
                <Translate id="genericLabel.MANAGER_PORT_HELP.text" />
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="username">
                <Translate id="genericLabel.USERNAME.text"/>
              </Label>
              <Input type="text"
                     name="username"
                     id="username"
                     invalid={errors.username !== undefined}
                     placeholder={ translate('genericLabel.USERNAME.text') }
                     value={values.username}
                     onBlur={handleBlur}
                     onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.username} />
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="password">
                <Translate id="genericLabel.PASSWORD.text"/>
              </Label>
              <PasswordRevealInput name="password"
                                   id="password"
                                   invalid={errors.password !== undefined}
                                   placeholder={ translate('genericLabel.PASSWORD.text') }
                                   value={values.password}
                                   onBlur={handleBlur}
                                   onChange={handleChange}
                                   error={errors.password}/>
            </FormGroup>
            { license.AES  &&
            <FormGroup>
              <Label for="aesKey">
                <Translate id="genericLabel.AES_KEY.text"/>
              </Label>
              <PasswordRevealInput name="aesKey"
                                   id="aesKey"
                                   invalid={errors.aesKey !== undefined}
                                   placeholder={translate('genericLabel.AES_KEY.text')}
                                   value={values.aesKey}
                                   onBlur={handleBlur}
                                   onChange={handleChange}
                                   error={errors.aesKey}/>
            </FormGroup>
            }
            <FormGroup>
              <Label for="baseTunIPAddr">
                <Translate id="genericLabel.IP_TUNNELING.text"/>
              </Label>
              <Input type="text"
                     name="baseTunIPAddr"
                     id="baseTunIPAddr"
                     invalid={errors.baseTunIPAddr !== undefined}
                     placeholder={ translate('genericLabel.IP_TUNNELING.text') }
                     value={values.baseTunIPAddr}
                     onBlur={handleBlur}
                     onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.baseTunIPAddr} />
              </FormFeedback>
              <div className="indicator">
                <Translate id="genericLabel.IP_TUNNELING_HELP.text" />
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="webProxyPort">
                <Translate id="genericLabel.WEB_PROXY_PORT.text"/>
              </Label>
              <Input type="number"
                     name="webProxyPort"
                     id="webProxyPort"
                     invalid={errors.webProxyPort !== undefined}
                     placeholder={ translate('genericLabel.WEB_PROXY_PORT.text') }
                     value={values.webProxyPort}
                     onBlur={handleBlur}
                     onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.webProxyPort} />
              </FormFeedback>
              <div className="indicator">
                <Translate id="genericLabel.WEB_PROXY_PORT_HELP.text" />
              </div>
            </FormGroup>
            <FormGroup className="buttons">
              <Button disabled={isSubmitting || !dirty}
                      color="primary"
                      type="submit">
                <Translate id="genericLabel.SAVE.text"/>
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    );
  }
}

NetworkSettingsForm.propTypes = propTypes;

const mapStateToProps = (state) => {
  // TCP Ports
  const configTCPPorts = [];
  if(state.config.gpimng){
    configTCPPorts.push(state.config.gpimng.alarmTcpPort);
  }
  if(state.config.rtspserver){
    configTCPPorts.push(state.config.rtspserver.port);
  }

  // UDP Ports
  const forbiddenUDPPorts = state.streamhub.udpUsedPort.intercomPorts.concat(
    state.streamhub.udpUsedPort.inputProtocolPorts,
    state.streamhub.udpUsedPort.channelPorts);

  return {
    forbiddenUDPPorts,
    forbiddenTCPPorts: forbiddenTCPPorts.concat(configTCPPorts)
  }
};
export default withLocalize(connect(mapStateToProps)(NetworkSettingsForm));