import React, { Component } from "react";
import { Button, Input, InputGroup, InputGroupAddon, FormFeedback } from "reactstrap";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import { Translate, withLocalize } from 'react-localize-redux';
import PasswordDecrpypt from './password-decrypt'

class PasswordRevealInput extends Component {

  constructor(props) {
    super(props);

    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.setDecrypted = this.setDecrypted.bind(this);

    this.state = {
      visible: false,
      allowVisibiliy: false,
      decrypted: false
    };
  }

  toggleVisibility(e) {
    e.preventDefault();

    this.setState({
      visible: !this.state.visible
    })
  }

  setDecrypted() {
    this.setState({
      visible: !this.state.visible,
      decrypted: true
    })
  }

  render() {
    const { visible, decrypted } = this.state;
    const { setFieldValue, defaultLanguage, activeLanguage, addTranslation, addTranslationForLanguage, setActiveLanguage, languages, renderToStaticMarkup, ignoreTranslateChildren, translate, initialize, ...rest } = this.props
    return (
      <InputGroup className="has-validation">
        <Input {...rest}
          onChange={(e) => {
            this.setState({
              allowVisibiliy: true,
              decrypted: true
            })
            this.props.onChange(e)
          }}
          autoComplete="new-password"
          type={visible ? 'text' : 'password'} />
        <InputGroupAddon addonType="append">
          { (!setFieldValue || decrypted) &&
          <Button className="no-top-padding no-bottom-padding"
            disabled={this.props.disabled}
            color="secondary"
            onClick={this.toggleVisibility}>
            <AWIcon name={visible ? 'visibility_off' : 'visibility'} />
          </Button>
          }
          { setFieldValue && !decrypted &&
            <PasswordDecrpypt setDecrypted={this.setDecrypted} setFieldValue={setFieldValue} fieldName={this.props.name} encryptedPassword={this.props.value}/>
          }
        </InputGroupAddon>
        <FormFeedback>
          <Translate id={this.props.error} />
        </FormFeedback>
      </InputGroup>
    )
  }
}

export default withLocalize(PasswordRevealInput);