import PropTypes from 'prop-types';
import React, { useState } from 'react'
import { connect } from "react-redux";
import AWModal from '@aviwest/ui-kit/dist/js/components/modal'
import { Translate, withLocalize } from "react-localize-redux";
import { encodersProfilesSettings, encoderAttachProfile } from '../../dashboard.actions';
import EncoderProfileList from '../../../settings/encoders/list';
import EncoderProfileForm from '../../../settings/encoders/form';
import { Breadcrumb, BreadcrumbItem, Button, Form, Input } from "reactstrap";
import AWIcon from '@aviwest/ui-kit/dist/js/components/icon';
import { createEncoderProfile, deleteEncoderProfile, updateEncoderProfile, } from '../../../settings/encoders/encoders.settings.actions';
import { encoderProfilePropTypes, encoderPropTypes } from '../../../../../utils/models-prop-types';

const propTypes = {
  callCreateProfile: PropTypes.func.isRequired,
  callDeleteProfile: PropTypes.func.isRequired,
  callUpdateProfile: PropTypes.func.isRequired,
  callEncoderAttachProfile: PropTypes.func.isRequired,
  encoderProfiles: PropTypes.arrayOf(encoderProfilePropTypes),
  encoders: PropTypes.arrayOf(encoderPropTypes),
  callCloseModal: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  canSelect: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

const EncoderProfilesSettingsModal = (props) => {
  const [search, setSearch] = useState("")
  const [editingProfileId, setEditingProfileId] = useState(null);

  const { callCreateProfile, callDeleteProfile, callUpdateProfile, callEncoderAttachProfile, callCloseModal, encoderProfiles, encoders, opened, canSelect, translate } = props;

  const closeModal = () => {
    setEditingProfileId(null);
    callCloseModal();
  }

  const handleSearch = (evt) => {
    setSearch(evt.target.value);
  }

  const handleNewProfile = () => {
    setEditingProfileId("-1");
  }

  const handleEditProfile = (profile) => {
    setEditingProfileId(profile.id);
  }

  const handleProfileAttach = (encoder, profile) => {
    callEncoderAttachProfile(encoder, profile.id)
    closeModal()
  }

  const handleCancel = () => {
    setEditingProfileId(null);
  }

  const handleProfileSubmit = (data) => {
    if (data.id !== '-1') {
      const { id, ...otherProps } = data;
      callUpdateProfile(id, otherProps);
    }
    else {
      const { id, ...otherProps } = data;
      callCreateProfile(otherProps);
    }
    handleCancel();
  };

  const handleProfileDelete = () => {
    callDeleteProfile(editingProfileId);
    setEditingProfileId(null);
  }

  return (
    <AWModal onClose={closeModal}
      open={opened}
      title={
        <div className="encoder-profiles-settings-modal">
          <Breadcrumb>
            <BreadcrumbItem onClick={handleCancel} tag={editingProfileId !== null ? "a" : "li"} active>
              <Translate id="genericLabel.ENCODER_PROFILES.text" />
            </BreadcrumbItem>
            {editingProfileId !== null &&
            <BreadcrumbItem active><Translate id="genericLabel.SETTINGS.text" /></BreadcrumbItem>}
          </Breadcrumb>
          {editingProfileId === null &&
            <div className="actions">
              <Form className="form-inline">
                <Input className="search"
                  onChange={handleSearch}
                  placeholder={translate('genericLabel.SEARCH.text')}
                  bsSize="sm"
                  value={search} />
              </Form>
              <Button size="sm" onClick={handleNewProfile}>
                <AWIcon name="add" />
              </Button>
            </div>
          }
        </div>
      }>
      <div className="content">
        {editingProfileId === null &&
          <EncoderProfileList encoderProfiles={encoderProfiles}
            encoders={encoders}
            onProfileAttach={handleProfileAttach}
            onProfileEdit={handleEditProfile}
            search={search}
            canSelect={canSelect} />
        }
        {editingProfileId !== null &&
          <EncoderProfileForm
            editingProfileId={editingProfileId}
            onCancel={handleCancel}
            onSubmit={handleProfileSubmit}
            onDelete={editingProfileId > 0 ? handleProfileDelete : null} />
        }
      </div>
    </AWModal>
  )
}

EncoderProfilesSettingsModal.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    encoderProfiles: state.streamhub.encoderProfiles,
    encoders: state.streamhub.encoders,
    opened: state.dashboard.editingEncodersProfilesSettings.open,
    canSelect: state.dashboard.editingEncodersProfilesSettings.canSelect,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    callCreateProfile: (data) => dispatch(createEncoderProfile(data)),
    callDeleteProfile: (id) => dispatch(deleteEncoderProfile(id)),
    callUpdateProfile: (id, data) => dispatch(updateEncoderProfile(id, data)),
    callEncoderAttachProfile: (encoder, profileId) => dispatch(encoderAttachProfile(encoder, profileId)),
    callCloseModal: () => dispatch(encodersProfilesSettings(false, false))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(EncoderProfilesSettingsModal));
