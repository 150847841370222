import {
  TOOLS_SYSTEM_CHECK_VIDEOCARD_FIRMWARE, TOOLS_SYSTEM_CHECK_VIDEOCARD_FIRMWARE_RESULT, TOOLS_SYSTEM_GENERATE_REPORT,
  TOOLS_SYSTEM_HALT_SERVER,
  TOOLS_SYSTEM_HALT_SERVER_MODAL,
  TOOLS_SYSTEM_REBOOT_SERVER,
  TOOLS_SYSTEM_REBOOT_SERVER_MODAL,
  TOOLS_SYSTEM_RESTART_STREAMHUB,
  TOOLS_SYSTEM_RESTART_STREAMHUB_MODAL,
  TOOLS_SYSTEM_UPDATE_FIRMWARE_MODAL,
  TOOLS_SYSTEM_UPDATE_VIDEOCARD_FIRMWARE,
  TOOLS_SYSTEM_UPDATE_VIDEOCARD_FIRMWARE_MODAL,
  TOOLS_SYSTEM_UPLOAD_FIRMWARE_CANCEL,
  TOOLS_SYSTEM_UPLOAD_FIRMWARE_REBOOT,
  TOOLS_SYSTEM_UPLOAD_FIRMWARE_RESULT,
  TOOLS_SYSTEM_UPLOAD_FIRMWARE_START,
  TOOLS_SYSTEM_UPLOADING_FIRMWARE
} from "../../../../constants";

//////// MODALS ////////

export const restartStreamhubModal = (open) => {
  return {
    type: TOOLS_SYSTEM_RESTART_STREAMHUB_MODAL,
    open
  }
};

export const rebootServerModal = (open) => {
  return {
    type: TOOLS_SYSTEM_REBOOT_SERVER_MODAL,
    open
  }
};

export const haltServerModal = (open) => {
  return {
    type: TOOLS_SYSTEM_HALT_SERVER_MODAL,
    open
  }
};

export const updateVideoCardFirmwareModal = (open) => {
  return {
    type: TOOLS_SYSTEM_UPDATE_VIDEOCARD_FIRMWARE_MODAL,
    open
  }
};

export const updateFirmwareModal = (open) => {
  return {
    type: TOOLS_SYSTEM_UPDATE_FIRMWARE_MODAL,
    open
  }
};

//////// ACTIONS ////////

export const restartStreamhub = () => {
  return {
    type: TOOLS_SYSTEM_RESTART_STREAMHUB
  }
};

export const rebootServer = () => {
  return {
    type: TOOLS_SYSTEM_REBOOT_SERVER
  }
};

export const haltServer = () => {
  return {
    type: TOOLS_SYSTEM_HALT_SERVER
  }
};

export const updateVideoCardFirmware = () => {
  return {
    type: TOOLS_SYSTEM_UPDATE_VIDEOCARD_FIRMWARE,
  }
};

export const checkVideoCardFirmware = () => {
  return {
    type: TOOLS_SYSTEM_CHECK_VIDEOCARD_FIRMWARE,
  }
};

export const checkVideoCardFirmwareResult = (result) => {
  return {
    type: TOOLS_SYSTEM_CHECK_VIDEOCARD_FIRMWARE_RESULT,
    result
  }
};

export const updateFirmware = (file) => {
  return {
    type: TOOLS_SYSTEM_UPLOAD_FIRMWARE_START,
    file
  }
};

export const uploadFirmwareResult = (result) => {
  return {
    type: TOOLS_SYSTEM_UPLOAD_FIRMWARE_RESULT,
    result
  }
};

export const confirmFirmwareUpdate = () => {
  return {
    type: TOOLS_SYSTEM_UPLOAD_FIRMWARE_REBOOT,
  }
};

export const interruptFirmwareUpdate = () => {
  return {
    type: TOOLS_SYSTEM_UPLOAD_FIRMWARE_CANCEL
  }
};

export const uploadingFirmwareData = (progress) => {
  return {
    type: TOOLS_SYSTEM_UPLOADING_FIRMWARE,
    progress
  }
};

export const generateReport = () => {
  return {
    type: TOOLS_SYSTEM_GENERATE_REPORT
  }
};